@mixin field-icon($font-size) {
  color: $color-heading-gray;
  font-size: $font-size;
  padding: $layout-padding-sm;

  display: inline-block;
  position: absolute;
  top: 1px;
  right: 1px;
}

@mixin field-clickable-icon($font-size) {
  @include field-icon($font-size);
  cursor: pointer;
  border-radius: $layout-padding-sm;
  &:hover {
    color: $color-heading-gray-dark;
    background-color: mix($theme-color-bg, $accent, $theme-color-interval * 8);
  }

}

@mixin edit-icon($color, $font-size) {
  content: '\f044';
  @extend %fal-icon;

  font-size: $font-size;
  color: $color;
  position: absolute;
  top: 1px;
  right: 1px;
}

@mixin field-edit-icon-on-hover($color, $font-size) {
  &:hover {
    &:after {
      @include edit-icon($color, $font-size)
    }
  }
}


@mixin table-field-edit-icon() {
  &:after {
    @include edit-icon($color-heading-gray, 10px);
    padding: $layout-padding-sm;

  }
}



