.rich-search-result {
  display: flex;
  align-items: center;
}

.rich-search-result__icon {
  margin-right: $layout-margin;
}

.rich-search-result__type-label {
  color: $color-heading-gray;
  font-size: rem-calc(16px);
  font-style: italic;
  font-weight: 100;
  margin-right: $layout-margin;
}

.dropdown-item.active {
  .rich-search-result__icon {
    color: $gray-400 !important; // to override the color utility class on the icon
  }

  .rich-search-result__type-label {
    color: $gray-400;
  }
}
