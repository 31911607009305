@mixin sidebar-nav-section() {
  padding: $layout-padding-lg $layout-padding-lg;

  &:not(:last-child){
    border-bottom: 1px solid $theme-color-border;
  }
}

@mixin sidebar-nav-section-collapsed() {
  padding: $layout-padding-lg $layout-padding-sm;
}

@mixin sidebar-nav-item() {
  color: $theme-color-txt;
  font-size: rem-calc(14px);
  text-align: left;
  width: 100%;

  &:hover {
    color: $theme-color-txt;
    text-decoration: none;
  }

}

@mixin sidebar-icon(){
  font-size: $font-size-base;
  margin-right: $layout-padding;
  margin-top: -3px;


  &:hover {
    opacity: 1.0;
  }

}
