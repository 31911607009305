// Typography mixins

@mixin heading($font-size) {
  font-size: $font-size;
  font-weight: normal;
  margin-bottom: 0;
}

@mixin heading--primary($font-size) {
  @include heading($font-size);
  color: $link-color;
}

@mixin heading--body($font-size) {
  @include heading($font-size);
  color: $color-body;
}

@mixin panel-heading--center($font-size) {
  @include heading--primary($font-size);
  text-align: center;
}

@mixin content-subheading() {
  @include heading--body(rem-calc(14px));
}
