
%fal-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;

  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
}
