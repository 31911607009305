$ap-indention-size: $layout-padding-xl;

.ap-indention {
  display: inline-block;
  width: 0;
  height: 1px;
}

.ap-indention--1 {
  width: $ap-indention-size;
}

.ap-indention--2 {
  width: $ap-indention-size * 2;
}

.ap-indention--3 {
  width: $ap-indention-size * 3;
}

.ap-indention--4 {
  width: $ap-indention-size * 4;
}

.ap-indention--5 {
  width: $ap-indention-size * 5;
}

.ap-indention--6 {
  width: $ap-indention-size * 6;
}

.ap-indention--7 {
  width: $ap-indention-size * 7;
}
