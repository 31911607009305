@import 'reference';

.loader-content {
  @include absolute-fill();
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  z-index: 20;
}

.loader-content svg {
  max-width: 640px;
}

.loader-content--full {
  @include absolute-fill();
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  z-index: 70;
}

.loader-content--sticky {
  position: sticky !important;
}

.loader-content--empty {
  width: 36px;
  @include media-breakpoint-up(md) {
    left: 145px;
  }
  @include media-breakpoint-up(lg) {
    left: 175px;
  }
}

.card-ap-login .loader-content--empty {
  left: 0;
}

.loader-content--panel {
}

.loader-content--modal {
  border-radius: $border-radius-xl;
}
