
.inline-edit-input {
  transition: padding-bottom $animation-duration $animation-timing-function;
}

.inline-edit-input--edit-mode {
  //padding-bottom: 40px;
}

.inline-edit-input__input-wrapper {
  position: relative;
  //padding-bottom: $layout-padding-sm;
}

.inline-edit-input__input, .inline-edit-input__spacing {
  border: 1px solid transparent;
  padding-top: 7px;
  padding-bottom: 7px; // have to increase padding a bit for proper vertical alignment
  border-radius: $border-radius-sm;
  min-width: 100px;
}

.inline-edit-input__spacing {
  padding-left: $input-padding-x;
  padding-right: $input-padding-x;
  width: auto;
}

.inline-edit-input__input {
  width: 100%;
}

.inline-edit-input__input--text {
  border-color: transparent;
  background-color: transparent;

  &:hover {
    cursor: text;
    border-color: $primary-color;
    background-color: $primary-color;
  }
}

.inline-edit-input__input--input {
  min-width: 130px;

  &:focus {
    border-color: #80bdff;
  }
}


.form-control.inline-edit-input__input--with-min-width { // have to add weight override style for inline-edit-currency
  min-width: 150px;
}


.inline-edit-input__buttons {
  padding-top: $layout-padding-sm;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
}

.inline-edit-input__button {
  transition: background-color $animation-duration $animation-timing-function;
  box-shadow: $box-shadow;

  &:disabled {
    opacity: 1;
  }
}

