@import 'reference';

.assignment {
  @include assignment-card-shape();
  //overflow: auto;
  position: relative;
  background-color: $white;

  &.cdk-drop-list-dragging {
    > .assignment__overlay-element-add {
      display: flex;
      @include absolute-fill();
      background-color: rgba(255, 255, 255, 0.8);
      justify-content: center;
      align-content: center;
      border: 3px dashed $theme-color-border;
      border-radius: 20px;

      > .assignment__plus-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem-calc(48px);
        color: $theme-color-border;
      }
    }

    > .cdk-drag-placeholder {
      display: none; // we don't wanna show placeholder
    }
  }
}

.assignment--show-selection-animation {
  animation: shake $animation-duration linear;
}

//.assignment--duplicate{
//  border: 3px double red;
//}

.assignment--latest {
  box-shadow: 0px 0px 3px 5px #42fe6d7d;
}

.assignment--tall {
  min-height: 366px;
}

.assignment--locked {
  .assignment__lock-overlay {
    display: block;
    background-color: #515f6d14;
    @include absolute-fill();
  }

  .assignment__lock-overlay-btn-area {
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: $border-radius-sm;
    position: absolute;
    right: 42px;
    top: 16px;
    //background-color: $primary;
    opacity: 0.5;

    &:hover {
      opacity: 0.2;
    }
  }
}

.assignment__primary-row {
  display: flex;
  flex-wrap: wrap;
}

.assignment__primary-col {
  display: block;
  width: 100%;
  padding: $layout-padding-sm 0;
  //border-top: 1px solid $color-border;
  //
  //&:last-child {
  //  border-bottom: 1px solid $color-border;
  //}
}

.assignment__overlay-element-add {
  display: none;
}

.assigment__elements-container {
  display: flex;
  //min-height: 100px;
}

.assigment__elements-container--one-column {
  flex-direction: column;

  .assignment__elements-col {
    width: 100%;
    border-left: none;
  }
}

.assignment__elements-col {
  width: 50%;
  border-left: 1px solid $theme-color-border;
  //border-bottom: 1px solid $color-border;

  &:first-child {
    border-left: none;
  }
}

.assignment__lock-overlay {
  display: none;
}
