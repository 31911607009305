@import 'reference';

$overlay-edit-header-height: 24px;
$overlay-edit-footer-height: 24px;
$overlay-edit-body-height: 280px;

@mixin overlay-edit-option-label-variant($key, $value) {
  .overlay-edit__option-label--#{$key} {
    background-color: $value;

    &:hover {
      background-color: mix($gray-900, $value, $theme-color-interval);
    }
  }
}

.overlay-edit {
  background-color: $theme-color-bg;
  position: relative; // this is needed for footer position

  padding: $layout-padding-xl $layout-padding-xl 50px $layout-padding-xl;
  border-radius: $layout-padding-xl;
}

.overlay-edit--mandatory {
  padding-bottom: $layout-padding-xl;
}

.overlay-edit--no-scroll {
  .overlay-edit__options {
    height: auto;
    overflow-y: initial;
  }
}

.overlay-edit__header {
  position: relative;
}

.overlay-edit__title {
  color: $color-heading-gray;
  font-size: rem-calc(14px);
  font-weight: normal;
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay-edit__footer {
  min-height: $overlay-edit-footer-height;
}

.overlay-edit__action-area {
  position: absolute;
  left: $layout-padding-lg;
  right: $layout-padding-lg;
  bottom: $layout-padding-lg;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay-edit__button {
  padding: 0;
  font-size: 14px;
  height: 24px;
  min-width: 46px;
  margin-right: 10px;
}

.overlay-edit__icon {
  display: inline-block;
  width: 20px;
  height: 20px;

  line-height: 20px;
  border-radius: 4px;
}

.overlay-edit__icon--remove {
  color: $color-heading-gray;

  &:hover {
    color: $color-heading-gray-dark;
  }
}

.overlay-edit__body {
  padding: $layout-padding 0;
}

.overlay-edit__options {
  //height: $overlay-edit-body-height;
  //
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  box-shadow: $box-shadow-sm;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.overlay-edit__options--single-col {
  display: block;

  .overlay-edit__option-label {
    width: 100%;
    padding: $layout-padding-sm $layout-padding $layout-padding-sm 36px;
    justify-content: flex-start;
  }

  > label,
  > div {
    margin-bottom: $label-margin-bottom;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .overlay-edit__option-icon {
    left: $layout-padding;
  }
}

.overlay-edit__options--two-cols {
  .overlay-edit__option-label {
    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(odd):nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}

.overlay-edit__option-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 38px;
  width: calc(50% - #{$layout-margin-sm/ 2});

  background-color: $color-gray-default;
  position: relative;
  cursor: pointer;
  padding: $layout-padding-sm $layout-padding-sm $layout-padding-sm 36px;
}

.overlay-edit__option-label--gray {
  .overlay-edit__option-icon {
    color: mix($theme-color-bg, $accent, $theme-color-interval * 8);
  }
}

@each $key, $value in $ap-user-color-selections {
  @include overlay-edit-option-label-variant($key, $value);
}

.overlay-edit__option-label--hidden {
  display: none;
}

.overlay-edit__option-label--duplicated {
  background-color: $color-background-duplicated;

  &:hover {
    background-color: mix($gray-900, $color-background-duplicated, $theme-color-interval);
  }

  .overlay-edit__option-label-text {
    color: $secondary;
  }

  .overlay-edit__option-icon {
    color: $secondary;
  }
}

.overlay-edit__option-label-text {
  display: inline-block;
  color: $theme-color-bg;
  font-size: rem-calc(12px);
}

.overlay-edit__option-input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked {
    & ~ .overlay-edit__option-icon {
      opacity: 1;
    }
  }
}

.overlay-edit__option-icon {
  @include check-icon();
  left: $layout-padding-sm;
  opacity: 0;
  color: $accent;
}
