modal .ap-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: $theme-color-txt;
}

.modal-content,
.modal-dialog {
  min-width: 25%;
}

.modal-content-sm,
.modal-dialog-sm {
  min-width: 25%;
  max-height: 95vh;
}

.modal-content {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
  padding: none;
}

.modal-header {
  border-bottom: 1px solid $theme-color-border;
  padding: $layout-padding-xxl;
}

.modal-popover__body {
  max-height: 75vh;
}

.ap-page--cream-bg {
  background: $theme-color-bg;
}

.secured-header {
  position: sticky;
  top: 0;
  z-index: $zindex-header;

  @include media-breakpoint-up(sm) {
    height: $navbar-height;
  }

  @include media-breakpoint-up(lg) {
    height: $navbar-height;
  }
}

.workspace-common {
  display: flex;
  flex: 1;
}

.workspace-common__content {
  position: relative;
  height: $workspace-height;
  flex: 1;
  background: $theme-color-bg;
}

.workspace-common__content-scroller {
  @include absolute-fill();

  // Multi-select menu... This needs to be dynamic/toggleable
  //bottom: 30px;

  overflow: auto;
  overflow-x: hidden;
  z-index: 1;

  &.workspace-common__content-scroller--no-scroll {
    overflow: hidden;
  }
}

.workspace-common__content-footer {
  display: block;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;

  background-color: $white;
  padding: 0;


}

.workspace-common__sidebar {
  z-index: $zindex-sidebar;
}

.workspace-common__sub-sidebar {
  z-index: $zindex-sidebar - 1;
}
