@mixin selectable-option() {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  background-color: $color-gray-default;
  position: relative;
  cursor: pointer;
}

@mixin check-icon() {
  font-size: rem-calc(20px);
  font-weight: bolder;
  text-align: center;
  transition: opacity $animation-duration * 0.5 $animation-timing-function;
  color: $theme-color-bg;
  position: absolute;
  left: $layout-padding;
  display: inline-block;
}
