.dropdown {
  z-index: $zindex-dropdown !important;
}

.dropdown-menu {
  overflow-y: auto;
  color: $theme-color-txt;
}

.dropdown-menu.show {
  border: 1px solid $theme-color-border;
  box-shadow: $box-shadow-theme-1;
}

ngb-datepicker.dropdown-menu {
  max-height: initial;
}

ngb-typeahead-window.dropdown-menu {
  max-height: calc(100vh - 30%);
  z-index: $zindex-ngb-typeahead !important;
}
