
@mixin selectable-background-variant($key, $value) {
  .background--#{$key} {
    background-color: $value !important;
    &:hover {
      background-color: mix($gray-900, $value, $theme-color-interval) !important;
    }
  }
}


@mixin absolute-fill(){
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin layout-card( $includeMargins: true, $displayTable: false ) {

  @if $displayTable {
    /* This is used to wrap very wide zedulr-tables so that the table doesnt overflow the card*/
    display: table;
    min-width: 100%;
  }

  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $layout-margin;
  padding: $layout-padding;

  border: 1px solid $theme-color-border;
  border-radius: $layout-padding;

  background-color: $theme-color-bg;

  @include media-breakpoint-up(md) {
    @if $includeMargins {
      margin-left: $layout-margin-xxl;
      margin-right: $layout-margin-xxl;
      @if $displayTable {
        min-width: calc(100% - #{$layout-margin-xxl * 2} );
      }
    }
    padding: $layout-padding-xl;
    border-radius: $layout-padding-xl;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}

@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
