@import 'reference';

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-backdrop {
  animation-name: modalBgFadeIn;
  animation-duration: $modal-animation-duration;
  background: $gray-900;
  z-index: $zindex-modal-backdrop !important;
}

.modal-backdrop.show {
  opacity: $modal-backdrop-opacity;
}

.modal > .modal-dialog {
  animation-name: modalZoomIn !important;
  animation-duration: $modal-animation-duration;
}

.modal-header {
}

.modal-footer {
}

.modal-xxl {
  // currently used for image-modal
  max-width: calc(100% - 20px);
}

.modal-xs {
  max-width: 380px;
}

.modal.modal--assignment-new {
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);

    @include media-breakpoint-up(lg) {
      margin-left: 300px;
    }
  }
}

.modal.ap-modal-desktop--lg {
  .modal-dialog {
    max-width: 100%;
    @include media-breakpoint-up(lg) {
      max-width: $modal-lg;
    }
  }
}

.modal.ap-modal-desktop--xl {
  .modal-dialog {
    max-width: 100%;
    @include media-breakpoint-up(lg) {
      max-width: 1280px;
    }
  }
}

.modal.ap-modal--high-zindex {
  z-index: $zindex-modal-high-zindex !important;
}

.modal {
  &.chat-modal,
  &.form-edit-modal,
  &.layout-picker-modal,
  &.ws-links-modal {
    .modal-content {
      border: 1px solid rgb(204, 204, 204);
      transition: none 0s ease 0s;
      will-change: auto;
      box-shadow: rgb(0, 0, 0) 0px 6px 20px 0px;
      height: auto;
      min-height: 100%;
      padding: 0px;

      .modal-body {
        overflow-y: hidden !important;
        max-height: 88vh !important;
        padding: 0px;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}

.modal.ws-links-modal .modal-dialog,
.modal.form-edit-modal .modal-dialog,
.modal.layout-picker-modal .modal-dialog,
.modal.chat-modal .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 90vw;
  max-height: 90vh;
  margin: 20px 0 0 0;
}
