@mixin assignment-card-shape() {
  border-radius: $border-radius-xl;
  overflow: hidden; // so the children will obey the border radius
  border: 1px solid $theme-color-border;
  box-shadow: 0 1px 5px 0 $theme-color-bg-darken;
}

@mixin assignment-card-header() {

}

@mixin assignment-card-title($color: $color-river-bed) {

  color: $color;

  font-size: rem-calc(18px);
  font-weight: normal;
  margin-bottom: 0;
  display: flex;
  align-items: center;

}

@mixin assignment-inner-card() {
  border: 1px solid $theme-color-border;
  box-shadow: none;
  background-color: $theme-color-bg;
  border-radius: $layout-padding-xl;
  position: relative;
  padding: $layout-padding-lg;
}


@mixin assignment-overlay-card-title($color: $theme-color-bg) {
  @include assignment-card-title($color);
  width: 100%;
  height: 35px;
  padding: 0 $layout-padding;
}


@mixin assignment-overlay-card-footer() {
  height: $assignment-footer-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: $layout-padding + $layout-padding-lg;
  padding-right: 36px;
  background-color: $assignment-footer-background-color;
}

@mixin assignment-overlay-card-btn() {
  padding: 0;
  line-height: 1;
  background-color: transparent;
  opacity: 0.8;
  color: $secondary;

  &:hover {
    opacity: 1;
  }
}

@mixin assignment-overlay-card-btn--readonly() {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

}

