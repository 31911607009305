@import 'reference';

::-webkit-scrollbar {
  width: 13px;
  height: 13px;

  @include media-breakpoint-up(xl) {
      width: 16px;
      height: 16px;
  }

}

@include media-breakpoint-up(xl) {
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: mix($theme-color-bg, $accent, $theme-color-interval * 2.5);
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $accent;
  background-clip: padding-box;
}
