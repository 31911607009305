@import 'reference';

$assignment-table-col-date-width: 150px;
$assignment-table-col-lock-width: 60px;
$assignment-table-col-title-width: 250px;
$assignment-table-col-progress-width: 200px;
$assignment-table-col-primary-tag-width: 150px;
$assignment-table-col-assets-width: 150px;
$assignment-table-col-elements-width: 150px;
$assignment-table-col-notes-width: 180px;
$assignment-table-col-stamp-width: 150px;
$assignment-table-col-cal-field: auto;
$assignment-table-col-cal-formula: auto;
$assignment-table-col-checklist: 220px;
$assignment-table-col-timesheet: auto;
$assignment-table-col-timesheet-name: auto;
$assignment-table-col-timesheet-in: auto;
$assignment-table-col-timesheet-out: auto;
$assignment-table-col-timesheet-text: auto;
$assignment-table-col-diary: 400px;

$assignment-table-col-auto: auto;

$assignment-table-header-color: $gray-600;

@mixin assignment-table-col-center() {
  @at-root {
    th#{&} {
      text-align: center;
    }

    td#{&} {
      .assignment-table__cell-content {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@mixin assignment-table-col-right() {
  @at-root {
    th#{&} {
      text-align: right;
    }

    td#{&} {
      .assignment-table__cell-content {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.assignment-table {
}

.assignment-table__header {
  padding: $layout-padding 0;
  display: flex;
  align-items: center;
  color: $assignment-table-header-color;
}

.assignment-table__table {
  thead {
    th {
      font-size: rem-calc(16px);
      line-height: $line-height-compact;
      color: $assignment-table-header-color;
      background-color: $primary-color;
      padding: $layout-padding;
      border: 2px solid $theme-color-bg;
    }
  }

  tbody {
    tr {
      background-color: $theme-color-bg;
      @include hover() {
        background-color: mix($accent, $theme-color-bg, $theme-color-interval);
      }
    }

    td {
      font-size: rem-calc(16px);
      line-height: $line-height-compact;
      color: $assignment-table-header-color;
      padding: 0;
      position: relative;

      border: none;
      vertical-align: middle;
    }
  }
}

.assignment-table__layout-icon {
  margin-right: $layout-margin-sm;
}

.assignment-table__col-date {
  min-width: $assignment-table-col-date-width;
}

.assignment-table__col-lock {
  min-width: $assignment-table-col-lock-width;
  @include assignment-table-col-center();
}

.assignment-table__col-title {
  min-width: $assignment-table-col-title-width;
}

.assignment-table__col-progress {
  min-width: $assignment-table-col-progress-width;
}

.assignment-table__col-stamp,
.assignment-table__col-primary-tag {
  min-width: $assignment-table-col-primary-tag-width;
  @include assignment-table-col-center();
}

.assignment-table__col-assets {
  min-width: $assignment-table-col-assets-width;
}

.assignment-table__col-elements {
  min-width: $assignment-table-col-elements-width;
}

.assignment-table__col-notes {
  min-width: $assignment-table-col-notes-width;
}

.assignment-table__col-stamp {
  min-width: $assignment-table-col-stamp-width;
}

.assignment-table__col-cal-rate-field {
  min-width: 250px;
}

.assignment-table__col-cal-units-field {
  min-width: 80px;
}

.assignment-table__col-cal-field {
  min-width: $assignment-table-col-cal-field;
  white-space: nowrap;
  @include assignment-table-col-right();
}

.assignment-table__col-cal-formula {
  min-width: $assignment-table-col-cal-formula;
  white-space: nowrap;
  @include assignment-table-col-right();
}

.assignment-table__col-status {
  @include assignment-table-col-center();
}

.assignment-table__col-actions {
  @include assignment-table-col-center();
}

.assignment-table__col-checklist {
  min-width: $assignment-table-col-checklist;
}

.assignment-table__col-timesheet {
  min-width: $assignment-table-col-timesheet;
}

.assignment-table__col-timesheet-name {
  min-width: $assignment-table-col-timesheet-name;
}

.assignment-table__col-timesheet-in {
  min-width: $assignment-table-col-timesheet-in;
}

.assignment-table__col-timesheet-out {
  min-width: $assignment-table-col-timesheet-out;
}

.assignment-table__col-timesheet-text {
  min-width: $assignment-table-col-timesheet-text;
}

.assignment-table__col-diary {
  min-width: $assignment-table-col-diary;
}

.assignment-table__cell-content {
  position: relative;
  min-height: $table-cell-min-height;
  //width: 100%;
  padding: 1px $layout-padding;
}

.assignment-table__cell-content--actionable {
  border: 1px solid transparent;

  &:hover {
    border-color: $accent;
  }
}

.assignment-table__cell-content--clickable {
  cursor: pointer;
}

.assignment-table__subtotal {
  color: $assignment-table-header-color;
  background-color: $primary-color;
  display: inline-block;
  width: auto;
}

.assignment-table__field-edit-icon {
  @include field-icon(10px);
}

.assignment-table__field-clickable-edit-icon {
  @include field-clickable-icon(10px);
}

//.assignment-table__cell-content--readonly {
//  cursor: initial;
//
//  &:hover {
//    &:after {
//      content: none;
//    }
//  }
//}

.assignment-table__date {
}

.assignment-table__menu-icon,
.assignment-table__lock-icon {
  cursor: pointer;
  font-size: rem-calc(16px);
  width: 30px; // $nav-btn-size;
  height: 30px; // $nav-btn-size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;

  &:hover {
    background-color: mix($accent, $theme-color-bg, $theme-color-interval * 2);
  }

  &.fa-lock-alt {
    color: $secondary-brand;
  }

  &.fa-unlock-alt {
    color: $teal;
  }
}

.assignment-table__brick-container {
  display: flex;
}

.assignment-table__assets {
  display: flex;
}

.assignment-table__element {
  padding: $layout-padding-sm 0;
  display: block;

  cursor: pointer;
}

.assignment-table__text {
}

.assignment-table__units {
  width: 50px;
  text-align: right;
}
