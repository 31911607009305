@import 'reference';

.ap-popover--no-hz-padding {
  .popover-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.popover-menu {
  @include list-unstyled();
  margin: 0 (-$popover-body-padding-x);
  min-width: 200px;
}

.popover-menu__item, .rich-dropdown-menu__item  {
  @include list-unstyled();
  padding: $layout-padding-md $popover-body-padding-x;
  cursor: pointer;
  font-size: 1rem;
  color: $theme-color-txt;
  white-space: nowrap;

  &:hover {
    color: $secondary-brand;
    background-color: $theme-color-bg;
  }

  .popover_icon_style{
    color: $theme-color-txt;
  }

  &.disabled {
    color: $gray-600;
    pointer-events: none;
    background-color: transparent;
  }
}

.popover-menu__item--name-value-pair {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.popover-menu__item-name {
  font-size: rem-calc(14px);
  padding-right: $layout-padding-xl;
}

.popover-menu__item-value {
  font-size: rem-calc(14px);
  color: $theme-color-txt;
}

.popover-menu__divider {
  height: 1;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid $theme-color-border;
}

.popover-menu__info-title {
  padding: 0.5rem $popover-body-padding-x;
  margin-bottom: 0;
  font-size: rem-calc(13px);
  color: $primary-brand;
}

.popover-menu__info-title--primary {
  font-size: rem-calc(13px);
  color: $theme-color-txt-lighten;
}

.popover-menu__info-text {
  font-size: rem-calc(14px);
  color: $link-color;
  padding: $layout-padding-sm $popover-body-padding-x;
}

.popover-body {
  padding: 0rem 1.5rem 0.5rem 1.5rem;
  box-shadow: $box-shadow-popover;
}

