.nav-tabs {
  border-bottom: none;
  padding: 0rem;

  .nav-link {
    border-color: transparent;
    padding: 0rem;
    margin: 0.5rem 0.5rem 0rem 0.5rem;

    &.active {
      color: $primary-brand;
      border-color: transparent;
      border-bottom: 3px solid $primary-brand;
      background: none;
      padding-bottom: 0.5rem;
    }

    &:hover, &:focus{
      border-color: transparent;
      color: $secondary-brand;
    }
  }
}
