@import 'reference';

.calendar-row {

  &.cdk-drop-list-dragging {
    .calendar-row__overlay {
      display: flex;

      .calendar-row__plus-icon {
        display: flex;
      }
    }
  }

  .cdk-drag-placeholder {
    display: none !important; // we don't wanna show placeholder
  }
}

.calendar-row--horizontal {
  @include layout-card($includeMargins: false);
  background-color: $white;
}

.calendar-row--vertical {
  @include layout-card($includeMargins: false);
  min-height: 100%;
  background: $white;
}

.calendar-row--awaiting-assignments {
  min-height: 82vh;
  background-color: $white; /* background */
  background-image: radial-gradient(circle, $theme-color-bg 30%, transparent 20%); /* dots */
  background-size: 10px 10px; /* Size of the squares containing the dots */
}

.calendar-row__project {
  position: relative;
  display: block;
  width: 100%;
  min-width: $assignment-width;
  max-width: none;
  padding: $layout-padding;

  @include media-breakpoint-up(md) {
    width: $assignment-width;
    min-width: $assignment-width;
    max-width: none;
  }
}

.calendar-row__body {
  min-height: 80px;
  @include make-row();
  margin-right: 0;
  margin-left: 0;
}

.calendar-row__overlay {
  display: none;
  @include absolute-fill();
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center;
  border: 5px dashed $theme-color-border;
  border-radius: 1.5rem;
}

.calendar-row__overlay--loading {
  display: flex;
}

.calendar-row__loader {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.calendar-row__plus-icon {
  display: none;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 50px;
  font-size: rem-calc(48px);
  color: $theme-color-border;
}
