.ap-table {
  border: 1px solid $theme-color-border;

  tr {
    background-color: $theme-color-bg;
  }
}

.ap-table--bg-creamy {
  tr {
    background-color: $white;
  }
}

.assignment-table__table{
  border-collapse: separate;
  border-spacing: 0;

  & thead tr:first-child th:nth-child(-n+3){
    position: sticky;
    z-index: 3 !important;
  }
  & tbody td:nth-child(-n+3)  {
    position: sticky;
    z-index: 2 !important;
  }
  & tbody td:nth-child(-n+3)  {
    background: $theme-color-bg;
  }
  & thead tr:first-child th:first-child ,
  & tbody td:first-child  {
    width: 150px;
    left: 0;
  }
  & thead tr:first-child th:nth-child(2) ,
  & tbody td:nth-child(2)  {
    left: 150px;
    width: 60px;
  }
  & thead tr:first-child th:nth-child(3) ,
  & tbody td:nth-child(3)  {
    left: 210px;
  }

  & thead{
    position: sticky;
    top: 0;
    z-index: 3;

    & th{
      z-index: 1;
    }
  }

}

