.ngb-tp {
  padding: 6px;


  .ngb-tp-input-container {
    position: relative;
    padding-right: 32px;
    width: 76px;

    > button:first-child {

      position: absolute;
      right: 0;
      bottom: 50%;
    }

    > button:last-child {

      position: absolute;
      right: 0;
      top: 50%;
    }

    > input {
      min-width: 34px;
    }
  }

}


