.template-popover-page {
  min-width: 276px;
  min-height: 400px;

  position: relative;
  padding-bottom: $layout-padding-xxxl * 2; // for footer buttons
}

.template-popover-page--grey-background {
  background-color: $primary-color;
}
