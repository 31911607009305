
.picker-button {
  @include picker-button();
}

.picker-button--thumb {
  width: 220px;
  height: 140px;
  background-size: cover;
}

.picker-button--icon-not-available,
.picker-button--color-not-available,
.picker-button--thumb-not-available {
  @include picker-button-not-available();
}


@each $key, $value in $ap-user-color-selections {
  @include selectable-background-variant($key, $value);
}

.background--transparent {
  background-color: lighten($secondary-brand, 30%);
  border: 1px solid $theme-color-border;
  color: $theme-color-txt;
  &:hover {
    color: $theme-color-txt;
    background-color: lighten($secondary-brand, 30%);
  }
}
