.layout-mr {
  margin-right: $layout-margin !important;
}

.layout-mr-n {
  margin-right: -$layout-margin !important;
}

.layout-ml {
  margin-left: $layout-margin !important;
}

.layout-ml-n {
  margin-left: -$layout-margin !important;
}

.layout-mt {
  margin-top: $layout-margin !important;
}

.layout-mt-n {
  margin-top: -$layout-margin !important;
}

.layout-mb {
  margin-bottom: $layout-margin !important;
}

.layout-mb-n {
  margin-bottom: -$layout-margin !important;
}

.layout-mr-sm {
  margin-right: $layout-margin-sm !important;
}

.layout-ml-sm {
  margin-left: $layout-margin-sm !important;
}

.layout-mt-sm {
  margin-top: $layout-margin-sm !important;
}

.layout-mb-sm {
  margin-bottom: $layout-margin-sm !important;
}

.layout-mr-lg {
  margin-right: $layout-margin-lg !important;
}

.layout-ml-lg {
  margin-left: $layout-margin-lg !important;
}

.layout-mt-lg {
  margin-top: $layout-margin-lg !important;
}

.layout-mb-lg {
  margin-bottom: $layout-margin-lg !important;
}

.layout-mr-xl {
  margin-right: $layout-margin-xl !important;
}

.layout-ml-xl {
  margin-left: $layout-margin-xl !important;
}

.layout-mt-xl {
  margin-top: $layout-margin-xl !important;
}

.layout-mb-xl {
  margin-bottom: $layout-margin-xl !important;
}

.label-margin-bottom {
  margin-bottom: $label-margin-bottom;
}

.layout-padding {
  padding: $layout-padding;
}

.layout-padding-y {
  padding-top: $layout-padding;
  padding-bottom: $layout-padding;
}

.layout-pt {
  padding-top: $layout-padding !important;
}

.layout-pt-xs {
  padding-top: 2px !important;
}
