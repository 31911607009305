.badge--inverted-primary {
  color: $primary-brand;
  background-color: mix($accent, $theme-color-bg, $theme-color-interval);
}

.badge--inverted-secondary {
  color: $secondary;
  background-color: mix($secondary, $theme-color-bg, $theme-color-interval);
}

.badge--primary {
  color: $white;
  background-color: $primary-brand;
}

.badge--secondary {
  color: $white;
  background-color: $secondary-brand;
}
