@mixin background-color-variant($key, $value) {
  .background-color--#{$key} {
    background-color: $value !important;
  }
}

@mixin background-color-hover-tint-variant($key, $value) {
  .background-color-hover-tint--#{$key} {
    &:hover {
      background-color: mix($gray-900, $value, $theme-color-interval) !important;
    }
  }
}

@mixin color-variant($key, $value) {
  .color--#{$key} {
    color: $value !important;
  }
}

//@mixin ap-theme-color($key, $value) {
//  .ap-theme-color--#{$key} {
//    --ap-theme-color: $value;
//  }
//}

@each $key, $value in $ap-user-color-selections {
  @include background-color-variant($key, $value);
  @include background-color-hover-tint-variant($key, $value);
  @include color-variant($key, $value);
  //@include ap-theme-color($key, $value);
}

.color--primary {
  color: $accent !important;
}
