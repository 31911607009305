@import '../_variables';

@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: $modal-backdrop-opacity;
  }
}
