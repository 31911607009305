@mixin assignment-header-variant($key, $value) {
  //.assignment-header--#{$key} {
  //
  //
  //
  //}
}

.assignment-header {
  color: $theme-color-txt;
  padding: $layout-padding $layout-padding-xl $layout-padding-sm;
  min-height: 50px;

  &:hover {
    .assignment-header__move-icon {
      opacity: 1;
    }
  }
}

.assignment-header--background-dark {
  //background-color: $color-river-bed;
  background-color: $theme-color-bg;
}

@each $key, $value in $ap-user-color-selections {
  @include assignment-header-variant($key, $value);
}

.assignment-header--selected {
  .assignment-header__check-icon {
    opacity: 1;
  }
}

.assignment-header--show-unchecked-btn {
  .assignment-header__check-icon {
    opacity: 1;
  }
}

.assignment-header--is-selectable {
  .assignment-header__check-icon {
    transition: opacity 0s;
  }

  &:hover {
    .assignment-header__check-icon {
      opacity: 1;
    }
  }
}

.assignment-header--is-clickable {
  .assignment-header__title {
    cursor: pointer;
  }
}

.assignment-header__title-row {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//.assignment-header__title-row-progress-empty-bar {
//  height: 20px;
//  position: relative;
//
//  &:before {
//    content: '';
//    display: inline-block;
//    width: 100%;
//    height: 3px;
//    position: absolute;
//    bottom: 7px;
//    left: 0;
//    background-color: $color-gray-default;
//  }
//
//}
//
//.assignment-header__title-row-progress {
//
//  display: inline-block;
//  width: 0;
//  height: 3px;
//  position: absolute;
//  left: 0;
//  bottom: 7px;
//  background-color: $primary;
//}

.assignment-header__title {
  @include assignment-card-title();

  color: $theme-color-txt;
  width: 90%;
  height: 100%;
  cursor: pointer;
  line-height: 1;
  word-wrap: break-word;
}

.assignment-header__check-icon {
  opacity: 0;
}

.assignment-header__icons {
  display: flex;
  align-items: center;
}

.assignment-header__link-icon {
  font-size: rem-calc(18px);
  cursor: pointer;
}

.assignment-header__link-icon--active {
  color: $theme-color-txt;
}

.assignment-header__move-icon {
  font-size: rem-calc(16px);
  cursor: move;
  opacity: 0;
}

.assignment-header__menu-icon {
  font-size: rem-calc(16px);
  cursor: pointer;
}

.assignment-header__locker-icon {
  font-size: rem-calc(16px);
  cursor: pointer;

  &.fa-lock-alt {
    color: $secondary-brand;
  }

  &.fa-unlock-alt {
    color: $teal;
  }
}

.assignment-header__expand-icon {
  //color: $color-gray-default;
  font-size: rem-calc(16px);
  cursor: pointer;
}

.assignment-header__color-indicators {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: -3px;
  left: 20px;
}

.assignment-header__row {
  @include make-row();
}

.assignment-header__row--widgets {
  padding-top: $layout-padding-sm;
  padding-bottom: $layout-padding-sm;
  overflow-x: hidden; // to avoid widgets carousel overflow during component initialisation
  margin-left: -8px;
}

.assignment-header__row--tags {
  padding-bottom: $layout-padding;
}

.assignment-header__progress {
  display: block;
  padding-bottom: $layout-padding;
}

.assignment-header__carousel-field {
  position: relative;
  width: 100%;
  padding: 0 $layout-padding-lg * 3 0 $layout-padding;

  // &:first-child {
  //   border-left: none;
  // }
}

.assignment-header__tag-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 $layout-padding;
  flex-wrap: wrap;
}

.assignment-header__tag {
  display: inline-block;
  margin-right: $layout-margin-sm;
  margin-top: $layout-padding-sm;
}
