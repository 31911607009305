@mixin picker-button() {
  display: flex;
  justify-content: center;
  align-items: center;


  height: $input-height;
  min-width: 60px;
  min-height: 60px;
  padding: $input-padding-y $input-padding-x;
  background-color: $white;
  border:1px solid $theme-color-border

}

@mixin picker-button-not-available() {
  &:before {
    content: 'N/A';
    color: $color-heading-gray-dark;
    font-size: rem-calc(14px);
  }
}
