.date-picker__calendar-btn {
  border: 1px solid $input-border-color;
  background-color: $input-bg;

  &:hover {
    background-color: $accent;
  }

  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow
  }

}
