/*
* Attention: Only place "very" generic styles into this file.
*/
@import 'reference';

html {
  min-height: 100%;
  font-size: 14px;
  line-height: 1.25;
}

body {
  min-height: 100%;
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.25;
  background-color: $theme-color-bg;
}

// Prevent angular component tags from effecting flex inheritance
ng-component,
app-secured-header,
app-multi-select-controls,
main{
  display: contents;
}

.ap h1,
.ap h2,
.ap h3 {
  font-family: $font-family;
}

a.plain-link {
  color: $theme-color-txt;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ap .card-ap {
  border-radius: 0;
  box-shadow: 1px 1px 3px 0px #aaa;
}

/* Header */
.ap .print-header{
  display: none;
}


/* My Workspaces */
.ap .list-workspaces {
}

/* Legal Documents */
.ap .text-legal-doc h1 {
  font-size: 1.2rem;
  font-weight: bold;
}

.ap .text-legal-doc h2 {
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: underline;
}

.ap .text-legal-doc h3 {
  font-size: 1rem;
  font-weight: bold;
}

.ap .text-legal-doc p,
.ap .text-legal-doc li {
  font-size: 0.8rem;
}

.ap .text-legal-doc h1,
.ap .text-legal-doc h2,
.ap .text-legal-doc h3,
.ap .text-legal-doc p {
  margin-top: 0.1em;
  margin-bottom: 1.2em;
}

.ap .modal-body .text-legal-doc {
  height: 400px;
  max-height: 100%;
  overflow: auto;
}


/* Generic table fixes */
.table thead th {
  font-weight: 400;
  font-size: 1rem;
  border: 0;
}

.table tbody tr:first-child td {
  border-top: 0;
}


.__rm.modal-body {
  position: relative;
}



.cdk-global-overlay-wrapper,
.cdk-overlay-connected-position-bounding-box{
  overflow-y: auto;
  pointer-events: auto!important;
}

.cdk-overlay-backdrop,
.__rm.modal-backdrop {
  animation-name: modalBgFadeIn;
  animation-duration: $modal-animation-duration;
  background: $gray-900;
}

.__rm.modal-backdrop.show,
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: $modal-backdrop-opacity;
}

.overlay-popover,
.__rm.modal > .modal-dialog {
  animation-name: modalZoomIn !important;
  animation-duration: $modal-animation-duration;
}

.overlay-popover,
.popover-layout,
.__rm.modal-content {
  border: 0;
  border-radius: $border-radius-xl;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
}

.__rm.modal-header {
  border-top-left-radius: $border-radius-xl * 0.95; /* Fix strange white artifact border */
  border-top-right-radius: $border-radius-xl * 0.95;
  box-shadow: $box-shadow-sm;
  & .close {
    text-shadow: none;
  }
}

.__rm.modal-body {
  border-bottom-left-radius: $border-radius-xl * 0.95; /* Fix strange white artifact border */
  border-bottom-right-radius: $border-radius-xl * 0.95;
  background-color: $theme-color-bg;
}

.__rm.modal-title {
  font-size: rem-calc(18px);
}




label[for] {
  cursor: pointer;
}

.loading-icon {
  position: fixed;
  width: auto;
  height: auto;
  top: 27px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

.nav__button {
  @include nav-btn();
}




/* App Alerts */
.app-alert__workspace{
  // position: fixed;
  // bottom: 10px;
  // right: 10px;
  // z-index: 1051; // Above the CDK overlay
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1051; /* Ensure it appears above other content */
  width: auto; /* Adjust width as needed */
  max-width: 300px; /* Adjust max-width as needed */
}



/* Sidebar Nav */
.sidebar-nav {
  @include sidebar-nav-section();
}

.sidebar-nav__title {
  color: $theme-color-txt;
  font-weight: normal;
  text-transform: uppercase;

  font-size: rem-calc(14px);
  display: flex;
  align-items: center;
  margin-bottom: $layout-margin;
  cursor: pointer;
}

.sidebar-nav__menu{

}

.link__item {
  @include sidebar-nav-item();
  display: block;
  padding: 0.25rem 1.25rem;
  border-radius: 0.25rem;

  &.link__item--active {
    color: $secondary-brand;
  }
}

/* Lazy Scroller */
.lazy-scroller-placeholder--horizontal,
.lazy-scroller-container--horizontal{
  height:100%;
}

.lazy-scroller-container{
  animation-name: fadeIn;
  animation-duration: $modal-animation-duration;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



/* Generic Mobile Style Fixes */
@include media-breakpoint-down(sm) {
  .workspace-common__sidebar,
  .workspace-common__sub-sidebar{
    display: none;
  }

  .workspace-content__control-bar{
    & .rich-dropdown__label {
      width: 80px;
    }
    & .controlbar__filter-status-label {
      width: 75px;
    }

    & .expansion-button,
    & .controlbar__content{
      background-color: $theme-color-bg;
    }
  }


  .hero-dropdown__hero-btn {
    flex: auto;
  }

  // ref: https://github.com/ng-bootstrap/ng-bootstrap/issues/1358
  :host ::ng-deep .ngb-dp-months {
    flex-flow: row wrap;
    justify-content: flex-start;
  }
  :host ::ng-deep .ngb-dp-month {
    flex: 1 0 auto;
  }
}
