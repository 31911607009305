@charset "UTF-8";
@import '~@ng-select/ng-select/themes/default.theme.css';
@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffca00;
  --green: #28a745;
  --teal: #1abb9a;
  --cyan: #47c8ae;
  --white: #ffffff;
  --gray: #6d6d6d;
  --gray-dark: #222222;
  --primary: #6f42c1;
  --secondary: #ff4a75;
  --success: #28a745;
  --info: #1abb9a;
  --warning: #ffca00;
  --danger: #dc3545;
  --light: #f7f8f9;
  --dark: #000000;
  --light-200: #ffffff;
  --breakpoint-xxs: 0;
  --breakpoint-iphone5: 320px;
  --breakpoint-xs: 400px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1280px;
  --breakpoint-xl: 1600px;
  --breakpoint-xxl: 1920px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #6f42c1;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #ff4a75;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6d6d6d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 10px;
  font-weight: normal;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6d6d6d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6d6d6d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #000000;
  border-radius: 4px;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #000000;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xs, .container-iphone5, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xs, .container-iphone5, .container {
    max-width: 720px;
  }
}
@media (min-width: 1280px) {
  .container-lg, .container-md, .container-sm, .container-xs, .container-iphone5, .container {
    max-width: 960px;
  }
}
@media (min-width: 1600px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container-iphone5, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-xs,
.col-xs-auto, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col-iphone5,
.col-iphone5-auto, .col-iphone5-12, .col-iphone5-11, .col-iphone5-10, .col-iphone5-9, .col-iphone5-8, .col-iphone5-7, .col-iphone5-6, .col-iphone5-5, .col-iphone5-4, .col-iphone5-3, .col-iphone5-2, .col-iphone5-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 320px) {
  .col-iphone5 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-iphone5-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-iphone5-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-iphone5-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-iphone5-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-iphone5-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-iphone5-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-iphone5-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-iphone5-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-iphone5-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-iphone5-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-iphone5-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-iphone5-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-iphone5-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-iphone5-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-iphone5-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-iphone5-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-iphone5-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-iphone5-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-iphone5-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-iphone5-first {
    order: -1;
  }
  .order-iphone5-last {
    order: 13;
  }
  .order-iphone5-0 {
    order: 0;
  }
  .order-iphone5-1 {
    order: 1;
  }
  .order-iphone5-2 {
    order: 2;
  }
  .order-iphone5-3 {
    order: 3;
  }
  .order-iphone5-4 {
    order: 4;
  }
  .order-iphone5-5 {
    order: 5;
  }
  .order-iphone5-6 {
    order: 6;
  }
  .order-iphone5-7 {
    order: 7;
  }
  .order-iphone5-8 {
    order: 8;
  }
  .order-iphone5-9 {
    order: 9;
  }
  .order-iphone5-10 {
    order: 10;
  }
  .order-iphone5-11 {
    order: 11;
  }
  .order-iphone5-12 {
    order: 12;
  }
  .offset-iphone5-0 {
    margin-left: 0;
  }
  .offset-iphone5-1 {
    margin-left: 8.33333333%;
  }
  .offset-iphone5-2 {
    margin-left: 16.66666667%;
  }
  .offset-iphone5-3 {
    margin-left: 25%;
  }
  .offset-iphone5-4 {
    margin-left: 33.33333333%;
  }
  .offset-iphone5-5 {
    margin-left: 41.66666667%;
  }
  .offset-iphone5-6 {
    margin-left: 50%;
  }
  .offset-iphone5-7 {
    margin-left: 58.33333333%;
  }
  .offset-iphone5-8 {
    margin-left: 66.66666667%;
  }
  .offset-iphone5-9 {
    margin-left: 75%;
  }
  .offset-iphone5-10 {
    margin-left: 83.33333333%;
  }
  .offset-iphone5-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 400px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xs-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xs-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xs-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xs-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xs-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xs-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 13;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333333%;
  }
  .offset-xs-2 {
    margin-left: 16.66666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333333%;
  }
  .offset-xs-5 {
    margin-left: 41.66666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333333%;
  }
  .offset-xs-8 {
    margin-left: 66.66666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333333%;
  }
  .offset-xs-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1920px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000000;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #d5d5d5;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #d5d5d5;
}
.table tbody + tbody {
  border-top: 2px solid #d5d5d5;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #d5d5d5;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #d5d5d5;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #000000;
  background-color: rgba(0, 0, 0, 0.025);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f1ecf9;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c5b3e6;
}

.table-hover .table-primary:hover {
  background-color: #e3d9f3;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #e3d9f3;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffedf1;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ffb7c8;
}

.table-hover .table-secondary:hover {
  background-color: #ffd4dd;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ffd4dd;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #eaf6ec;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a9dcb5;
}

.table-hover .table-success:hover {
  background-color: #d8eedc;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d8eedc;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e8f8f5;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a3e4d7;
}

.table-hover .table-info:hover {
  background-color: #d4f2ed;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #d4f2ed;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fffae6;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffea99;
}

.table-hover .table-warning:hover {
  background-color: #fff5cd;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff5cd;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcebec;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f1aeb5;
}

.table-hover .table-danger:hover {
  background-color: #f9d5d7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9d5d7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfd;
}

.table-hover .table-light:hover {
  background-color: #f1f1f1;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f1f1f1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #e6e6e6;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #999999;
}

.table-hover .table-dark:hover {
  background-color: #d9d9d9;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #d9d9d9;
}

.table-light-200,
.table-light-200 > th,
.table-light-200 > td {
  background-color: white;
}
.table-light-200 th,
.table-light-200 td,
.table-light-200 thead th,
.table-light-200 tbody + tbody {
  border-color: white;
}

.table-hover .table-light-200:hover {
  background-color: #f2f2f2;
}
.table-hover .table-light-200:hover > td,
.table-hover .table-light-200:hover > th {
  background-color: #f2f2f2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.025);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.025);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #222222;
  border-color: #353535;
}
.table .thead-light th {
  color: #464646;
  background-color: #e9e6f3;
  border-color: #d5d5d5;
}

.table-dark {
  color: #ffffff;
  background-color: #222222;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #353535;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 319.98px) {
  .table-responsive-iphone5 {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-iphone5 > .table-bordered {
    border: 0;
  }
}
@media (max-width: 399.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xs > .table-bordered {
    border: 0;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1279.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1919.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464646;
  background-color: #e9e6f3;
  background-clip: padding-box;
  border: 1px solid #b1b1b1;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #464646;
  background-color: #e9e6f3;
  border-color: #b8a2e0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.form-control::placeholder {
  color: #909090;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e6e6e6;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #464646;
}
select.form-control:focus::-ms-value {
  color: #464646;
  background-color: #e9e6f3;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #000000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6d6d6d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.75rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #e9e6f3 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.75rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #e9e6f3 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000000;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #5e37a6;
  border-color: #59339d;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #5e37a6;
  border-color: #59339d;
  box-shadow: 0 0 0 0.2rem rgba(133, 94, 202, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #59339d;
  border-color: #533093;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 94, 202, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #ff4a75;
  border-color: #ff4a75;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #ff2458;
  border-color: #ff174e;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #ff2458;
  border-color: #ff174e;
  box-shadow: 0 0 0 0.2rem rgba(255, 101, 138, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #ff4a75;
  border-color: #ff4a75;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #ff174e;
  border-color: #ff0a44;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 101, 138, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #1abb9a;
  border-color: #1abb9a;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #15997e;
  border-color: #148e75;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #15997e;
  border-color: #148e75;
  box-shadow: 0 0 0 0.2rem rgba(60, 197, 169, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #1abb9a;
  border-color: #1abb9a;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #148e75;
  border-color: #12836c;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 197, 169, 0.5);
}

.btn-warning {
  color: #000000;
  background-color: #ffca00;
  border-color: #ffca00;
}
.btn-warning:hover {
  color: #000000;
  background-color: #d9ac00;
  border-color: #cca200;
}
.btn-warning:focus, .btn-warning.focus {
  color: #000000;
  background-color: #d9ac00;
  border-color: #cca200;
  box-shadow: 0 0 0 0.2rem rgba(217, 172, 0, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #000000;
  background-color: #ffca00;
  border-color: #ffca00;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #000000;
  background-color: #cca200;
  border-color: #bf9800;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 172, 0, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #000000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}
.btn-light:hover {
  color: #000000;
  background-color: #e1e5e9;
  border-color: #dadfe3;
}
.btn-light:focus, .btn-light.focus {
  color: #000000;
  background-color: #e1e5e9;
  border-color: #dadfe3;
  box-shadow: 0 0 0 0.2rem rgba(210, 211, 212, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #000000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: #dadfe3;
  border-color: #d3d8de;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 211, 212, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-light-200 {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-light-200:hover {
  color: #000000;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-light-200:focus, .btn-light-200.focus {
  color: #000000;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}
.btn-light-200.disabled, .btn-light-200:disabled {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-light-200:not(:disabled):not(.disabled):active, .btn-light-200:not(:disabled):not(.disabled).active, .show > .btn-light-200.dropdown-toggle {
  color: #000000;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-light-200:not(:disabled):not(.disabled):active:focus, .btn-light-200:not(:disabled):not(.disabled).active:focus, .show > .btn-light-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 217, 217, 0.5);
}

.btn-outline-primary {
  color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #6f42c1;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

.btn-outline-secondary {
  color: #ff4a75;
  border-color: #ff4a75;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #ff4a75;
  border-color: #ff4a75;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 74, 117, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ff4a75;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #ff4a75;
  border-color: #ff4a75;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 74, 117, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #1abb9a;
  border-color: #1abb9a;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #1abb9a;
  border-color: #1abb9a;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 187, 154, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #1abb9a;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #1abb9a;
  border-color: #1abb9a;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 187, 154, 0.5);
}

.btn-outline-warning {
  color: #ffca00;
  border-color: #ffca00;
}
.btn-outline-warning:hover {
  color: #000000;
  background-color: #ffca00;
  border-color: #ffca00;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 202, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffca00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #000000;
  background-color: #ffca00;
  border-color: #ffca00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 202, 0, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f7f8f9;
  border-color: #f7f8f9;
}
.btn-outline-light:hover {
  color: #000000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f7f8f9;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #000000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}

.btn-outline-dark {
  color: #000000;
  border-color: #000000;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #000000;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-light-200 {
  color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light-200:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light-200:focus, .btn-outline-light-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-light-200.disabled, .btn-outline-light-200:disabled {
  color: #ffffff;
  background-color: transparent;
}
.btn-outline-light-200:not(:disabled):not(.disabled):active, .btn-outline-light-200:not(:disabled):not(.disabled).active, .show > .btn-outline-light-200.dropdown-toggle {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-outline-light-200:not(:disabled):not(.disabled):active:focus, .btn-outline-light-200:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #6f42c1;
  text-decoration: none;
}
.btn-link:hover {
  color: #ff4a75;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6d6d6d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 6px 10px;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 4px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000000;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 320px) {
  .dropdown-menu-iphone5-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-iphone5-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 400px) {
  .dropdown-menu-xs-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xs-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1280px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1920px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e6e6e6;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #000000;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: black;
  text-decoration: none;
  background-color: #e6e6e6;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #6f42c1;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #909090;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6d6d6d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #000000;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464646;
  text-align: center;
  white-space: nowrap;
  background-color: #e6e6e6;
  border: 1px solid #b1b1b1;
  border-radius: 0.75rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #6f42c1;
  background-color: #6f42c1;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #b8a2e0;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #d5c8ed;
  border-color: #d5c8ed;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6d6d6d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e6e6e6;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #e9e6f3;
  border: 1px solid #909090;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.75rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #6f42c1;
  background-color: #6f42c1;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(111, 66, 193, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(111, 66, 193, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(111, 66, 193, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #909090;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #e9e6f3;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(111, 66, 193, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464646;
  vertical-align: middle;
  background: #e9e6f3 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23222222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #b1b1b1;
  border-radius: 0.75rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #b8a2e0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.custom-select:focus::-ms-value {
  color: #464646;
  background-color: #e9e6f3;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6d6d6d;
  background-color: #e6e6e6;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #464646;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #b8a2e0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e6e6e6;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #464646;
  background-color: #e9e6f3;
  border: 1px solid #b1b1b1;
  border-radius: 0.75rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #464646;
  content: "Browse";
  background-color: #e6e6e6;
  border-left: inherit;
  border-radius: 0 0.75rem 0.75rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #6f42c1;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #d5c8ed;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d5d5d5;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #6f42c1;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #d5c8ed;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d5d5d5;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #6f42c1;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #d5c8ed;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #d5d5d5;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d5d5d5;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #909090;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #909090;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #909090;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6d6d6d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #d5d5d5;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e6e6e6 #e6e6e6 #d5d5d5;
}
.nav-tabs .nav-link.disabled {
  color: #6d6d6d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #464646;
  background-color: #ffffff;
  border-color: #d5d5d5 #d5d5d5 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.75rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #6f42c1;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.75rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 319.98px) {
  .navbar-expand-iphone5 > .container,
.navbar-expand-iphone5 > .container-fluid,
.navbar-expand-iphone5 > .container-sm,
.navbar-expand-iphone5 > .container-md,
.navbar-expand-iphone5 > .container-lg,
.navbar-expand-iphone5 > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 320px) {
  .navbar-expand-iphone5 {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-iphone5 .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-iphone5 .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-iphone5 .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-iphone5 > .container,
.navbar-expand-iphone5 > .container-fluid,
.navbar-expand-iphone5 > .container-sm,
.navbar-expand-iphone5 > .container-md,
.navbar-expand-iphone5 > .container-lg,
.navbar-expand-iphone5 > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-iphone5 .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-iphone5 .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-iphone5 .navbar-toggler {
    display: none;
  }
}
@media (max-width: 399.98px) {
  .navbar-expand-xs > .container,
.navbar-expand-xs > .container-fluid,
.navbar-expand-xs > .container-sm,
.navbar-expand-xs > .container-md,
.navbar-expand-xs > .container-lg,
.navbar-expand-xs > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 400px) {
  .navbar-expand-xs {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xs .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xs .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xs .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xs > .container,
.navbar-expand-xs > .container-fluid,
.navbar-expand-xs > .container-sm,
.navbar-expand-xs > .container-md,
.navbar-expand-xs > .container-lg,
.navbar-expand-xs > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xs .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xs .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xs .navbar-toggler {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1279.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1280px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1599.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1919.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1920px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-deck .card {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group > .card {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  font-size: 0.625rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.75rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.35rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.35rem;
  color: #6d6d6d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6d6d6d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.75rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #6f42c1;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
}
.page-link:hover {
  z-index: 2;
  color: #ff4a75;
  text-decoration: none;
  background-color: #e6e6e6;
  border-color: #d5d5d5;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.page-item.disabled .page-link {
  color: #6d6d6d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #d5d5d5;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #6f42c1;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #59339d;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #ff4a75;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #ff174e;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 74, 117, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #1abb9a;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #148e75;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 187, 154, 0.5);
}

.badge-warning {
  color: #000000;
  background-color: #ffca00;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #000000;
  background-color: #cca200;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 202, 0, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #000000;
  background-color: #f7f8f9;
}
a.badge-light:hover, a.badge-light:focus {
  color: #000000;
  background-color: #dadfe3;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #000000;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-light-200 {
  color: #000000;
  background-color: #ffffff;
}
a.badge-light-200:hover, a.badge-light-200:focus {
  color: #000000;
  background-color: #e6e6e6;
}
a.badge-light-200:focus, a.badge-light-200.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e6e6e6;
  border-radius: 1rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.75rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #2c1a4d;
  background-color: #f1ecf9;
  border-color: #f1ecf9;
}
.alert-primary hr {
  border-top-color: #e3d9f3;
}
.alert-primary .alert-link {
  color: #160d27;
}

.alert-secondary {
  color: #661e2f;
  background-color: #ffedf1;
  border-color: #ffedf1;
}
.alert-secondary hr {
  border-top-color: #ffd4dd;
}
.alert-secondary .alert-link {
  color: #3f121d;
}

.alert-success {
  color: #10431c;
  background-color: #eaf6ec;
  border-color: #eaf6ec;
}
.alert-success hr {
  border-top-color: #d8eedc;
}
.alert-success .alert-link {
  color: #061a0b;
}

.alert-info {
  color: #0a4b3e;
  background-color: #e8f8f5;
  border-color: #e8f8f5;
}
.alert-info hr {
  border-top-color: #d4f2ed;
}
.alert-info .alert-link {
  color: #041e19;
}

.alert-warning {
  color: #665100;
  background-color: #fffae6;
  border-color: #fffae6;
}
.alert-warning hr {
  border-top-color: #fff5cd;
}
.alert-warning .alert-link {
  color: #332900;
}

.alert-danger {
  color: #58151c;
  background-color: #fcebec;
  border-color: #fcebec;
}
.alert-danger hr {
  border-top-color: #f9d5d7;
}
.alert-danger .alert-link {
  color: #2f0b0f;
}

.alert-light {
  color: #636364;
  background-color: #fefefe;
  border-color: #fefefe;
}
.alert-light hr {
  border-top-color: #f1f1f1;
}
.alert-light .alert-link {
  color: #4a4a4a;
}

.alert-dark {
  color: black;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}
.alert-dark hr {
  border-top-color: #d9d9d9;
}
.alert-dark .alert-link {
  color: black;
}

.alert-light-200 {
  color: #666666;
  background-color: white;
  border-color: white;
}
.alert-light-200 hr {
  border-top-color: #f2f2f2;
}
.alert-light-200 .alert-link {
  color: #4d4d4d;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e6e6e6;
  border-radius: 0.75rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #6f42c1;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.75rem;
}

.list-group-item-action {
  width: 100%;
  color: #464646;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #464646;
  text-decoration: none;
  background-color: #f7f8f9;
}
.list-group-item-action:active {
  color: #000000;
  background-color: #e6e6e6;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6d6d6d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 320px) {
  .list-group-horizontal-iphone5 {
    flex-direction: row;
  }
  .list-group-horizontal-iphone5 > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-iphone5 > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-iphone5 > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-iphone5 > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-iphone5 > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 400px) {
  .list-group-horizontal-xs {
    flex-direction: row;
  }
  .list-group-horizontal-xs > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1280px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1920px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #2c1a4d;
  background-color: #f1ecf9;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #2c1a4d;
  background-color: #e3d9f3;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #2c1a4d;
  border-color: #2c1a4d;
}

.list-group-item-secondary {
  color: #661e2f;
  background-color: #ffedf1;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #661e2f;
  background-color: #ffd4dd;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #661e2f;
  border-color: #661e2f;
}

.list-group-item-success {
  color: #10431c;
  background-color: #eaf6ec;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #10431c;
  background-color: #d8eedc;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #10431c;
  border-color: #10431c;
}

.list-group-item-info {
  color: #0a4b3e;
  background-color: #e8f8f5;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0a4b3e;
  background-color: #d4f2ed;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0a4b3e;
  border-color: #0a4b3e;
}

.list-group-item-warning {
  color: #665100;
  background-color: #fffae6;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #665100;
  background-color: #fff5cd;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #665100;
  border-color: #665100;
}

.list-group-item-danger {
  color: #58151c;
  background-color: #fcebec;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #58151c;
  background-color: #f9d5d7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #58151c;
  border-color: #58151c;
}

.list-group-item-light {
  color: #636364;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636364;
  background-color: #f1f1f1;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #636364;
  border-color: #636364;
}

.list-group-item-dark {
  color: black;
  background-color: #e6e6e6;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: black;
  background-color: #d9d9d9;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-light-200 {
  color: #666666;
  background-color: white;
}
.list-group-item-light-200.list-group-item-action:hover, .list-group-item-light-200.list-group-item-action:focus {
  color: #666666;
  background-color: #f2f2f2;
}
.list-group-item-light-200.list-group-item-action.active {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.4;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #d5d5d5;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #d5d5d5;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 736px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 556px;
  }
}
@media (min-width: 1280px) {
  .modal-lg,
.modal-xl {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1400px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #59359a;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #59359a;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #59359a;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #59359a;
}

.tooltip-inner {
  max-width: 300px;
  padding: 0.75rem 0.75rem;
  color: #ffffff;
  text-align: center;
  background-color: #59359a;
  border-radius: 0.75rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
  max-width: initial;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 6px;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 6px 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 6px 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 1.5rem;
  color: #000000;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #6f42c1 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #59339d !important;
}

.bg-secondary {
  background-color: #ff4a75 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #ff174e !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #1abb9a !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #148e75 !important;
}

.bg-warning {
  background-color: #ffca00 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cca200 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f7f8f9 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dadfe3 !important;
}

.bg-dark {
  background-color: #000000 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-light-200 {
  background-color: #ffffff !important;
}

a.bg-light-200:hover, a.bg-light-200:focus,
button.bg-light-200:hover,
button.bg-light-200:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #d5d5d5 !important;
}

.border-top {
  border-top: 1px solid #d5d5d5 !important;
}

.border-right {
  border-right: 1px solid #d5d5d5 !important;
}

.border-bottom {
  border-bottom: 1px solid #d5d5d5 !important;
}

.border-left {
  border-left: 1px solid #d5d5d5 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #6f42c1 !important;
}

.border-secondary {
  border-color: #ff4a75 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #1abb9a !important;
}

.border-warning {
  border-color: #ffca00 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f7f8f9 !important;
}

.border-dark {
  border-color: #000000 !important;
}

.border-light-200 {
  border-color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 4px !important;
}

.rounded {
  border-radius: 0.75rem !important;
}

.rounded-top {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-right {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-left {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-lg {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 320px) {
  .d-iphone5-none {
    display: none !important;
  }
  .d-iphone5-inline {
    display: inline !important;
  }
  .d-iphone5-inline-block {
    display: inline-block !important;
  }
  .d-iphone5-block {
    display: block !important;
  }
  .d-iphone5-table {
    display: table !important;
  }
  .d-iphone5-table-row {
    display: table-row !important;
  }
  .d-iphone5-table-cell {
    display: table-cell !important;
  }
  .d-iphone5-flex {
    display: flex !important;
  }
  .d-iphone5-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 400px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1920px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 320px) {
  .flex-iphone5-row {
    flex-direction: row !important;
  }
  .flex-iphone5-column {
    flex-direction: column !important;
  }
  .flex-iphone5-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-iphone5-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-iphone5-wrap {
    flex-wrap: wrap !important;
  }
  .flex-iphone5-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-iphone5-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-iphone5-fill {
    flex: 1 1 auto !important;
  }
  .flex-iphone5-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-iphone5-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-iphone5-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-iphone5-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-iphone5-start {
    justify-content: flex-start !important;
  }
  .justify-content-iphone5-end {
    justify-content: flex-end !important;
  }
  .justify-content-iphone5-center {
    justify-content: center !important;
  }
  .justify-content-iphone5-between {
    justify-content: space-between !important;
  }
  .justify-content-iphone5-around {
    justify-content: space-around !important;
  }
  .align-items-iphone5-start {
    align-items: flex-start !important;
  }
  .align-items-iphone5-end {
    align-items: flex-end !important;
  }
  .align-items-iphone5-center {
    align-items: center !important;
  }
  .align-items-iphone5-baseline {
    align-items: baseline !important;
  }
  .align-items-iphone5-stretch {
    align-items: stretch !important;
  }
  .align-content-iphone5-start {
    align-content: flex-start !important;
  }
  .align-content-iphone5-end {
    align-content: flex-end !important;
  }
  .align-content-iphone5-center {
    align-content: center !important;
  }
  .align-content-iphone5-between {
    align-content: space-between !important;
  }
  .align-content-iphone5-around {
    align-content: space-around !important;
  }
  .align-content-iphone5-stretch {
    align-content: stretch !important;
  }
  .align-self-iphone5-auto {
    align-self: auto !important;
  }
  .align-self-iphone5-start {
    align-self: flex-start !important;
  }
  .align-self-iphone5-end {
    align-self: flex-end !important;
  }
  .align-self-iphone5-center {
    align-self: center !important;
  }
  .align-self-iphone5-baseline {
    align-self: baseline !important;
  }
  .align-self-iphone5-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 400px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1280px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1920px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 320px) {
  .float-iphone5-left {
    float: left !important;
  }
  .float-iphone5-right {
    float: right !important;
  }
  .float-iphone5-none {
    float: none !important;
  }
}
@media (min-width: 400px) {
  .float-xs-left {
    float: left !important;
  }
  .float-xs-right {
    float: right !important;
  }
  .float-xs-none {
    float: none !important;
  }
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1280px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1920px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 320px) {
  .m-iphone5-0 {
    margin: 0 !important;
  }
  .mt-iphone5-0,
.my-iphone5-0 {
    margin-top: 0 !important;
  }
  .mr-iphone5-0,
.mx-iphone5-0 {
    margin-right: 0 !important;
  }
  .mb-iphone5-0,
.my-iphone5-0 {
    margin-bottom: 0 !important;
  }
  .ml-iphone5-0,
.mx-iphone5-0 {
    margin-left: 0 !important;
  }
  .m-iphone5-1 {
    margin: 0.25rem !important;
  }
  .mt-iphone5-1,
.my-iphone5-1 {
    margin-top: 0.25rem !important;
  }
  .mr-iphone5-1,
.mx-iphone5-1 {
    margin-right: 0.25rem !important;
  }
  .mb-iphone5-1,
.my-iphone5-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-iphone5-1,
.mx-iphone5-1 {
    margin-left: 0.25rem !important;
  }
  .m-iphone5-2 {
    margin: 0.5rem !important;
  }
  .mt-iphone5-2,
.my-iphone5-2 {
    margin-top: 0.5rem !important;
  }
  .mr-iphone5-2,
.mx-iphone5-2 {
    margin-right: 0.5rem !important;
  }
  .mb-iphone5-2,
.my-iphone5-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-iphone5-2,
.mx-iphone5-2 {
    margin-left: 0.5rem !important;
  }
  .m-iphone5-3 {
    margin: 1rem !important;
  }
  .mt-iphone5-3,
.my-iphone5-3 {
    margin-top: 1rem !important;
  }
  .mr-iphone5-3,
.mx-iphone5-3 {
    margin-right: 1rem !important;
  }
  .mb-iphone5-3,
.my-iphone5-3 {
    margin-bottom: 1rem !important;
  }
  .ml-iphone5-3,
.mx-iphone5-3 {
    margin-left: 1rem !important;
  }
  .m-iphone5-4 {
    margin: 1.5rem !important;
  }
  .mt-iphone5-4,
.my-iphone5-4 {
    margin-top: 1.5rem !important;
  }
  .mr-iphone5-4,
.mx-iphone5-4 {
    margin-right: 1.5rem !important;
  }
  .mb-iphone5-4,
.my-iphone5-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-iphone5-4,
.mx-iphone5-4 {
    margin-left: 1.5rem !important;
  }
  .m-iphone5-5 {
    margin: 3rem !important;
  }
  .mt-iphone5-5,
.my-iphone5-5 {
    margin-top: 3rem !important;
  }
  .mr-iphone5-5,
.mx-iphone5-5 {
    margin-right: 3rem !important;
  }
  .mb-iphone5-5,
.my-iphone5-5 {
    margin-bottom: 3rem !important;
  }
  .ml-iphone5-5,
.mx-iphone5-5 {
    margin-left: 3rem !important;
  }
  .p-iphone5-0 {
    padding: 0 !important;
  }
  .pt-iphone5-0,
.py-iphone5-0 {
    padding-top: 0 !important;
  }
  .pr-iphone5-0,
.px-iphone5-0 {
    padding-right: 0 !important;
  }
  .pb-iphone5-0,
.py-iphone5-0 {
    padding-bottom: 0 !important;
  }
  .pl-iphone5-0,
.px-iphone5-0 {
    padding-left: 0 !important;
  }
  .p-iphone5-1 {
    padding: 0.25rem !important;
  }
  .pt-iphone5-1,
.py-iphone5-1 {
    padding-top: 0.25rem !important;
  }
  .pr-iphone5-1,
.px-iphone5-1 {
    padding-right: 0.25rem !important;
  }
  .pb-iphone5-1,
.py-iphone5-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-iphone5-1,
.px-iphone5-1 {
    padding-left: 0.25rem !important;
  }
  .p-iphone5-2 {
    padding: 0.5rem !important;
  }
  .pt-iphone5-2,
.py-iphone5-2 {
    padding-top: 0.5rem !important;
  }
  .pr-iphone5-2,
.px-iphone5-2 {
    padding-right: 0.5rem !important;
  }
  .pb-iphone5-2,
.py-iphone5-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-iphone5-2,
.px-iphone5-2 {
    padding-left: 0.5rem !important;
  }
  .p-iphone5-3 {
    padding: 1rem !important;
  }
  .pt-iphone5-3,
.py-iphone5-3 {
    padding-top: 1rem !important;
  }
  .pr-iphone5-3,
.px-iphone5-3 {
    padding-right: 1rem !important;
  }
  .pb-iphone5-3,
.py-iphone5-3 {
    padding-bottom: 1rem !important;
  }
  .pl-iphone5-3,
.px-iphone5-3 {
    padding-left: 1rem !important;
  }
  .p-iphone5-4 {
    padding: 1.5rem !important;
  }
  .pt-iphone5-4,
.py-iphone5-4 {
    padding-top: 1.5rem !important;
  }
  .pr-iphone5-4,
.px-iphone5-4 {
    padding-right: 1.5rem !important;
  }
  .pb-iphone5-4,
.py-iphone5-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-iphone5-4,
.px-iphone5-4 {
    padding-left: 1.5rem !important;
  }
  .p-iphone5-5 {
    padding: 3rem !important;
  }
  .pt-iphone5-5,
.py-iphone5-5 {
    padding-top: 3rem !important;
  }
  .pr-iphone5-5,
.px-iphone5-5 {
    padding-right: 3rem !important;
  }
  .pb-iphone5-5,
.py-iphone5-5 {
    padding-bottom: 3rem !important;
  }
  .pl-iphone5-5,
.px-iphone5-5 {
    padding-left: 3rem !important;
  }
  .m-iphone5-n1 {
    margin: -0.25rem !important;
  }
  .mt-iphone5-n1,
.my-iphone5-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-iphone5-n1,
.mx-iphone5-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-iphone5-n1,
.my-iphone5-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-iphone5-n1,
.mx-iphone5-n1 {
    margin-left: -0.25rem !important;
  }
  .m-iphone5-n2 {
    margin: -0.5rem !important;
  }
  .mt-iphone5-n2,
.my-iphone5-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-iphone5-n2,
.mx-iphone5-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-iphone5-n2,
.my-iphone5-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-iphone5-n2,
.mx-iphone5-n2 {
    margin-left: -0.5rem !important;
  }
  .m-iphone5-n3 {
    margin: -1rem !important;
  }
  .mt-iphone5-n3,
.my-iphone5-n3 {
    margin-top: -1rem !important;
  }
  .mr-iphone5-n3,
.mx-iphone5-n3 {
    margin-right: -1rem !important;
  }
  .mb-iphone5-n3,
.my-iphone5-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-iphone5-n3,
.mx-iphone5-n3 {
    margin-left: -1rem !important;
  }
  .m-iphone5-n4 {
    margin: -1.5rem !important;
  }
  .mt-iphone5-n4,
.my-iphone5-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-iphone5-n4,
.mx-iphone5-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-iphone5-n4,
.my-iphone5-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-iphone5-n4,
.mx-iphone5-n4 {
    margin-left: -1.5rem !important;
  }
  .m-iphone5-n5 {
    margin: -3rem !important;
  }
  .mt-iphone5-n5,
.my-iphone5-n5 {
    margin-top: -3rem !important;
  }
  .mr-iphone5-n5,
.mx-iphone5-n5 {
    margin-right: -3rem !important;
  }
  .mb-iphone5-n5,
.my-iphone5-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-iphone5-n5,
.mx-iphone5-n5 {
    margin-left: -3rem !important;
  }
  .m-iphone5-auto {
    margin: auto !important;
  }
  .mt-iphone5-auto,
.my-iphone5-auto {
    margin-top: auto !important;
  }
  .mr-iphone5-auto,
.mx-iphone5-auto {
    margin-right: auto !important;
  }
  .mb-iphone5-auto,
.my-iphone5-auto {
    margin-bottom: auto !important;
  }
  .ml-iphone5-auto,
.mx-iphone5-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 400px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
.my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
.mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
.my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
.mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
.my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
.mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
.my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
.mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
.my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
.mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
.my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
.mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
.my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
.mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
.my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
.mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
.my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
.mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
.my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
.mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
.my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
.mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
.my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
.mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
.py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
.px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
.py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
.px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
.py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
.px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
.py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
.px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
.py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
.px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
.py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
.px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
.py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
.px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
.py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
.px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
.py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
.px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
.py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
.px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
.py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
.px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
.py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
.px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.25rem !important;
  }
  .mt-xs-n1,
.my-xs-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xs-n1,
.mx-xs-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xs-n1,
.my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xs-n1,
.mx-xs-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
.my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
.mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
.my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
.mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
.my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
.mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
.my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
.mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
.my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
.mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
.my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
.mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
.my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
.mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
.my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
.mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
.my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
.mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
.my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
.mx-xs-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1920px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 320px) {
  .text-iphone5-left {
    text-align: left !important;
  }
  .text-iphone5-right {
    text-align: right !important;
  }
  .text-iphone5-center {
    text-align: center !important;
  }
}
@media (min-width: 400px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #6f42c1 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #4e2d89 !important;
}

.text-secondary {
  color: #ff4a75 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #fd003c !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #1abb9a !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117863 !important;
}

.text-warning {
  color: #ffca00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b38d00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f7f8f9 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd2d8 !important;
}

.text-dark {
  color: #000000 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-light-200 {
  color: #ffffff !important;
}

a.text-light-200:hover, a.text-light-200:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #000000 !important;
}

.text-muted {
  color: #6d6d6d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #909090;
    page-break-inside: avoid;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1280px !important;
  }
  .container {
    min-width: 1280px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #d5d5d5 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #d5d5d5;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #d5d5d5;
  }
}
.ap-indention {
  display: inline-block;
  width: 0;
  height: 1px;
}

.ap-indention--1 {
  width: 1.5rem;
}

.ap-indention--2 {
  width: 3rem;
}

.ap-indention--3 {
  width: 4.5rem;
}

.ap-indention--4 {
  width: 6rem;
}

.ap-indention--5 {
  width: 7.5rem;
}

.ap-indention--6 {
  width: 9rem;
}

.ap-indention--7 {
  width: 10.5rem;
}

.layout-mr {
  margin-right: 0.75rem !important;
}

.layout-mr-n {
  margin-right: -0.75rem !important;
}

.layout-ml {
  margin-left: 0.75rem !important;
}

.layout-ml-n {
  margin-left: -0.75rem !important;
}

.layout-mt {
  margin-top: 0.75rem !important;
}

.layout-mt-n {
  margin-top: -0.75rem !important;
}

.layout-mb {
  margin-bottom: 0.75rem !important;
}

.layout-mb-n {
  margin-bottom: -0.75rem !important;
}

.layout-mr-sm {
  margin-right: 0.35rem !important;
}

.layout-ml-sm {
  margin-left: 0.35rem !important;
}

.layout-mt-sm {
  margin-top: 0.35rem !important;
}

.layout-mb-sm {
  margin-bottom: 0.35rem !important;
}

.layout-mr-lg {
  margin-right: 1rem !important;
}

.layout-ml-lg {
  margin-left: 1rem !important;
}

.layout-mt-lg {
  margin-top: 1rem !important;
}

.layout-mb-lg {
  margin-bottom: 1rem !important;
}

.layout-mr-xl {
  margin-right: 1.5rem !important;
}

.layout-ml-xl {
  margin-left: 1.5rem !important;
}

.layout-mt-xl {
  margin-top: 1.5rem !important;
}

.layout-mb-xl {
  margin-bottom: 1.5rem !important;
}

.label-margin-bottom {
  margin-bottom: 0.5rem;
}

.layout-padding {
  padding: 0.75rem;
}

.layout-padding-y {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.layout-pt {
  padding-top: 0.75rem !important;
}

.layout-pt-xs {
  padding-top: 2px !important;
}

.background-color--not-available {
  background-color: #f7f8f9 !important;
}

.background-color-hover-tint--not-available:hover {
  background-color: #dedfe0 !important;
}

.color--not-available {
  color: #f7f8f9 !important;
}

.background-color--color-49546a {
  background-color: rgba(73, 84, 106, 0.2) !important;
}

.background-color-hover-tint--color-49546a:hover {
  background-color: rgba(37, 42, 53, 0.28) !important;
}

.color--color-49546a {
  color: rgba(73, 84, 106, 0.2) !important;
}

.background-color--color-ff4a75 {
  background-color: rgba(255, 74, 117, 0.2) !important;
}

.background-color-hover-tint--color-ff4a75:hover {
  background-color: rgba(128, 37, 59, 0.28) !important;
}

.color--color-ff4a75 {
  color: rgba(255, 74, 117, 0.2) !important;
}

.background-color--color-6f41c1 {
  background-color: rgba(111, 65, 193, 0.2) !important;
}

.background-color-hover-tint--color-6f41c1:hover {
  background-color: rgba(56, 33, 97, 0.28) !important;
}

.color--color-6f41c1 {
  color: rgba(111, 65, 193, 0.2) !important;
}

.background-color--color-007bff {
  background-color: rgba(0, 123, 255, 0.2) !important;
}

.background-color-hover-tint--color-007bff:hover {
  background-color: rgba(0, 62, 128, 0.28) !important;
}

.color--color-007bff {
  color: rgba(0, 123, 255, 0.2) !important;
}

.background-color--color-eb842d {
  background-color: rgba(235, 132, 45, 0.2) !important;
}

.background-color-hover-tint--color-eb842d:hover {
  background-color: rgba(118, 66, 23, 0.28) !important;
}

.color--color-eb842d {
  color: rgba(235, 132, 45, 0.2) !important;
}

.background-color--color-39b49b {
  background-color: rgba(57, 180, 155, 0.2) !important;
}

.background-color-hover-tint--color-39b49b:hover {
  background-color: rgba(29, 90, 78, 0.28) !important;
}

.color--color-39b49b {
  color: rgba(57, 180, 155, 0.2) !important;
}

.background-color--color-708090 {
  background-color: rgba(112, 128, 144, 0.2) !important;
}

.background-color-hover-tint--color-708090:hover {
  background-color: rgba(56, 64, 72, 0.28) !important;
}

.color--color-708090 {
  color: rgba(112, 128, 144, 0.2) !important;
}

.background-color--color-c62f3e {
  background-color: rgba(198, 47, 62, 0.2) !important;
}

.background-color-hover-tint--color-c62f3e:hover {
  background-color: rgba(99, 24, 31, 0.28) !important;
}

.color--color-c62f3e {
  color: rgba(198, 47, 62, 0.2) !important;
}

.background-color--color-856088 {
  background-color: rgba(133, 96, 136, 0.2) !important;
}

.background-color-hover-tint--color-856088:hover {
  background-color: rgba(67, 48, 68, 0.28) !important;
}

.color--color-856088 {
  color: rgba(133, 96, 136, 0.2) !important;
}

.background-color--color-2072af {
  background-color: rgba(32, 114, 175, 0.2) !important;
}

.background-color-hover-tint--color-2072af:hover {
  background-color: rgba(16, 57, 88, 0.28) !important;
}

.color--color-2072af {
  color: rgba(32, 114, 175, 0.2) !important;
}

.background-color--color-de7c67 {
  background-color: rgba(222, 124, 103, 0.2) !important;
}

.background-color-hover-tint--color-de7c67:hover {
  background-color: rgba(111, 62, 52, 0.28) !important;
}

.color--color-de7c67 {
  color: rgba(222, 124, 103, 0.2) !important;
}

.background-color--color-70a951 {
  background-color: rgba(112, 169, 81, 0.2) !important;
}

.background-color-hover-tint--color-70a951:hover {
  background-color: rgba(56, 85, 41, 0.28) !important;
}

.color--color-70a951 {
  color: rgba(112, 169, 81, 0.2) !important;
}

.background-color--color-0f1f40 {
  background-color: rgba(15, 31, 64, 0.2) !important;
}

.background-color-hover-tint--color-0f1f40:hover {
  background-color: rgba(8, 16, 32, 0.28) !important;
}

.color--color-0f1f40 {
  color: rgba(15, 31, 64, 0.2) !important;
}

.background-color--color-d471ac {
  background-color: rgba(212, 113, 172, 0.2) !important;
}

.background-color-hover-tint--color-d471ac:hover {
  background-color: rgba(106, 57, 86, 0.28) !important;
}

.color--color-d471ac {
  color: rgba(212, 113, 172, 0.2) !important;
}

.background-color--color-9e71bd {
  background-color: rgba(158, 113, 189, 0.2) !important;
}

.background-color-hover-tint--color-9e71bd:hover {
  background-color: rgba(79, 57, 95, 0.28) !important;
}

.color--color-9e71bd {
  color: rgba(158, 113, 189, 0.2) !important;
}

.background-color--color-51acca {
  background-color: rgba(81, 172, 202, 0.2) !important;
}

.background-color-hover-tint--color-51acca:hover {
  background-color: rgba(41, 86, 101, 0.28) !important;
}

.color--color-51acca {
  color: rgba(81, 172, 202, 0.2) !important;
}

.background-color--color-e8b717 {
  background-color: rgba(232, 183, 23, 0.2) !important;
}

.background-color-hover-tint--color-e8b717:hover {
  background-color: rgba(116, 92, 12, 0.28) !important;
}

.color--color-e8b717 {
  color: rgba(232, 183, 23, 0.2) !important;
}

.background-color--color-79d18a {
  background-color: rgba(121, 209, 138, 0.2) !important;
}

.background-color-hover-tint--color-79d18a:hover {
  background-color: rgba(61, 105, 69, 0.28) !important;
}

.color--color-79d18a {
  color: rgba(121, 209, 138, 0.2) !important;
}

.background-color--color-848484 {
  background-color: rgba(132, 132, 132, 0.2) !important;
}

.background-color-hover-tint--color-848484:hover {
  background-color: rgba(66, 66, 66, 0.28) !important;
}

.color--color-848484 {
  color: rgba(132, 132, 132, 0.2) !important;
}

.background-color--color-c13ea5 {
  background-color: rgba(193, 62, 165, 0.2) !important;
}

.background-color-hover-tint--color-c13ea5:hover {
  background-color: rgba(97, 31, 83, 0.28) !important;
}

.color--color-c13ea5 {
  color: rgba(193, 62, 165, 0.2) !important;
}

.background-color--color-c392e0 {
  background-color: rgba(195, 146, 224, 0.2) !important;
}

.background-color-hover-tint--color-c392e0:hover {
  background-color: rgba(98, 73, 112, 0.28) !important;
}

.color--color-c392e0 {
  color: rgba(195, 146, 224, 0.2) !important;
}

.background-color--color-67c9e3 {
  background-color: rgba(103, 201, 227, 0.2) !important;
}

.background-color-hover-tint--color-67c9e3:hover {
  background-color: rgba(52, 101, 114, 0.28) !important;
}

.color--color-67c9e3 {
  color: rgba(103, 201, 227, 0.2) !important;
}

.background-color--color-f1dc4d {
  background-color: rgba(241, 220, 77, 0.2) !important;
}

.background-color-hover-tint--color-f1dc4d:hover {
  background-color: rgba(121, 110, 39, 0.28) !important;
}

.color--color-f1dc4d {
  color: rgba(241, 220, 77, 0.2) !important;
}

.color--primary {
  color: #49546a !important;
}

.ap-color-link {
  color: #6f42c1 !important;
}

.ap-color-danger {
  color: #dc3545 !important;
}

.ap-color-low-key {
  color: #515f6d !important;
}

.ap-color-positive {
  color: #6f42c1 !important;
}

.ap-color-negative {
  color: #f06292 !important;
}

/*!
 * Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  -webkit-animation-name: fa-beat;
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  -webkit-animation-name: fa-bounce;
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  -webkit-animation-name: fa-fade;
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  -webkit-animation-name: fa-flip;
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  -webkit-animation-name: fa-shake;
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0);
  }
}
@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "0";
}

.fa-1::before {
  content: "1";
}

.fa-2::before {
  content: "2";
}

.fa-3::before {
  content: "3";
}

.fa-4::before {
  content: "4";
}

.fa-5::before {
  content: "5";
}

.fa-6::before {
  content: "6";
}

.fa-7::before {
  content: "7";
}

.fa-8::before {
  content: "8";
}

.fa-9::before {
  content: "9";
}

.fa-00::before {
  content: "\e467";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-a::before {
  content: "A";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-accent-grave::before {
  content: "`";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-album::before {
  content: "\f89f";
}

.fa-album-circle-plus::before {
  content: "\e48c";
}

.fa-album-circle-user::before {
  content: "\e48d";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-album-collection-circle-plus::before {
  content: "\e48e";
}

.fa-album-collection-circle-user::before {
  content: "\e48f";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-ampersand::before {
  content: "&";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-angel::before {
  content: "\f779";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-apartment::before {
  content: "\e468";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-apostrophe::before {
  content: "'";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-archway::before {
  content: "\f557";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-arrow-left-long-to-line::before {
  content: "\e3d4";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-arrow-right-long-to-line::before {
  content: "\e3d5";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-arrow-turn-down-right::before {
  content: "\e3d6";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-asterisk::before {
  content: "*";
}

.fa-at::before {
  content: "@";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-award::before {
  content: "\f559";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-b::before {
  content: "B";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-badge::before {
  content: "\f335";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-badminton::before {
  content: "\e33a";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-bagel::before {
  content: "\e3d7";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-baguette::before {
  content: "\e3d8";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-baseball::before {
  content: "\f433";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-basketball::before {
  content: "\f434";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-bed::before {
  content: "\f236";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-binary::before {
  content: "\e33b";
}

.fa-binary-circle-check::before {
  content: "\e33c";
}

.fa-binary-lock::before {
  content: "\e33d";
}

.fa-binary-slash::before {
  content: "\e33e";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-bird::before {
  content: "\e469";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-blanket-fire::before {
  content: "\e3da";
}

.fa-blender::before {
  content: "\f517";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-block::before {
  content: "\e46a";
}

.fa-block-brick::before {
  content: "\e3db";
}

.fa-wall-brick::before {
  content: "\e3db";
}

.fa-block-brick-fire::before {
  content: "\e3dc";
}

.fa-firewall::before {
  content: "\e3dc";
}

.fa-block-question::before {
  content: "\e3dd";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-blog::before {
  content: "\f781";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-bold::before {
  content: "\f032";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-zap::before {
  content: "\f0e7";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-book::before {
  content: "\f02d";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-books::before {
  content: "\f5db";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-boot::before {
  content: "\f782";
}

.fa-boot-heeled::before {
  content: "\e33f";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-bowl-scoop::before {
  content: "\e3de";
}

.fa-bowl-shaved-ice::before {
  content: "\e3de";
}

.fa-bowl-scoops::before {
  content: "\e3df";
}

.fa-bowl-soft-serve::before {
  content: "\e46b";
}

.fa-bowl-spoon::before {
  content: "\e3e0";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-box::before {
  content: "\f466";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-bracket-curly::before {
  content: "{";
}

.fa-bracket-curly-left::before {
  content: "{";
}

.fa-bracket-curly-right::before {
  content: "}";
}

.fa-bracket-round::before {
  content: "(";
}

.fa-parenthesis::before {
  content: "(";
}

.fa-bracket-round-right::before {
  content: ")";
}

.fa-bracket-square::before {
  content: "[";
}

.fa-bracket::before {
  content: "[";
}

.fa-bracket-left::before {
  content: "[";
}

.fa-bracket-square-right::before {
  content: "]";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-brazilian-real-sign::before {
  content: "\e46c";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-bread-slice-butter::before {
  content: "\e3e1";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-broccoli::before {
  content: "\e3e2";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-bug::before {
  content: "\f188";
}

.fa-bug-slash::before {
  content: "\e490";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-building-columns::before {
  content: "\f19c";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-museum::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-burger-lettuce::before {
  content: "\e3e3";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-bus::before {
  content: "\f207";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-butter::before {
  content: "\e3e4";
}

.fa-c::before {
  content: "C";
}

.fa-cabin::before {
  content: "\e46d";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-cable-car::before {
  content: "\e0cf";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-cake::before {
  content: "\f1fd";
}

.fa-cake-slice::before {
  content: "\e3e5";
}

.fa-shortcake::before {
  content: "\e3e5";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-calendar-circle-exclamation::before {
  content: "\e46e";
}

.fa-calendar-circle-minus::before {
  content: "\e46f";
}

.fa-calendar-circle-plus::before {
  content: "\e470";
}

.fa-calendar-circle-user::before {
  content: "\e471";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-calendar-lines-pen::before {
  content: "\e472";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-screenshot::before {
  content: "\e0da";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-can-food::before {
  content: "\e3e6";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-candy::before {
  content: "\e3e7";
}

.fa-candy-bar::before {
  content: "\e3e8";
}

.fa-chocolate-bar::before {
  content: "\e3e8";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-car-bolt::before {
  content: "\e341";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-car-circle-bolt::before {
  content: "\e342";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-car-mirrors::before {
  content: "\e343";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-car-side-bolt::before {
  content: "\e344";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-card-club::before {
  content: "\e3e9";
}

.fa-card-diamond::before {
  content: "\e3ea";
}

.fa-card-heart::before {
  content: "\e3eb";
}

.fa-card-spade::before {
  content: "\e3ec";
}

.fa-cards::before {
  content: "\e3ed";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-cart-arrow-up::before {
  content: "\e3ee";
}

.fa-cart-circle-arrow-down::before {
  content: "\e3ef";
}

.fa-cart-circle-arrow-up::before {
  content: "\e3f0";
}

.fa-cart-circle-check::before {
  content: "\e3f1";
}

.fa-cart-circle-exclamation::before {
  content: "\e3f2";
}

.fa-cart-circle-plus::before {
  content: "\e3f3";
}

.fa-cart-circle-xmark::before {
  content: "\e3f4";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-cent-sign::before {
  content: "\e3f5";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-chart-simple::before {
  content: "\e473";
}

.fa-chart-simple-horizontal::before {
  content: "\e474";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-check::before {
  content: "\f00c";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-chess::before {
  content: "\f439";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-chestnut::before {
  content: "\e3f6";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-chopsticks::before {
  content: "\e3f7";
}

.fa-church::before {
  content: "\f51d";
}

.fa-circle::before {
  content: "\f111";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-circle-quarters::before {
  content: "\e3f8";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-city::before {
  content: "\f64f";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-four::before {
  content: "\f017";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-clock-eight::before {
  content: "\e345";
}

.fa-clock-eight-thirty::before {
  content: "\e346";
}

.fa-clock-eleven::before {
  content: "\e347";
}

.fa-clock-eleven-thirty::before {
  content: "\e348";
}

.fa-clock-five::before {
  content: "\e349";
}

.fa-clock-five-thirty::before {
  content: "\e34a";
}

.fa-clock-four-thirty::before {
  content: "\e34b";
}

.fa-clock-nine::before {
  content: "\e34c";
}

.fa-clock-nine-thirty::before {
  content: "\e34d";
}

.fa-clock-one::before {
  content: "\e34e";
}

.fa-clock-one-thirty::before {
  content: "\e34f";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-clock-seven::before {
  content: "\e350";
}

.fa-clock-seven-thirty::before {
  content: "\e351";
}

.fa-clock-six::before {
  content: "\e352";
}

.fa-clock-six-thirty::before {
  content: "\e353";
}

.fa-clock-ten::before {
  content: "\e354";
}

.fa-clock-ten-thirty::before {
  content: "\e355";
}

.fa-clock-three::before {
  content: "\e356";
}

.fa-clock-three-thirty::before {
  content: "\e357";
}

.fa-clock-twelve::before {
  content: "\e358";
}

.fa-clock-twelve-thirty::before {
  content: "\e359";
}

.fa-clock-two::before {
  content: "\e35a";
}

.fa-clock-two-thirty::before {
  content: "\e35b";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-cloud-check::before {
  content: "\e35c";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-cloud-exclamation::before {
  content: "\e491";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-cloud-minus::before {
  content: "\e35d";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-cloud-plus::before {
  content: "\e35e";
}

.fa-cloud-question::before {
  content: "\e492";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-cloud-xmark::before {
  content: "\e35f";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-clover::before {
  content: "\e139";
}

.fa-club::before {
  content: "\f327";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-code::before {
  content: "\f121";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-code-pull-request-closed::before {
  content: "\e3f9";
}

.fa-code-pull-request-draft::before {
  content: "\e3fa";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-coin-blank::before {
  content: "\e3fb";
}

.fa-coin-front::before {
  content: "\e3fc";
}

.fa-coin-vertical::before {
  content: "\e3fd";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-colon::before {
  content: ":";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-columns-3::before {
  content: "\e361";
}

.fa-comet::before {
  content: "\e003";
}

.fa-comma::before {
  content: ",";
}

.fa-command::before {
  content: "\e142";
}

.fa-comment::before {
  content: "\f075";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-comments::before {
  content: "\f086";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-compress::before {
  content: "\f066";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-corner::before {
  content: "\e3fe";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-crab::before {
  content: "\e3ff";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-crop::before {
  content: "\f125";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-cross::before {
  content: "\f654";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-crow::before {
  content: "\f520";
}

.fa-crown::before {
  content: "\f521";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-crystal-ball::before {
  content: "\e362";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-cucumber::before {
  content: "\e401";
}

.fa-cup-straw::before {
  content: "\e363";
}

.fa-cup-straw-swoosh::before {
  content: "\e364";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-cupcake::before {
  content: "\e402";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-custard::before {
  content: "\e403";
}

.fa-d::before {
  content: "D";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-dash::before {
  content: "\e404";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-desktop::before {
  content: "\f390";
}

.fa-desktop-alt::before {
  content: "\f390";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-diagram-cells::before {
  content: "\e475";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-diagram-next::before {
  content: "\e476";
}

.fa-diagram-predecessor::before {
  content: "\e477";
}

.fa-diagram-previous::before {
  content: "\e478";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-diagram-subtask::before {
  content: "\e479";
}

.fa-diagram-successor::before {
  content: "\e47a";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-diamond-exclamation::before {
  content: "\e405";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-dice::before {
  content: "\f522";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-display::before {
  content: "\e163";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-distribute-spacing-horizontal::before {
  content: "\e365";
}

.fa-distribute-spacing-vertical::before {
  content: "\e366";
}

.fa-ditto::before {
  content: '"';
}

.fa-divide::before {
  content: "\f529";
}

.fa-dna::before {
  content: "\f471";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-dollar-sign::before {
  content: "$";
}

.fa-dollar::before {
  content: "$";
}

.fa-usd::before {
  content: "$";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-donut::before {
  content: "\e406";
}

.fa-doughnut::before {
  content: "\e406";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-down-from-dotted-line::before {
  content: "\e407";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-down-to-dotted-line::before {
  content: "\e408";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-download::before {
  content: "\f019";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-drum::before {
  content: "\f569";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-e::before {
  content: "E";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-earth-america::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-earth-europe::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-earth-oceania::before {
  content: "\e47b";
}

.fa-globe-oceania::before {
  content: "\e47b";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-eject::before {
  content: "\f052";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-equals::before {
  content: "=";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-exclamation::before {
  content: "!";
}

.fa-expand::before {
  content: "\f065";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-eyes::before {
  content: "\e367";
}

.fa-f::before {
  content: "F";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-face-angry-horns::before {
  content: "\e368";
}

.fa-face-anguished::before {
  content: "\e369";
}

.fa-face-anxious-sweat::before {
  content: "\e36a";
}

.fa-face-astonished::before {
  content: "\e36b";
}

.fa-face-awesome::before {
  content: "\e409";
}

.fa-gave-dandy::before {
  content: "\e409";
}

.fa-face-beam-hand-over-mouth::before {
  content: "\e47c";
}

.fa-face-clouds::before {
  content: "\e47d";
}

.fa-face-confounded::before {
  content: "\e36c";
}

.fa-face-confused::before {
  content: "\e36d";
}

.fa-face-cowboy-hat::before {
  content: "\e36e";
}

.fa-face-diagonal-mouth::before {
  content: "\e47e";
}

.fa-face-disappointed::before {
  content: "\e36f";
}

.fa-face-disguise::before {
  content: "\e370";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-face-dotted::before {
  content: "\e47f";
}

.fa-face-downcast-sweat::before {
  content: "\e371";
}

.fa-face-drooling::before {
  content: "\e372";
}

.fa-face-exhaling::before {
  content: "\e480";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-face-expressionless::before {
  content: "\e373";
}

.fa-face-eyes-xmarks::before {
  content: "\e374";
}

.fa-face-fearful::before {
  content: "\e375";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-face-frown-slight::before {
  content: "\e376";
}

.fa-face-glasses::before {
  content: "\e377";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-face-hand-over-mouth::before {
  content: "\e378";
}

.fa-face-hand-peeking::before {
  content: "\e481";
}

.fa-face-hand-yawn::before {
  content: "\e379";
}

.fa-face-head-bandage::before {
  content: "\e37a";
}

.fa-face-holding-back-tears::before {
  content: "\e482";
}

.fa-face-hushed::before {
  content: "\e37b";
}

.fa-face-icicles::before {
  content: "\e37c";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-face-kiss-closed-eyes::before {
  content: "\e37d";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-face-lying::before {
  content: "\e37e";
}

.fa-face-mask::before {
  content: "\e37f";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-face-melting::before {
  content: "\e483";
}

.fa-face-monocle::before {
  content: "\e380";
}

.fa-face-nauseated::before {
  content: "\e381";
}

.fa-face-nose-steam::before {
  content: "\e382";
}

.fa-face-party::before {
  content: "\e383";
}

.fa-face-pensive::before {
  content: "\e384";
}

.fa-face-persevering::before {
  content: "\e385";
}

.fa-face-pleading::before {
  content: "\e386";
}

.fa-face-pouting::before {
  content: "\e387";
}

.fa-face-raised-eyebrow::before {
  content: "\e388";
}

.fa-face-relieved::before {
  content: "\e389";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-face-sad-sweat::before {
  content: "\e38a";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-face-saluting::before {
  content: "\e484";
}

.fa-face-scream::before {
  content: "\e38b";
}

.fa-face-shush::before {
  content: "\e38c";
}

.fa-face-sleeping::before {
  content: "\e38d";
}

.fa-face-sleepy::before {
  content: "\e38e";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-face-smile-halo::before {
  content: "\e38f";
}

.fa-face-smile-hearts::before {
  content: "\e390";
}

.fa-face-smile-horns::before {
  content: "\e391";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-face-smile-relaxed::before {
  content: "\e392";
}

.fa-face-smile-tear::before {
  content: "\e393";
}

.fa-face-smile-tongue::before {
  content: "\e394";
}

.fa-face-smile-upside-down::before {
  content: "\e395";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-face-smiling-hands::before {
  content: "\e396";
}

.fa-face-smirking::before {
  content: "\e397";
}

.fa-face-spiral-eyes::before {
  content: "\e485";
}

.fa-face-sunglasses::before {
  content: "\e398";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-face-swear::before {
  content: "\e399";
}

.fa-face-thermometer::before {
  content: "\e39a";
}

.fa-face-thinking::before {
  content: "\e39b";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-face-tissue::before {
  content: "\e39c";
}

.fa-face-tongue-money::before {
  content: "\e39d";
}

.fa-face-tongue-sweat::before {
  content: "\e39e";
}

.fa-face-unamused::before {
  content: "\e39f";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-face-vomit::before {
  content: "\e3a0";
}

.fa-face-weary::before {
  content: "\e3a1";
}

.fa-face-woozy::before {
  content: "\e3a2";
}

.fa-face-worried::before {
  content: "\e3a3";
}

.fa-face-zany::before {
  content: "\e3a4";
}

.fa-face-zipper::before {
  content: "\e3a5";
}

.fa-falafel::before {
  content: "\e40a";
}

.fa-family::before {
  content: "\e300";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-fan::before {
  content: "\f863";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-fence::before {
  content: "\e303";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-file-circle-info::before {
  content: "\e493";
}

.fa-file-circle-plus::before {
  content: "\e494";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-file-lock::before {
  content: "\e3a6";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-file-slash::before {
  content: "\e3a7";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-files::before {
  content: "\e178";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-fill::before {
  content: "\f575";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-film::before {
  content: "\f008";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-films::before {
  content: "\e17a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-fish::before {
  content: "\f578";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-fishing-rod::before {
  content: "\e3a8";
}

.fa-flag::before {
  content: "\f024";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-flatbread::before {
  content: "\e40b";
}

.fa-flatbread-stuffed::before {
  content: "\e40c";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-flying-disc::before {
  content: "\e3a9";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-folder-blank::before {
  content: "\e185";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-folders::before {
  content: "\f660";
}

.fa-fondue-pot::before {
  content: "\e40d";
}

.fa-font::before {
  content: "\f031";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-football::before {
  content: "\f44e";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-fort::before {
  content: "\e486";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-frame::before {
  content: "\e495";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-function::before {
  content: "\f661";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-g::before {
  content: "G";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-gallery-thumbnails::before {
  content: "\e3aa";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-gamepad-modern::before {
  content: "\f8bc";
}

.fa-gamepad-alt::before {
  content: "\f8bc";
}

.fa-garage::before {
  content: "\e009";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-garlic::before {
  content: "\e40e";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-gauge::before {
  content: "\f624";
}

.fa-dashboard::before {
  content: "\f624";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-gauge-circle-bolt::before {
  content: "\e496";
}

.fa-gauge-circle-minus::before {
  content: "\e497";
}

.fa-gauge-circle-plus::before {
  content: "\e498";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-gauge-simple::before {
  content: "\f629";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-tachometer-fast::before {
  content: "\f62a";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-gif::before {
  content: "\e190";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-glass::before {
  content: "\f804";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-goal-net::before {
  content: "\e3ab";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-golf-flag-hole::before {
  content: "\e3ac";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-grate::before {
  content: "\e193";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-greater-than::before {
  content: ">";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-grid-dividers::before {
  content: "\e3ad";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-grip-dots::before {
  content: "\e410";
}

.fa-grip-dots-vertical::before {
  content: "\e411";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-h::before {
  content: "H";
}

.fa-h1::before {
  content: "\f313";
}

.fa-h2::before {
  content: "\f314";
}

.fa-h3::before {
  content: "\f315";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-h5::before {
  content: "\e412";
}

.fa-h6::before {
  content: "\e413";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-hammer-crash::before {
  content: "\e414";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-hand::before {
  content: "\f256";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-hand-back-fist::before {
  content: "\f255";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-hashtag::before {
  content: "#";
}

.fa-hashtag-lock::before {
  content: "\e415";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-headset::before {
  content: "\f590";
}

.fa-heart::before {
  content: "\f004";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-hexagon-check::before {
  content: "\e416";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-hexagon-exclamation::before {
  content: "\e417";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-hockey-stick-puck::before {
  content: "\e3ae";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-honey-pot::before {
  content: "\e418";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-hose::before {
  content: "\e419";
}

.fa-hose-reel::before {
  content: "\e41a";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-alt::before {
  content: "\f0f8";
}

.fa-hospital-wide::before {
  content: "\f0f8";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-2::before {
  content: "\f254";
}

.fa-hourglass-half::before {
  content: "\f254";
}

.fa-hourglass-clock::before {
  content: "\e41b";
}

.fa-hourglass-empty::before {
  content: "\f252";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-house::before {
  content: "\f015";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-alt::before {
  content: "\f015";
}

.fa-home-lg-alt::before {
  content: "\f015";
}

.fa-house-blank::before {
  content: "\e487";
}

.fa-home-blank::before {
  content: "\e487";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-house-chimney::before {
  content: "\e3af";
}

.fa-home-lg::before {
  content: "\e3af";
}

.fa-house-chimney-blank::before {
  content: "\e3b0";
}

.fa-house-chimney-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-house-chimney-heart::before {
  content: "\e1b2";
}

.fa-house-chimney-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-house-chimney-user::before {
  content: "\e065";
}

.fa-house-chimney-window::before {
  content: "\e00d";
}

.fa-house-crack::before {
  content: "\e3b1";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-house-heart::before {
  content: "\f4c9";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-house-medical::before {
  content: "\e3b2";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-return::before {
  content: "\e011";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-house-user::before {
  content: "\e1b0";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-house-window::before {
  content: "\e3b3";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-hundred-points::before {
  content: "\e41c";
}

.fa-100::before {
  content: "\e41c";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-hyphen::before {
  content: "-";
}

.fa-i::before {
  content: "I";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-image::before {
  content: "\f03e";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-images::before {
  content: "\f302";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-industry::before {
  content: "\f275";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-info::before {
  content: "\f129";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-integral::before {
  content: "\f667";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-italic::before {
  content: "\f033";
}

.fa-j::before {
  content: "J";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-joint::before {
  content: "\f595";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-k::before {
  content: "K";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-key::before {
  content: "\f084";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-key-skeleton-left-right::before {
  content: "\e3b4";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-l::before {
  content: "L";
}

.fa-lacrosse-stick::before {
  content: "\e3b5";
}

.fa-lacrosse-stick-ball::before {
  content: "\e3b6";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-leafy-green::before {
  content: "\e41d";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-left-long-to-line::before {
  content: "\e41e";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-less-than::before {
  content: "<";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-light-emergency::before {
  content: "\e41f";
}

.fa-light-emergency-on::before {
  content: "\e420";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-lips::before {
  content: "\f600";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-lobster::before {
  content: "\e421";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location::before {
  content: "\f601";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-slash::before {
  content: "\f603";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-location-pin::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-location-pin-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-lock::before {
  content: "\f023";
}

.fa-lock-a::before {
  content: "\e422";
}

.fa-lock-hashtag::before {
  content: "\e423";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-lollipop::before {
  content: "\e424";
}

.fa-lollypop::before {
  content: "\e424";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-m::before {
  content: "M";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-map::before {
  content: "\f279";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-mars::before {
  content: "\f222";
}

.fa-mars-and-venus::before {
  content: "\f224";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-mask-snorkel::before {
  content: "\e3b7";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-meat::before {
  content: "\f814";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-melon::before {
  content: "\e310";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-memo-circle-info::before {
  content: "\e49a";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-memory::before {
  content: "\f538";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-message-bot::before {
  content: "\e3b8";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-moon::before {
  content: "\f186";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-moped::before {
  content: "\e3b9";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-mug::before {
  content: "\f874";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-mushroom::before {
  content: "\e425";
}

.fa-music::before {
  content: "\f001";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-n::before {
  content: "N";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-nesting-dolls::before {
  content: "\e3ba";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-notes::before {
  content: "\e202";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-o::before {
  content: "O";
}

.fa-object-exclude::before {
  content: "\e49c";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-object-intersect::before {
  content: "\e49d";
}

.fa-object-subtract::before {
  content: "\e49e";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-object-union::before {
  content: "\e49f";
}

.fa-objects-align-bottom::before {
  content: "\e3bb";
}

.fa-objects-align-center-horizontal::before {
  content: "\e3bc";
}

.fa-objects-align-center-vertical::before {
  content: "\e3bd";
}

.fa-objects-align-left::before {
  content: "\e3be";
}

.fa-objects-align-right::before {
  content: "\e3bf";
}

.fa-objects-align-top::before {
  content: "\e3c0";
}

.fa-objects-column::before {
  content: "\e3c1";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-octagon-check::before {
  content: "\e426";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-olive::before {
  content: "\e316";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-om::before {
  content: "\f679";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-onion::before {
  content: "\e427";
}

.fa-option::before {
  content: "\e318";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-otter::before {
  content: "\f700";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-overline::before {
  content: "\f876";
}

.fa-p::before {
  content: "P";
}

.fa-page::before {
  content: "\e428";
}

.fa-page-caret-down::before {
  content: "\e429";
}

.fa-file-caret-down::before {
  content: "\e429";
}

.fa-page-caret-up::before {
  content: "\e42a";
}

.fa-file-caret-up::before {
  content: "\e42a";
}

.fa-pager::before {
  content: "\f815";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-paintbrush::before {
  content: "\f1fc";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-paintbrush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paintbrush-alt::before {
  content: "\f5a9";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-pan-food::before {
  content: "\e42b";
}

.fa-pan-frying::before {
  content: "\e42c";
}

.fa-pancakes::before {
  content: "\e42d";
}

.fa-panel-ews::before {
  content: "\e42e";
}

.fa-panel-fire::before {
  content: "\e42f";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-paperclip-vertical::before {
  content: "\e3c2";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-peanut::before {
  content: "\e430";
}

.fa-peanuts::before {
  content: "\e431";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-pen::before {
  content: "\f304";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-pen-nib-slash::before {
  content: "\e4a1";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-pencil-alt::before {
  content: "\f303";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-people::before {
  content: "\e216";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-pepper::before {
  content: "\e432";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-percent::before {
  content: "%";
}

.fa-percentage::before {
  content: "%";
}

.fa-period::before {
  content: ".";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-person-to-door::before {
  content: "\e433";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-phone::before {
  content: "\f095";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-phone-intercom::before {
  content: "\e434";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-pickleball::before {
  content: "\e435";
}

.fa-pie::before {
  content: "\f705";
}

.fa-pig::before {
  content: "\f706";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-pills::before {
  content: "\f484";
}

.fa-pinata::before {
  content: "\e3c3";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-pipe::before {
  content: "|";
}

.fa-pipe-circle-check::before {
  content: "\e436";
}

.fa-pipe-collar::before {
  content: "\e437";
}

.fa-pipe-section::before {
  content: "\e438";
}

.fa-pipe-smoking::before {
  content: "\e3c4";
}

.fa-pipe-valve::before {
  content: "\e439";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-plane::before {
  content: "\f072";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-plate-utensils::before {
  content: "\e43b";
}

.fa-play::before {
  content: "\f04b";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-plus::before {
  content: "+";
}

.fa-add::before {
  content: "+";
}

.fa-plus-minus::before {
  content: "\e43c";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-podium::before {
  content: "\f680";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-pompebled::before {
  content: "\e43d";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-pool-8-ball::before {
  content: "\e3c5";
}

.fa-poop::before {
  content: "\f619";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-popsicle::before {
  content: "\e43e";
}

.fa-pot-food::before {
  content: "\e43f";
}

.fa-potato::before {
  content: "\e440";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-pretzel::before {
  content: "\e441";
}

.fa-print::before {
  content: "\f02f";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-pump::before {
  content: "\e442";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-puzzle::before {
  content: "\e443";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-q::before {
  content: "Q";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-question::before {
  content: "?";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-r::before {
  content: "R";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-radar::before {
  content: "\e024";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-rectangle-history::before {
  content: "\e4a2";
}

.fa-rectangle-history-circle-plus::before {
  content: "\e4a3";
}

.fa-rectangle-history-circle-user::before {
  content: "\e4a4";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-reel::before {
  content: "\e238";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-right-long-to-line::before {
  content: "\e444";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-road::before {
  content: "\f018";
}

.fa-robot::before {
  content: "\f544";
}

.fa-robot-astromech::before {
  content: "\e2d2";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-rugby-ball::before {
  content: "\e3c6";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-s::before {
  content: "S";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-sailboat::before {
  content: "\e445";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-salt-shaker::before {
  content: "\e446";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-scanner::before {
  content: "\f8f3";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-school::before {
  content: "\f549";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-seal::before {
  content: "\e241";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-section::before {
  content: "\e447";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-semicolon::before {
  content: ";";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-server::before {
  content: "\f233";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-arrows-turn-right::before {
  content: "\f367";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-shirt::before {
  content: "\f553";
}

.fa-t-shirt::before {
  content: "\f553";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-shirt-long-sleeve::before {
  content: "\e3c7";
}

.fa-shirt-running::before {
  content: "\e3c8";
}

.fa-shirt-tank-top::before {
  content: "\e3c9";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-shop-lock::before {
  content: "\e4a5";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-shrimp::before {
  content: "\e448";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-shutters::before {
  content: "\e449";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-signature-lock::before {
  content: "\e3ca";
}

.fa-signature-slash::before {
  content: "\e3cb";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-ski-boot::before {
  content: "\e3cc";
}

.fa-ski-boot-ski::before {
  content: "\e3cd";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-slash::before {
  content: "\f715";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-slash-forward::before {
  content: "/";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-slider::before {
  content: "\e252";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-slot-machine::before {
  content: "\e3ce";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-snake::before {
  content: "\f716";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-socks::before {
  content: "\f696";
}

.fa-soft-serve::before {
  content: "\e400";
}

.fa-creemee::before {
  content: "\e400";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-spider::before {
  content: "\f717";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-split::before {
  content: "\e254";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-sportsball::before {
  content: "\e44b";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-sprinkler-ceiling::before {
  content: "\e44c";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-square-a-lock::before {
  content: "\e44d";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-square-kanban::before {
  content: "\e488";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-square-list::before {
  content: "\e489";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-square-quarters::before {
  content: "\e44e";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-square-ring::before {
  content: "\e44f";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-squid::before {
  content: "\e450";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-star::before {
  content: "\f005";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-stars::before {
  content: "\f762";
}

.fa-starship::before {
  content: "\e039";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-steak::before {
  content: "\f824";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-store::before {
  content: "\f54e";
}

.fa-store-lock::before {
  content: "\e4a6";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-sun::before {
  content: "\f185";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-sushi::before {
  content: "\e48a";
}

.fa-nigiri::before {
  content: "\e48a";
}

.fa-sushi-roll::before {
  content: "\e48b";
}

.fa-maki-roll::before {
  content: "\e48b";
}

.fa-makizushi::before {
  content: "\e48b";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-t::before {
  content: "T";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-taco::before {
  content: "\f826";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-tamale::before {
  content: "\e451";
}

.fa-tank-water::before {
  content: "\e452";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-teddy-bear::before {
  content: "\e3cf";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-text::before {
  content: "\f893";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-tilde::before {
  content: "~";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-tire::before {
  content: "\f631";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-train::before {
  content: "\f238";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-train-track::before {
  content: "\e453";
}

.fa-train-tram::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-train-tunnel::before {
  content: "\e454";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-transgender::before {
  content: "\f225";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-trees::before {
  content: "\f724";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-truck-bolt::before {
  content: "\e3d0";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-turn-down-right::before {
  content: "\e455";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-u::before {
  content: "U";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-uniform-martial-arts::before {
  content: "\e3d1";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-up-from-dotted-line::before {
  content: "\e456";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-up-to-dotted-line::before {
  content: "\e457";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-upload::before {
  content: "\f093";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-user::before {
  content: "\f007";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-user-chef::before {
  content: "\e3d2";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-user-doctor-hair::before {
  content: "\e458";
}

.fa-user-doctor-hair-long::before {
  content: "\e459";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-user-hair::before {
  content: "\e45a";
}

.fa-user-hair-buns::before {
  content: "\e3d3";
}

.fa-user-hair-long::before {
  content: "\e45b";
}

.fa-user-hair-mullet::before {
  content: "\e45c";
}

.fa-business-front::before {
  content: "\e45c";
}

.fa-party-back::before {
  content: "\e45c";
}

.fa-trian-balbot::before {
  content: "\e45c";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-user-nurse-hair::before {
  content: "\e45d";
}

.fa-user-nurse-hair-long::before {
  content: "\e45e";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-user-robot-xmarks::before {
  content: "\e4a7";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-user-tie-hair::before {
  content: "\e45f";
}

.fa-user-tie-hair-long::before {
  content: "\e460";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-user-vneck::before {
  content: "\e461";
}

.fa-user-vneck-hair::before {
  content: "\e462";
}

.fa-user-vneck-hair-long::before {
  content: "\e463";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-utensils-slash::before {
  content: "\e464";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-v::before {
  content: "V";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-vent-damper::before {
  content: "\e465";
}

.fa-venus::before {
  content: "\f221";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-vest::before {
  content: "\e085";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-vial::before {
  content: "\f492";
}

.fa-vials::before {
  content: "\f493";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-virus::before {
  content: "\e074";
}

.fa-virus-covid::before {
  content: "\e4a8";
}

.fa-virus-covid-slash::before {
  content: "\e4a9";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-volleyball::before {
  content: "\f45f";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-w::before {
  content: "W";
}

.fa-waffle::before {
  content: "\e466";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-walker::before {
  content: "\f831";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-water::before {
  content: "\f773";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-waveform-path::before {
  content: "\f8f2";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-window::before {
  content: "\f40e";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-x::before {
  content: "X";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-y::before {
  content: "Y";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-z::before {
  content: "Z";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../assets/fontawesome/fa-light-300.woff2") format("woff2"), url("../assets/fontawesome/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/*!
 * Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
:root, :host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../assets/fontawesome/fa-solid-900.woff2") format("woff2"), url("../assets/fontawesome/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

.workspace-layout-content {
  display: flex;
  flex-direction: column;
}

.workspace-layout-content__body {
  flex-grow: 1;
  overflow: auto;
  padding: 0.75rem;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
::ng-deep .accordion:hover {
  overflow: hidden !important;
}
::ng-deep .accordion .accordion-button {
  border: none;
  width: 100%;
  text-align: left;
  height: 50px;
  background: #f7f8f9;
}
::ng-deep .accordion .accordion-item {
  border-radius: 0;
  border: 1px solid #dadfe3 !important;
  margin-bottom: 0.5rem !important;
  background-color: #f7f8f9;
}
::ng-deep .accordion .accordion-item .accordion-header {
  margin-bottom: 0 !important;
  border-bottom: none !important;
  background-color: #f7f8f9;
  width: 100%;
}
::ng-deep .accordion .accordion-item .accordion-header .btn {
  text-decoration: none;
  width: 100%;
  padding-left: 0 !important;
}
::ng-deep .accordion .accordion-item .accordion-header .btn:hover {
  text-decoration: none !important;
}
::ng-deep .accordion .accordion-item .accordion-header .btn:focus {
  box-shadow: none !important;
}
::ng-deep .accordion .accordion-item .accordion-body {
  padding: 10px !important;
  border-top: 1px solid #dadfe3;
  background-color: #ffffff;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.assignment {
  border-radius: 1.5rem;
  overflow: hidden;
  border: 1px solid #dadfe3;
  box-shadow: 0 1px 5px 0 #e8ebee;
  position: relative;
  background-color: #ffffff;
}
.assignment.cdk-drop-list-dragging > .assignment__overlay-element-add {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center;
  align-content: center;
  border: 3px dashed #dadfe3;
  border-radius: 20px;
}
.assignment.cdk-drop-list-dragging > .assignment__overlay-element-add > .assignment__plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #dadfe3;
}
.assignment.cdk-drop-list-dragging > .cdk-drag-placeholder {
  display: none;
}

.assignment--show-selection-animation {
  animation: shake 250ms linear;
}

.assignment--latest {
  box-shadow: 0px 0px 3px 5px rgba(66, 254, 109, 0.4901960784);
}

.assignment--tall {
  min-height: 366px;
}

.assignment--locked .assignment__lock-overlay {
  display: block;
  background-color: rgba(81, 95, 109, 0.0784313725);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.assignment--locked .assignment__lock-overlay-btn-area {
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: absolute;
  right: 42px;
  top: 16px;
  opacity: 0.5;
}
.assignment--locked .assignment__lock-overlay-btn-area:hover {
  opacity: 0.2;
}

.assignment__primary-row {
  display: flex;
  flex-wrap: wrap;
}

.assignment__primary-col {
  display: block;
  width: 100%;
  padding: 0.35rem 0;
}

.assignment__overlay-element-add {
  display: none;
}

.assigment__elements-container {
  display: flex;
}

.assigment__elements-container--one-column {
  flex-direction: column;
}
.assigment__elements-container--one-column .assignment__elements-col {
  width: 100%;
  border-left: none;
}

.assignment__elements-col {
  width: 50%;
  border-left: 1px solid #dadfe3;
}
.assignment__elements-col:first-child {
  border-left: none;
}

.assignment__lock-overlay {
  display: none;
}

.assignment-header {
  color: #515f6d;
  padding: 0.75rem 1.5rem 0.35rem;
  min-height: 50px;
}
.assignment-header:hover .assignment-header__move-icon {
  opacity: 1;
}

.assignment-header--background-dark {
  background-color: #f7f8f9;
}

.assignment-header--selected .assignment-header__check-icon {
  opacity: 1;
}

.assignment-header--show-unchecked-btn .assignment-header__check-icon {
  opacity: 1;
}

.assignment-header--is-selectable .assignment-header__check-icon {
  transition: opacity 0s;
}
.assignment-header--is-selectable:hover .assignment-header__check-icon {
  opacity: 1;
}

.assignment-header--is-clickable .assignment-header__title {
  cursor: pointer;
}

.assignment-header__title-row {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assignment-header__title {
  color: #ff4a75;
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  color: #515f6d;
  width: 90%;
  height: 100%;
  cursor: pointer;
  line-height: 1;
  word-wrap: break-word;
}

.assignment-header__check-icon {
  opacity: 0;
}

.assignment-header__icons {
  display: flex;
  align-items: center;
}

.assignment-header__link-icon {
  font-size: 1.125rem;
  cursor: pointer;
}

.assignment-header__link-icon--active {
  color: #515f6d;
}

.assignment-header__move-icon {
  font-size: 1rem;
  cursor: move;
  opacity: 0;
}

.assignment-header__menu-icon {
  font-size: 1rem;
  cursor: pointer;
}

.assignment-header__locker-icon {
  font-size: 1rem;
  cursor: pointer;
}
.assignment-header__locker-icon.fa-lock-alt {
  color: #ff4a75;
}
.assignment-header__locker-icon.fa-unlock-alt {
  color: #1abb9a;
}

.assignment-header__expand-icon {
  font-size: 1rem;
  cursor: pointer;
}

.assignment-header__color-indicators {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: -3px;
  left: 20px;
}

.assignment-header__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.assignment-header__row--widgets {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  overflow-x: hidden;
  margin-left: -8px;
}

.assignment-header__row--tags {
  padding-bottom: 0.75rem;
}

.assignment-header__progress {
  display: block;
  padding-bottom: 0.75rem;
}

.assignment-header__carousel-field {
  position: relative;
  width: 100%;
  padding: 0 3rem 0 0.75rem;
}

.assignment-header__tag-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.75rem;
  flex-wrap: wrap;
}

.assignment-header__tag {
  display: inline-block;
  margin-right: 0.35rem;
  margin-top: 0.35rem;
}

.assignment-inner-card {
  border: 1px solid #dadfe3;
  box-shadow: none;
  background-color: #f7f8f9;
  border-radius: 1.5rem;
  position: relative;
  padding: 1rem;
}

.assignment-primary-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 42px;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 3rem;
  box-shadow: none;
}
.assignment-primary-tag:hover:after {
  content: "\f044";
  font-size: 10px;
  color: #515f6d;
  position: absolute;
  top: 1px;
  right: 1px;
}

.assignment-primary-tag--not-available {
  background-color: #f7f8f9;
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--not-available:hover {
  background-color: #f7f8f9;
}
.assignment-primary-tag--not-available:hover.assignment-primary-tag--readonly {
  background-color: #f7f8f9;
}

.assignment-primary-tag--color-49546a {
  background-color: rgba(73, 84, 106, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-49546a:hover {
  background-color: rgba(73, 84, 106, 0.2);
}
.assignment-primary-tag--color-49546a:hover.assignment-primary-tag--readonly {
  background-color: rgba(73, 84, 106, 0.2);
}

.assignment-primary-tag--color-ff4a75 {
  background-color: rgba(255, 74, 117, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-ff4a75:hover {
  background-color: rgba(255, 74, 117, 0.2);
}
.assignment-primary-tag--color-ff4a75:hover.assignment-primary-tag--readonly {
  background-color: rgba(255, 74, 117, 0.2);
}

.assignment-primary-tag--color-6f41c1 {
  background-color: rgba(111, 65, 193, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-6f41c1:hover {
  background-color: rgba(111, 65, 193, 0.2);
}
.assignment-primary-tag--color-6f41c1:hover.assignment-primary-tag--readonly {
  background-color: rgba(111, 65, 193, 0.2);
}

.assignment-primary-tag--color-007bff {
  background-color: rgba(0, 123, 255, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-007bff:hover {
  background-color: rgba(0, 123, 255, 0.2);
}
.assignment-primary-tag--color-007bff:hover.assignment-primary-tag--readonly {
  background-color: rgba(0, 123, 255, 0.2);
}

.assignment-primary-tag--color-eb842d {
  background-color: rgba(235, 132, 45, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-eb842d:hover {
  background-color: rgba(235, 132, 45, 0.2);
}
.assignment-primary-tag--color-eb842d:hover.assignment-primary-tag--readonly {
  background-color: rgba(235, 132, 45, 0.2);
}

.assignment-primary-tag--color-39b49b {
  background-color: rgba(57, 180, 155, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-39b49b:hover {
  background-color: rgba(57, 180, 155, 0.2);
}
.assignment-primary-tag--color-39b49b:hover.assignment-primary-tag--readonly {
  background-color: rgba(57, 180, 155, 0.2);
}

.assignment-primary-tag--color-708090 {
  background-color: rgba(112, 128, 144, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-708090:hover {
  background-color: rgba(112, 128, 144, 0.2);
}
.assignment-primary-tag--color-708090:hover.assignment-primary-tag--readonly {
  background-color: rgba(112, 128, 144, 0.2);
}

.assignment-primary-tag--color-c62f3e {
  background-color: rgba(198, 47, 62, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-c62f3e:hover {
  background-color: rgba(198, 47, 62, 0.2);
}
.assignment-primary-tag--color-c62f3e:hover.assignment-primary-tag--readonly {
  background-color: rgba(198, 47, 62, 0.2);
}

.assignment-primary-tag--color-856088 {
  background-color: rgba(133, 96, 136, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-856088:hover {
  background-color: rgba(133, 96, 136, 0.2);
}
.assignment-primary-tag--color-856088:hover.assignment-primary-tag--readonly {
  background-color: rgba(133, 96, 136, 0.2);
}

.assignment-primary-tag--color-2072af {
  background-color: rgba(32, 114, 175, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-2072af:hover {
  background-color: rgba(32, 114, 175, 0.2);
}
.assignment-primary-tag--color-2072af:hover.assignment-primary-tag--readonly {
  background-color: rgba(32, 114, 175, 0.2);
}

.assignment-primary-tag--color-de7c67 {
  background-color: rgba(222, 124, 103, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-de7c67:hover {
  background-color: rgba(222, 124, 103, 0.2);
}
.assignment-primary-tag--color-de7c67:hover.assignment-primary-tag--readonly {
  background-color: rgba(222, 124, 103, 0.2);
}

.assignment-primary-tag--color-70a951 {
  background-color: rgba(112, 169, 81, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-70a951:hover {
  background-color: rgba(112, 169, 81, 0.2);
}
.assignment-primary-tag--color-70a951:hover.assignment-primary-tag--readonly {
  background-color: rgba(112, 169, 81, 0.2);
}

.assignment-primary-tag--color-0f1f40 {
  background-color: rgba(15, 31, 64, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-0f1f40:hover {
  background-color: rgba(15, 31, 64, 0.2);
}
.assignment-primary-tag--color-0f1f40:hover.assignment-primary-tag--readonly {
  background-color: rgba(15, 31, 64, 0.2);
}

.assignment-primary-tag--color-d471ac {
  background-color: rgba(212, 113, 172, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-d471ac:hover {
  background-color: rgba(212, 113, 172, 0.2);
}
.assignment-primary-tag--color-d471ac:hover.assignment-primary-tag--readonly {
  background-color: rgba(212, 113, 172, 0.2);
}

.assignment-primary-tag--color-9e71bd {
  background-color: rgba(158, 113, 189, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-9e71bd:hover {
  background-color: rgba(158, 113, 189, 0.2);
}
.assignment-primary-tag--color-9e71bd:hover.assignment-primary-tag--readonly {
  background-color: rgba(158, 113, 189, 0.2);
}

.assignment-primary-tag--color-51acca {
  background-color: rgba(81, 172, 202, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-51acca:hover {
  background-color: rgba(81, 172, 202, 0.2);
}
.assignment-primary-tag--color-51acca:hover.assignment-primary-tag--readonly {
  background-color: rgba(81, 172, 202, 0.2);
}

.assignment-primary-tag--color-e8b717 {
  background-color: rgba(232, 183, 23, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-e8b717:hover {
  background-color: rgba(232, 183, 23, 0.2);
}
.assignment-primary-tag--color-e8b717:hover.assignment-primary-tag--readonly {
  background-color: rgba(232, 183, 23, 0.2);
}

.assignment-primary-tag--color-79d18a {
  background-color: rgba(121, 209, 138, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-79d18a:hover {
  background-color: rgba(121, 209, 138, 0.2);
}
.assignment-primary-tag--color-79d18a:hover.assignment-primary-tag--readonly {
  background-color: rgba(121, 209, 138, 0.2);
}

.assignment-primary-tag--color-848484 {
  background-color: rgba(132, 132, 132, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-848484:hover {
  background-color: rgba(132, 132, 132, 0.2);
}
.assignment-primary-tag--color-848484:hover.assignment-primary-tag--readonly {
  background-color: rgba(132, 132, 132, 0.2);
}

.assignment-primary-tag--color-c13ea5 {
  background-color: rgba(193, 62, 165, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-c13ea5:hover {
  background-color: rgba(193, 62, 165, 0.2);
}
.assignment-primary-tag--color-c13ea5:hover.assignment-primary-tag--readonly {
  background-color: rgba(193, 62, 165, 0.2);
}

.assignment-primary-tag--color-c392e0 {
  background-color: rgba(195, 146, 224, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-c392e0:hover {
  background-color: rgba(195, 146, 224, 0.2);
}
.assignment-primary-tag--color-c392e0:hover.assignment-primary-tag--readonly {
  background-color: rgba(195, 146, 224, 0.2);
}

.assignment-primary-tag--color-67c9e3 {
  background-color: rgba(103, 201, 227, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-67c9e3:hover {
  background-color: rgba(103, 201, 227, 0.2);
}
.assignment-primary-tag--color-67c9e3:hover.assignment-primary-tag--readonly {
  background-color: rgba(103, 201, 227, 0.2);
}

.assignment-primary-tag--color-f1dc4d {
  background-color: rgba(241, 220, 77, 0.2);
  border: none;
  color: #515f6d;
}
.assignment-primary-tag--color-f1dc4d:hover {
  background-color: rgba(241, 220, 77, 0.2);
}
.assignment-primary-tag--color-f1dc4d:hover.assignment-primary-tag--readonly {
  background-color: rgba(241, 220, 77, 0.2);
}

.assignment-primary-tag--sm {
  height: 28px;
  width: 120px;
}
.assignment-primary-tag--sm .assignment-primary-tag__icon {
  width: 18px;
  height: 18px;
}
.assignment-primary-tag--sm .assignment-primary-tag__value {
  font-size: 0.625rem;
}

.assignment-primary-tag--readonly {
  cursor: initial;
}
.assignment-primary-tag--readonly:hover:after {
  content: none;
}

.assignment-primary-tag__icon {
  font-size: 1.125rem;
  color: #515f6d;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: transparent;
  border-radius: 6px;
}

.assignment-primary-tag__value {
  font-size: 0.875rem;
  flex-grow: 1;
  display: flex;
  justify-content: start;
}

.assignment-section-title {
  font-size: 10px;
  text-transform: none;
  color: rgba(81, 95, 109, 0.6);
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.assignment-table__header {
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  color: #6d6d6d;
}

.assignment-table__table thead th {
  font-size: 1rem;
  line-height: 1.2;
  color: #6d6d6d;
  background-color: #6f42c1;
  padding: 0.75rem;
  border: 2px solid #f7f8f9;
}
.assignment-table__table tbody tr {
  background-color: #f7f8f9;
}
.assignment-table__table tbody tr:hover {
  background-color: #e6e8eb;
}
.assignment-table__table tbody td {
  font-size: 1rem;
  line-height: 1.2;
  color: #6d6d6d;
  padding: 0;
  position: relative;
  border: none;
  vertical-align: middle;
}

.assignment-table__layout-icon {
  margin-right: 0.35rem;
}

.assignment-table__col-date {
  min-width: 150px;
}

.assignment-table__col-lock {
  min-width: 60px;
}
th.assignment-table__col-lock {
  text-align: center;
}

td.assignment-table__col-lock .assignment-table__cell-content {
  display: flex;
  justify-content: center;
}

.assignment-table__col-title {
  min-width: 250px;
}

.assignment-table__col-progress {
  min-width: 200px;
}

.assignment-table__col-stamp,
.assignment-table__col-primary-tag {
  min-width: 150px;
}
th.assignment-table__col-stamp, .assignment-table__col-primary-tag {
  text-align: center;
}

td.assignment-table__col-stamp .assignment-table__cell-content, .assignment-table__col-primary-tag .assignment-table__cell-content {
  display: flex;
  justify-content: center;
}

.assignment-table__col-assets {
  min-width: 150px;
}

.assignment-table__col-elements {
  min-width: 150px;
}

.assignment-table__col-notes {
  min-width: 180px;
}

.assignment-table__col-stamp {
  min-width: 150px;
}

.assignment-table__col-cal-rate-field {
  min-width: 250px;
}

.assignment-table__col-cal-units-field {
  min-width: 80px;
}

.assignment-table__col-cal-field {
  min-width: auto;
  white-space: nowrap;
}
th.assignment-table__col-cal-field {
  text-align: right;
}

td.assignment-table__col-cal-field .assignment-table__cell-content {
  display: flex;
  justify-content: flex-end;
}

.assignment-table__col-cal-formula {
  min-width: auto;
  white-space: nowrap;
}
th.assignment-table__col-cal-formula {
  text-align: right;
}

td.assignment-table__col-cal-formula .assignment-table__cell-content {
  display: flex;
  justify-content: flex-end;
}

th.assignment-table__col-status {
  text-align: center;
}

td.assignment-table__col-status .assignment-table__cell-content {
  display: flex;
  justify-content: center;
}

th.assignment-table__col-actions {
  text-align: center;
}

td.assignment-table__col-actions .assignment-table__cell-content {
  display: flex;
  justify-content: center;
}

.assignment-table__col-checklist {
  min-width: 220px;
}

.assignment-table__col-timesheet {
  min-width: auto;
}

.assignment-table__col-timesheet-name {
  min-width: auto;
}

.assignment-table__col-timesheet-in {
  min-width: auto;
}

.assignment-table__col-timesheet-out {
  min-width: auto;
}

.assignment-table__col-timesheet-text {
  min-width: auto;
}

.assignment-table__col-diary {
  min-width: 400px;
}

.assignment-table__cell-content {
  position: relative;
  min-height: 30px;
  padding: 1px 0.75rem;
}

.assignment-table__cell-content--actionable {
  border: 1px solid transparent;
}
.assignment-table__cell-content--actionable:hover {
  border-color: #49546a;
}

.assignment-table__cell-content--clickable {
  cursor: pointer;
}

.assignment-table__subtotal {
  color: #6d6d6d;
  background-color: #6f42c1;
  display: inline-block;
  width: auto;
}

.assignment-table__field-edit-icon {
  color: #515f6d;
  font-size: 10px;
  padding: 0.35rem;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 1px;
}

.assignment-table__field-clickable-edit-icon {
  color: #515f6d;
  font-size: 10px;
  padding: 0.35rem;
  display: inline-block;
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
  border-radius: 0.35rem;
}
.assignment-table__field-clickable-edit-icon:hover {
  color: #515f6d;
  background-color: #d4d7dc;
}

.assignment-table__menu-icon,
.assignment-table__lock-icon {
  cursor: pointer;
  font-size: 1rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
}
.assignment-table__menu-icon:hover,
.assignment-table__lock-icon:hover {
  background-color: #d4d7dc;
}
.assignment-table__menu-icon.fa-lock-alt,
.assignment-table__lock-icon.fa-lock-alt {
  color: #ff4a75;
}
.assignment-table__menu-icon.fa-unlock-alt,
.assignment-table__lock-icon.fa-unlock-alt {
  color: #1abb9a;
}

.assignment-table__brick-container {
  display: flex;
}

.assignment-table__assets {
  display: flex;
}

.assignment-table__element {
  padding: 0.35rem 0;
  display: block;
  cursor: pointer;
}

.assignment-table__units {
  width: 50px;
  text-align: right;
}

.inline-edit-input {
  transition: padding-bottom 250ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.inline-edit-input__input-wrapper {
  position: relative;
}

.inline-edit-input__input, .inline-edit-input__spacing {
  border: 1px solid transparent;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 4px;
  min-width: 100px;
}

.inline-edit-input__spacing {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: auto;
}

.inline-edit-input__input {
  width: 100%;
}

.inline-edit-input__input--text {
  border-color: transparent;
  background-color: transparent;
}
.inline-edit-input__input--text:hover {
  cursor: text;
  border-color: #6f42c1;
  background-color: #6f42c1;
}

.inline-edit-input__input--input {
  min-width: 130px;
}
.inline-edit-input__input--input:focus {
  border-color: #80bdff;
}

.form-control.inline-edit-input__input--with-min-width {
  min-width: 150px;
}

.inline-edit-input__buttons {
  padding-top: 0.35rem;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
}

.inline-edit-input__button {
  transition: background-color 250ms cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.inline-edit-input__button:disabled {
  opacity: 1;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.overlay-edit {
  background-color: #f7f8f9;
  position: relative;
  padding: 1.5rem 1.5rem 50px 1.5rem;
  border-radius: 1.5rem;
}

.overlay-edit--mandatory {
  padding-bottom: 1.5rem;
}

.overlay-edit--no-scroll .overlay-edit__options {
  height: auto;
  overflow-y: initial;
}

.overlay-edit__header {
  position: relative;
}

.overlay-edit__title {
  color: #515f6d;
  font-size: 0.875rem;
  font-weight: normal;
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay-edit__footer {
  min-height: 24px;
}

.overlay-edit__action-area {
  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay-edit__button {
  padding: 0;
  font-size: 14px;
  height: 24px;
  min-width: 46px;
  margin-right: 10px;
}

.overlay-edit__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
}

.overlay-edit__icon--remove {
  color: #515f6d;
}
.overlay-edit__icon--remove:hover {
  color: #515f6d;
}

.overlay-edit__body {
  padding: 0.75rem 0;
}

.overlay-edit__options {
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.overlay-edit__options--single-col {
  display: block;
}
.overlay-edit__options--single-col .overlay-edit__option-label {
  width: 100%;
  padding: 0.35rem 0.75rem 0.35rem 36px;
  justify-content: flex-start;
}
.overlay-edit__options--single-col > label,
.overlay-edit__options--single-col > div {
  margin-bottom: 0.5rem;
}
.overlay-edit__options--single-col > label:last-child,
.overlay-edit__options--single-col > div:last-child {
  margin-bottom: 0;
}
.overlay-edit__options--single-col .overlay-edit__option-icon {
  left: 0.75rem;
}

.overlay-edit__options--two-cols .overlay-edit__option-label:last-child {
  margin-bottom: 0;
}
.overlay-edit__options--two-cols .overlay-edit__option-label:nth-child(odd):nth-last-child(2) {
  margin-bottom: 0;
}

.overlay-edit__option-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 38px;
  width: calc(50% - 0.175rem);
  background-color: #515f6d;
  position: relative;
  cursor: pointer;
  padding: 0.35rem 0.35rem 0.35rem 36px;
}

.overlay-edit__option-label--gray .overlay-edit__option-icon {
  color: #d4d7dc;
}

.overlay-edit__option-label--not-available {
  background-color: #f7f8f9;
}
.overlay-edit__option-label--not-available:hover {
  background-color: #dedfe0;
}

.overlay-edit__option-label--color-49546a {
  background-color: rgba(73, 84, 106, 0.2);
}
.overlay-edit__option-label--color-49546a:hover {
  background-color: rgba(37, 42, 53, 0.28);
}

.overlay-edit__option-label--color-ff4a75 {
  background-color: rgba(255, 74, 117, 0.2);
}
.overlay-edit__option-label--color-ff4a75:hover {
  background-color: rgba(128, 37, 59, 0.28);
}

.overlay-edit__option-label--color-6f41c1 {
  background-color: rgba(111, 65, 193, 0.2);
}
.overlay-edit__option-label--color-6f41c1:hover {
  background-color: rgba(56, 33, 97, 0.28);
}

.overlay-edit__option-label--color-007bff {
  background-color: rgba(0, 123, 255, 0.2);
}
.overlay-edit__option-label--color-007bff:hover {
  background-color: rgba(0, 62, 128, 0.28);
}

.overlay-edit__option-label--color-eb842d {
  background-color: rgba(235, 132, 45, 0.2);
}
.overlay-edit__option-label--color-eb842d:hover {
  background-color: rgba(118, 66, 23, 0.28);
}

.overlay-edit__option-label--color-39b49b {
  background-color: rgba(57, 180, 155, 0.2);
}
.overlay-edit__option-label--color-39b49b:hover {
  background-color: rgba(29, 90, 78, 0.28);
}

.overlay-edit__option-label--color-708090 {
  background-color: rgba(112, 128, 144, 0.2);
}
.overlay-edit__option-label--color-708090:hover {
  background-color: rgba(56, 64, 72, 0.28);
}

.overlay-edit__option-label--color-c62f3e {
  background-color: rgba(198, 47, 62, 0.2);
}
.overlay-edit__option-label--color-c62f3e:hover {
  background-color: rgba(99, 24, 31, 0.28);
}

.overlay-edit__option-label--color-856088 {
  background-color: rgba(133, 96, 136, 0.2);
}
.overlay-edit__option-label--color-856088:hover {
  background-color: rgba(67, 48, 68, 0.28);
}

.overlay-edit__option-label--color-2072af {
  background-color: rgba(32, 114, 175, 0.2);
}
.overlay-edit__option-label--color-2072af:hover {
  background-color: rgba(16, 57, 88, 0.28);
}

.overlay-edit__option-label--color-de7c67 {
  background-color: rgba(222, 124, 103, 0.2);
}
.overlay-edit__option-label--color-de7c67:hover {
  background-color: rgba(111, 62, 52, 0.28);
}

.overlay-edit__option-label--color-70a951 {
  background-color: rgba(112, 169, 81, 0.2);
}
.overlay-edit__option-label--color-70a951:hover {
  background-color: rgba(56, 85, 41, 0.28);
}

.overlay-edit__option-label--color-0f1f40 {
  background-color: rgba(15, 31, 64, 0.2);
}
.overlay-edit__option-label--color-0f1f40:hover {
  background-color: rgba(8, 16, 32, 0.28);
}

.overlay-edit__option-label--color-d471ac {
  background-color: rgba(212, 113, 172, 0.2);
}
.overlay-edit__option-label--color-d471ac:hover {
  background-color: rgba(106, 57, 86, 0.28);
}

.overlay-edit__option-label--color-9e71bd {
  background-color: rgba(158, 113, 189, 0.2);
}
.overlay-edit__option-label--color-9e71bd:hover {
  background-color: rgba(79, 57, 95, 0.28);
}

.overlay-edit__option-label--color-51acca {
  background-color: rgba(81, 172, 202, 0.2);
}
.overlay-edit__option-label--color-51acca:hover {
  background-color: rgba(41, 86, 101, 0.28);
}

.overlay-edit__option-label--color-e8b717 {
  background-color: rgba(232, 183, 23, 0.2);
}
.overlay-edit__option-label--color-e8b717:hover {
  background-color: rgba(116, 92, 12, 0.28);
}

.overlay-edit__option-label--color-79d18a {
  background-color: rgba(121, 209, 138, 0.2);
}
.overlay-edit__option-label--color-79d18a:hover {
  background-color: rgba(61, 105, 69, 0.28);
}

.overlay-edit__option-label--color-848484 {
  background-color: rgba(132, 132, 132, 0.2);
}
.overlay-edit__option-label--color-848484:hover {
  background-color: rgba(66, 66, 66, 0.28);
}

.overlay-edit__option-label--color-c13ea5 {
  background-color: rgba(193, 62, 165, 0.2);
}
.overlay-edit__option-label--color-c13ea5:hover {
  background-color: rgba(97, 31, 83, 0.28);
}

.overlay-edit__option-label--color-c392e0 {
  background-color: rgba(195, 146, 224, 0.2);
}
.overlay-edit__option-label--color-c392e0:hover {
  background-color: rgba(98, 73, 112, 0.28);
}

.overlay-edit__option-label--color-67c9e3 {
  background-color: rgba(103, 201, 227, 0.2);
}
.overlay-edit__option-label--color-67c9e3:hover {
  background-color: rgba(52, 101, 114, 0.28);
}

.overlay-edit__option-label--color-f1dc4d {
  background-color: rgba(241, 220, 77, 0.2);
}
.overlay-edit__option-label--color-f1dc4d:hover {
  background-color: rgba(121, 110, 39, 0.28);
}

.overlay-edit__option-label--hidden {
  display: none;
}

.overlay-edit__option-label--duplicated {
  background-color: #ffd7dc;
}
.overlay-edit__option-label--duplicated:hover {
  background-color: #e6c2c6;
}
.overlay-edit__option-label--duplicated .overlay-edit__option-label-text {
  color: #ff4a75;
}
.overlay-edit__option-label--duplicated .overlay-edit__option-icon {
  color: #ff4a75;
}

.overlay-edit__option-label-text {
  display: inline-block;
  color: #f7f8f9;
  font-size: 0.75rem;
}

.overlay-edit__option-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.overlay-edit__option-input:checked ~ .overlay-edit__option-icon {
  opacity: 1;
}

.overlay-edit__option-icon {
  font-size: 1.25rem;
  font-weight: bolder;
  text-align: center;
  transition: opacity 125ms cubic-bezier(0.25, 0.8, 0.25, 1);
  color: #f7f8f9;
  position: absolute;
  left: 0.75rem;
  display: inline-block;
  left: 0.35rem;
  opacity: 0;
  color: #49546a;
}

.rich-search-result {
  display: flex;
  align-items: center;
}

.rich-search-result__icon {
  margin-right: 0.75rem;
}

.rich-search-result__type-label {
  color: #515f6d;
  font-size: 1rem;
  font-style: italic;
  font-weight: 100;
  margin-right: 0.75rem;
}

.dropdown-item.active .rich-search-result__icon {
  color: #b1b1b1 !important;
}
.dropdown-item.active .rich-search-result__type-label {
  color: #b1b1b1;
}

.template-popover-page {
  min-width: 276px;
  min-height: 400px;
  position: relative;
  padding-bottom: 5rem;
}

.template-popover-page--grey-background {
  background-color: #6f42c1;
}

.view-custom-settings {
  padding: 0;
  min-width: 300px;
}

.view-custom-settings__dropdown-header {
  font-size: 0.75rem;
}

.view-custom-settings__form-group {
  margin-bottom: 0;
  padding: 0.25rem 1.5rem;
}

.view-custom-settings__custom-control {
  font-size: 1rem;
  color: #515f6d;
}

.view-custom-settings__custom-control-label {
  cursor: pointer;
}
.view-custom-settings__custom-control-label:before, .view-custom-settings__custom-control-label:after {
  top: 0.125rem;
}

.view-custom-settings__row {
  display: flex;
}

.view-custom-settings__item-padding {
  display: inline-block;
  padding: 0.25rem 1.5rem;
}

.calendar.calendar--vertical .layout-metric-item .title {
  display: none;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.loader-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  z-index: 20;
}

.loader-content svg {
  max-width: 640px;
}

.loader-content--full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  z-index: 70;
}

.loader-content--sticky {
  position: sticky !important;
}

.loader-content--empty {
  width: 36px;
}
@media (min-width: 768px) {
  .loader-content--empty {
    left: 145px;
  }
}
@media (min-width: 1280px) {
  .loader-content--empty {
    left: 175px;
  }
}

.card-ap-login .loader-content--empty {
  left: 0;
}

.loader-content--modal {
  border-radius: 1.5rem;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.item-picked__search-bar {
  position: sticky;
  top: -1rem;
  z-index: 2;
  margin-top: -1rem;
  padding: 1rem 0 0.25rem;
  background-color: transparent;
}

.item-picker__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin: 0;
}
.item-picker__list--flex-column {
  flex-direction: column;
}

.item-picker__list--tree {
  flex-direction: column;
}
.item-picker__list--tree .item-picker__list-item {
  width: 100%;
}

.item-picker__list-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 170px;
  padding: 0.5rem 1rem;
}
@media (min-width: 576px) {
  .item-picker__list-item {
    width: 100%;
    padding: 0.5rem 1rem;
  }
}
@media (min-width: 768px) {
  .item-picker__list-item {
    width: 100%;
    padding: 0.5rem 1rem;
  }
}
.item-picker__list-item--flex-column {
  width: 100% !important;
}
.item-picker__list-item.item-picker__list-item--selected {
  /*padding: 1rem;
  border: 3px solid $teal;
  border-radius: 10px;
  margin-bottom: 5px;
  max-width: 88%;
  margin-left: auto;
  margin-right: auto;*/
}
.item-picker__list-item.item-picker__list-item--selected .selectable-brick {
  background: #d6d6d6;
}

.list-item__brick {
  width: 100%;
}
.list-item__brick:hover {
  /*padding: 1rem;
  border: 3px solid $teal;
  border-radius: 10px;
  max-width: 88%;
  margin-left: auto;
  margin-right: auto;*/
  box-shadow: #e8ebee 0px 13px 27px -5px, rgba(81, 95, 109, 0.6) 0px 8px 16px -8px;
  cursor: pointer;
}
.list-item__brick--flex-column {
  font-size: 1.5rem;
}

/* Adjust list item padding or margins as needed to accommodate the checkbox */
.item-picker__list-item {
  display: flex;
  align-items: center;
  /* Add any additional styles for alignment or spacing */
}

.nav-tabs {
  border-bottom: none;
  padding: 0rem;
}
.nav-tabs .nav-link {
  border-color: transparent;
  padding: 0rem;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
}
.nav-tabs .nav-link.active {
  color: #6f42c1;
  border-color: transparent;
  border-bottom: 3px solid #6f42c1;
  background: none;
  padding-bottom: 0.5rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
  color: #ff4a75;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.calendar-row.cdk-drop-list-dragging .calendar-row__overlay {
  display: flex;
}
.calendar-row.cdk-drop-list-dragging .calendar-row__overlay .calendar-row__plus-icon {
  display: flex;
}
.calendar-row .cdk-drag-placeholder {
  display: none !important;
}

.calendar-row--horizontal {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  border: 1px solid #dadfe3;
  border-radius: 0.75rem;
  background-color: #f7f8f9;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .calendar-row--horizontal {
    padding: 1.5rem;
    border-radius: 1.5rem;
  }
}

.calendar-row--vertical {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  border: 1px solid #dadfe3;
  border-radius: 0.75rem;
  background-color: #f7f8f9;
  min-height: 100%;
  background: #ffffff;
}
@media (min-width: 768px) {
  .calendar-row--vertical {
    padding: 1.5rem;
    border-radius: 1.5rem;
  }
}

.calendar-row--awaiting-assignments {
  min-height: 82vh;
  background-color: #ffffff; /* background */
  background-image: radial-gradient(circle, #f7f8f9 30%, transparent 20%); /* dots */
  background-size: 10px 10px; /* Size of the squares containing the dots */
}

.calendar-row__project {
  position: relative;
  display: block;
  width: 100%;
  min-width: 21.5rem;
  max-width: none;
  padding: 0.75rem;
}
@media (min-width: 768px) {
  .calendar-row__project {
    width: 21.5rem;
    min-width: 21.5rem;
    max-width: none;
  }
}

.calendar-row__body {
  min-height: 80px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-right: 0;
  margin-left: 0;
}

.calendar-row__overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center;
  border: 5px dashed #dadfe3;
  border-radius: 1.5rem;
}

.calendar-row__overlay--loading {
  display: flex;
}

.calendar-row__loader {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.calendar-row__plus-icon {
  display: none;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 50px;
  font-size: 3rem;
  color: #dadfe3;
}

modal .ap-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #515f6d;
}

.modal-content,
.modal-dialog {
  min-width: 25%;
}

.modal-content-sm,
.modal-dialog-sm {
  min-width: 25%;
  max-height: 95vh;
}

.modal-content {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
  padding: none;
}

.modal-header {
  border-bottom: 1px solid #dadfe3;
  padding: 2rem;
}

.modal-popover__body {
  max-height: 75vh;
}

.ap-page--cream-bg {
  background: #f7f8f9;
}

.secured-header {
  position: sticky;
  top: 0;
  z-index: 7;
}
@media (min-width: 576px) {
  .secured-header {
    height: 67.5px;
  }
}
@media (min-width: 1280px) {
  .secured-header {
    height: 67.5px;
  }
}

.workspace-common {
  display: flex;
  flex: 1;
}

.workspace-common__content {
  position: relative;
  height: calc(100vh - 67.5px);
  flex: 1;
  background: #f7f8f9;
}

.workspace-common__content-scroller {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  overflow-x: hidden;
  z-index: 1;
}
.workspace-common__content-scroller.workspace-common__content-scroller--no-scroll {
  overflow: hidden;
}

.workspace-common__content-footer {
  display: block;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
  background-color: #ffffff;
  padding: 0;
}

.workspace-common__sidebar {
  z-index: 6;
}

.workspace-common__sub-sidebar {
  z-index: 5;
}

.badge--inverted-primary {
  color: #6f42c1;
  background-color: #e6e8eb;
}

.badge--inverted-secondary {
  color: #ff4a75;
  background-color: #f8e7ec;
}

.badge--primary {
  color: #ffffff;
  background-color: #6f42c1;
}

.badge--secondary {
  color: #ffffff;
  background-color: #ff4a75;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.ap-popover--no-hz-padding .popover-body {
  padding-left: 0;
  padding-right: 0;
}

.popover-menu {
  padding-left: 0;
  list-style: none;
  margin: 0 -1.5rem;
  min-width: 200px;
}

.popover-menu__item, .rich-dropdown-menu__item {
  padding-left: 0;
  list-style: none;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  color: #515f6d;
  white-space: nowrap;
}
.popover-menu__item:hover, .rich-dropdown-menu__item:hover {
  color: #ff4a75;
  background-color: #f7f8f9;
}
.popover-menu__item .popover_icon_style, .rich-dropdown-menu__item .popover_icon_style {
  color: #515f6d;
}
.popover-menu__item.disabled, .rich-dropdown-menu__item.disabled {
  color: #6d6d6d;
  pointer-events: none;
  background-color: transparent;
}

.popover-menu__item--name-value-pair {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.popover-menu__item-name {
  font-size: 0.875rem;
  padding-right: 1.5rem;
}

.popover-menu__item-value {
  font-size: 0.875rem;
  color: #515f6d;
}

.popover-menu__divider {
  height: 1;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #dadfe3;
}

.popover-menu__info-title {
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #6f42c1;
}

.popover-menu__info-title--primary {
  font-size: 0.8125rem;
  color: rgba(81, 95, 109, 0.6);
}

.popover-menu__info-text {
  font-size: 0.875rem;
  color: #6f42c1;
  padding: 0.35rem 1.5rem;
}

.popover-body {
  padding: 0rem 1.5rem 0.5rem 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tooltip-inner {
  text-align: left;
}

.dropdown {
  z-index: 1000 !important;
}

.dropdown-menu {
  overflow-y: auto;
  color: #515f6d;
}

.dropdown-menu.show {
  border: 1px solid #dadfe3;
  box-shadow: #e8ebee 0px 13px 27px -5px, rgba(81, 95, 109, 0.6) 0px 8px 16px -8px;
}

ngb-datepicker.dropdown-menu {
  max-height: initial;
}

ngb-typeahead-window.dropdown-menu {
  max-height: calc(100vh - 30%);
  z-index: 2000 !important;
}

p {
  margin-bottom: 0.75rem;
}

.ap-panel-title {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 0;
  color: #6f42c1;
  text-align: center;
}

.ap-panel-subtitle {
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 0;
  color: #6f42c1;
  text-align: center;
}

.ap-section-heading {
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: 0;
  color: #6f42c1;
}

.ap-subheading {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0;
  color: #515f6d;
}

.ap-subheading--white {
  color: #515f6d;
}

.ap-subheading--primary {
  color: #6f42c1;
}

.ap-no-content {
  font-size: 0.875rem;
  color: #515f6d;
}

.ap-link--dark {
  color: #515f6d;
  text-decoration: underline;
}
.ap-link--dark:hover {
  color: #6f42c1;
}

.ap-link--sm {
  font-size: 14px;
}

.ap-status-label {
  font-size: 0.625rem;
  color: #515f6d;
  font-style: italic;
}

h1, .h1 {
  font-size: 2.5rem;
  color: #515f6d;
}

h2, .h2 {
  font-size: 2rem;
  color: #515f6d;
}

h3, .h3 {
  font-size: 1.75rem;
  color: #515f6d;
}

h4, .h4 {
  font-size: 1.5rem;
  color: #515f6d;
}

h5, .h5 {
  font-size: 1.25rem;
  color: #515f6d;
}

h6, .h6 {
  font-size: 1rem;
  color: #515f6d;
}

.ap-action-icon-btn {
  color: #515f6d;
  min-width: 30px;
  min-height: 30px;
  font-size: 1rem;
  margin-left: 0.35rem;
  cursor: pointer;
  padding: 0;
  border-width: 0;
}
.ap-action-icon-btn:hover {
  background-color: #f7f8f9;
}

.modal-footer .btn-sm.btn-primary, .modal-footer .btn-group-sm > .btn-primary.btn {
  min-width: 110px;
}

.btn-outline-link {
  Color: #515f6d;
}
.btn-outline-link:hover {
  color: #ff4a75;
}
.btn-outline-link:focus {
  color: #515f6d;
  background-color: #ffffff;
  border: 1px solid #dadfe3;
}

.btn-outline-light {
  Color: #515f6d;
  background-color: #ffffff;
  border: 1px solid #dadfe3;
}
.btn-outline-light:hover {
  color: #ff4a75;
  background-color: #ffffff;
  border: 1px solid #dadfe3;
  transform: translateY(1px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.btn-outline-light:focus {
  color: #515f6d;
  background-color: #ffffff;
  border: 1px solid #dadfe3;
}

.btn-light {
  color: #515f6d;
  background-color: #f7f8f9;
  border-color: #dadfe3;
}
.btn-light:hover {
  background-color: #e8ebee;
  border-color: #dadfe3;
}
.btn-light:focus {
  color: #515f6d;
  background-color: #ffffff;
  border: 1px solid #dadfe3;
  box-shadow: #e8ebee 0px 13px 27px -5px, rgba(81, 95, 109, 0.6) 0px 8px 16px -8px;
  text-underline-mode: none;
}
.btn-light:disabled, .btn-light.disabled {
  color: rgba(81, 95, 109, 0.6);
  background-color: #f7f8f9;
  border-color: #dadfe3;
  cursor: not-allowed;
  opacity: 0.65;
}
.btn-light:disabled:hover, .btn-light:disabled:focus, .btn-light.disabled:hover, .btn-light.disabled:focus {
  color: rgba(81, 95, 109, 0.6);
  background-color: #f7f8f9;
  border-color: #dadfe3;
  box-shadow: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #6f42c1;
  border: none;
}
.btn-primary:hover {
  background-color: #59339d;
  border-color: #ffffff;
  transform: translateY(1px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.btn-primary:focus {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  text-underline-mode: none;
}

.btn-primary-reverse {
  color: #515f6d;
  background-color: #e4dcf3;
  border: none;
}
.btn-primary-reverse:hover {
  background-color: #e4dcf3;
  border-color: #515f6d;
}
.btn-primary-reverse:focus {
  box-shadow: #e8ebee 0px 13px 27px -5px, rgba(81, 95, 109, 0.6) 0px 8px 16px -8px;
  text-underline-mode: none;
}

.btn-secondary {
  color: #515f6d;
  background-color: #ffe3ea;
  border: none;
}
.btn-secondary:hover {
  background-color: #ff4a75;
  border-color: #ffffff;
}
.btn-secondary:focus {
  box-shadow: #e8ebee 0px 13px 27px -5px, rgba(81, 95, 109, 0.6) 0px 8px 16px -8px;
  text-underline-mode: none;
}

.btn-dropdown-title {
  Color: #515f6d;
  font-size: 1.5rem;
  width: 220px;
}
.btn-dropdown-title:hover {
  color: #ff4a75;
  transform: translateY(1px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.btn-dropdown-title:focus {
  box-shadow: none;
}

.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 1rem;
  background-color: #ffffff;
  padding: 2px;
  border: 1px solid #dadfe3;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
  background-color: #ffe3ea;
  border: none;
  color: #515f6d;
}

.radio {
  margin-bottom: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #ff4a75;
  text-decoration: none;
  background: none;
}

.close {
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
  color: #515f6d;
  opacity: 1;
}
.close:hover {
  Color: #ff4a75;
}

.picker-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(1.5em + 0.75rem + 2px);
  min-width: 60px;
  min-height: 60px;
  padding: 0.375rem 0.75rem;
  background-color: #ffffff;
  border: 1px solid #dadfe3;
}

.picker-button--thumb {
  width: 220px;
  height: 140px;
  background-size: cover;
}

.picker-button--icon-not-available:before,
.picker-button--color-not-available:before,
.picker-button--thumb-not-available:before {
  content: "N/A";
  color: #515f6d;
  font-size: 0.875rem;
}

.background--not-available {
  background-color: #f7f8f9 !important;
}
.background--not-available:hover {
  background-color: #dedfe0 !important;
}

.background--color-49546a {
  background-color: rgba(73, 84, 106, 0.2) !important;
}
.background--color-49546a:hover {
  background-color: rgba(37, 42, 53, 0.28) !important;
}

.background--color-ff4a75 {
  background-color: rgba(255, 74, 117, 0.2) !important;
}
.background--color-ff4a75:hover {
  background-color: rgba(128, 37, 59, 0.28) !important;
}

.background--color-6f41c1 {
  background-color: rgba(111, 65, 193, 0.2) !important;
}
.background--color-6f41c1:hover {
  background-color: rgba(56, 33, 97, 0.28) !important;
}

.background--color-007bff {
  background-color: rgba(0, 123, 255, 0.2) !important;
}
.background--color-007bff:hover {
  background-color: rgba(0, 62, 128, 0.28) !important;
}

.background--color-eb842d {
  background-color: rgba(235, 132, 45, 0.2) !important;
}
.background--color-eb842d:hover {
  background-color: rgba(118, 66, 23, 0.28) !important;
}

.background--color-39b49b {
  background-color: rgba(57, 180, 155, 0.2) !important;
}
.background--color-39b49b:hover {
  background-color: rgba(29, 90, 78, 0.28) !important;
}

.background--color-708090 {
  background-color: rgba(112, 128, 144, 0.2) !important;
}
.background--color-708090:hover {
  background-color: rgba(56, 64, 72, 0.28) !important;
}

.background--color-c62f3e {
  background-color: rgba(198, 47, 62, 0.2) !important;
}
.background--color-c62f3e:hover {
  background-color: rgba(99, 24, 31, 0.28) !important;
}

.background--color-856088 {
  background-color: rgba(133, 96, 136, 0.2) !important;
}
.background--color-856088:hover {
  background-color: rgba(67, 48, 68, 0.28) !important;
}

.background--color-2072af {
  background-color: rgba(32, 114, 175, 0.2) !important;
}
.background--color-2072af:hover {
  background-color: rgba(16, 57, 88, 0.28) !important;
}

.background--color-de7c67 {
  background-color: rgba(222, 124, 103, 0.2) !important;
}
.background--color-de7c67:hover {
  background-color: rgba(111, 62, 52, 0.28) !important;
}

.background--color-70a951 {
  background-color: rgba(112, 169, 81, 0.2) !important;
}
.background--color-70a951:hover {
  background-color: rgba(56, 85, 41, 0.28) !important;
}

.background--color-0f1f40 {
  background-color: rgba(15, 31, 64, 0.2) !important;
}
.background--color-0f1f40:hover {
  background-color: rgba(8, 16, 32, 0.28) !important;
}

.background--color-d471ac {
  background-color: rgba(212, 113, 172, 0.2) !important;
}
.background--color-d471ac:hover {
  background-color: rgba(106, 57, 86, 0.28) !important;
}

.background--color-9e71bd {
  background-color: rgba(158, 113, 189, 0.2) !important;
}
.background--color-9e71bd:hover {
  background-color: rgba(79, 57, 95, 0.28) !important;
}

.background--color-51acca {
  background-color: rgba(81, 172, 202, 0.2) !important;
}
.background--color-51acca:hover {
  background-color: rgba(41, 86, 101, 0.28) !important;
}

.background--color-e8b717 {
  background-color: rgba(232, 183, 23, 0.2) !important;
}
.background--color-e8b717:hover {
  background-color: rgba(116, 92, 12, 0.28) !important;
}

.background--color-79d18a {
  background-color: rgba(121, 209, 138, 0.2) !important;
}
.background--color-79d18a:hover {
  background-color: rgba(61, 105, 69, 0.28) !important;
}

.background--color-848484 {
  background-color: rgba(132, 132, 132, 0.2) !important;
}
.background--color-848484:hover {
  background-color: rgba(66, 66, 66, 0.28) !important;
}

.background--color-c13ea5 {
  background-color: rgba(193, 62, 165, 0.2) !important;
}
.background--color-c13ea5:hover {
  background-color: rgba(97, 31, 83, 0.28) !important;
}

.background--color-c392e0 {
  background-color: rgba(195, 146, 224, 0.2) !important;
}
.background--color-c392e0:hover {
  background-color: rgba(98, 73, 112, 0.28) !important;
}

.background--color-67c9e3 {
  background-color: rgba(103, 201, 227, 0.2) !important;
}
.background--color-67c9e3:hover {
  background-color: rgba(52, 101, 114, 0.28) !important;
}

.background--color-f1dc4d {
  background-color: rgba(241, 220, 77, 0.2) !important;
}
.background--color-f1dc4d:hover {
  background-color: rgba(121, 110, 39, 0.28) !important;
}

.background--transparent {
  background-color: #ffe3ea;
  border: 1px solid #dadfe3;
  color: #515f6d;
}
.background--transparent:hover {
  color: #515f6d;
  background-color: #ffe3ea;
}

.ap-form-section {
  padding: 0.75rem 0;
  border-bottom: 1px solid #d5d5d5;
}

.ap-form-field-width-xs {
  width: 200px;
}

.ap-form-field-width-sm {
  width: 320px;
}

.ap-form-field-width-md {
  width: 480px;
}

.ap-form-field-width-lg {
  width: 600px;
}

.ap-field-label-width {
  width: 150px;
}

.ap-field-label-sm {
  font-size: 0.875rem;
}

.ap-field-text {
  font-size: 1rem;
  color: #515f6d;
  padding: 0.375rem 0;
}

.ap-field-text-sm {
  font-size: 0.875rem;
}

.ap-field-text--tooltip {
  color: #515f6d;
}

.ap-field-text--positive {
  color: #6f42c1;
}

.ap-field-text--negative {
  color: #f06292;
}

.ap-required-asterisk {
  color: #dc3545;
  margin-left: 0.35rem;
}

.ap-optional-text {
  color: #909090;
  font-style: italic;
}

.btn.disabled,
.btn:disabled {
  filter: opacity(0.5);
}

.form-control {
  color: #515f6d;
  background-color: #ffffff;
  height: 45px;
}

.no-input-focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.ap-table {
  border: 1px solid #dadfe3;
}
.ap-table tr {
  background-color: #f7f8f9;
}

.ap-table--bg-creamy tr {
  background-color: #ffffff;
}

.assignment-table__table {
  border-collapse: separate;
  border-spacing: 0;
}
.assignment-table__table thead tr:first-child th:nth-child(-n+3) {
  position: sticky;
  z-index: 3 !important;
}
.assignment-table__table tbody td:nth-child(-n+3) {
  position: sticky;
  z-index: 2 !important;
}
.assignment-table__table tbody td:nth-child(-n+3) {
  background: #f7f8f9;
}
.assignment-table__table thead tr:first-child th:first-child, .assignment-table__table tbody td:first-child {
  width: 150px;
  left: 0;
}
.assignment-table__table thead tr:first-child th:nth-child(2), .assignment-table__table tbody td:nth-child(2) {
  left: 150px;
  width: 60px;
}
.assignment-table__table thead tr:first-child th:nth-child(3), .assignment-table__table tbody td:nth-child(3) {
  left: 210px;
}
.assignment-table__table thead {
  position: sticky;
  top: 0;
  z-index: 3;
}
.assignment-table__table thead th {
  z-index: 1;
}

.date-picker__calendar-btn {
  border: 1px solid #b1b1b1;
  background-color: #e9e6f3;
}
.date-picker__calendar-btn:hover {
  background-color: #49546a;
}
.date-picker__calendar-btn:focus {
  border-color: #b8a2e0;
  box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.25);
}

.template-explainer-img {
  max-width: 521px;
  width: auto;
  height: auto;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1050;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1050;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1050;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
}

/*
* PatP: We're going to consolidate the BS backdrop and the CDK Backdrop styles
*
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 1;

      // In high contrast mode the rgba background will become solid so we need to fall back
      // to making it opaque using `opacity`. Note that we can't use the `cdk-high-contrast`
      // mixin, because we can't normalize the import path to the _a11y.scss both for the
      // source and when this file is distributed. See #10908.
      @media screen and (-ms-high-contrast: active) {
        opacity: 0.6;
      }
  }

  .cdk-overlay-dark-backdrop {
    background: $cdk-overlay-dark-backdrop-background;
  }
*
*/
.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.z-table__editor-workspace-group .ng-select {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dadfe3;
}
.z-table__editor-workspace-group .ng-select .ng-select-container {
  flex-wrap: wrap;
  background: transparent;
  border: none;
  height: auto;
  min-height: 28px;
  color: #515f6d;
  max-height: 29px;
}
.z-table__editor-workspace-group .ng-select .ng-select-container:hover {
  box-shadow: none;
}
.z-table__editor-workspace-group .ng-select .ng-select-container .ng-value-container {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}
.z-table__editor-workspace-group .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  width: 100%;
  height: 100%;
  padding: 1px 5px;
  z-index: 1;
  font-size: inherit !important;
  text-align: left;
  color: #515f6d;
}
.z-table__editor-workspace-group .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  margin-left: 0;
}
.z-table__editor-workspace-group .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: inherit !important;
}
.z-table__editor-workspace-group .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  height: 100%;
  top: 0;
  padding-left: 5px;
}
.z-table__editor-workspace-group .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input > input {
  height: 100%;
}
.z-table__editor-workspace-group .ng-select.ng-select-opened .ng-select-container {
  z-index: 4 !important;
}
.z-table__editor-workspace-group .ng-dropdown-panel {
  width: auto;
  min-width: 100%;
  z-index: 7 !important;
}
.z-table__editor-workspace-group.z-table__editor--empty .ng-input {
  border-radius: 0.75rem;
}
.z-table__editor-workspace-group .ng-select-disabled .ng-value .ng-value-label,
.z-table__editor-workspace-group .ng-input input:disabled {
  color: rgba(81, 95, 109, 0.6);
  opacity: 1;
}

.ng-select .ng-select-container {
  min-height: 38px;
  background-color: #e8ebee;
}

.ng-select.ng-select-opened > .ng-select-container {
  background-color: #f7f8f9;
}

.ng-placeholder {
  font-size: 0.875rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 0.875rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 9px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 180px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #ffffff;
}

.ng-select.ng-select-focused .ng-select-container,
.ng-select.ng-select-focused.ng-select-opened .ng-select-container:hover,
.ng-select.ng-select-focused:not(.ng-select-opened) .ng-select-container {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: #dadfe3;
  box-shadow: #e8ebee 0px 13px 27px -5px, rgba(81, 95, 109, 0.6) 0px 8px 16px -8px;
}
@media (prefers-reduced-motion: reduce) {
  .ng-select.ng-select-focused .ng-select-container,
.ng-select.ng-select-focused.ng-select-opened .ng-select-container:hover,
.ng-select.ng-select-focused:not(.ng-select-opened) .ng-select-container {
    transition: none;
  }
}

.ng-dropdown-panel.ng-select-top {
  margin-bottom: 0.2rem;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 0.2rem;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-radius: 4px;
}

.ng-select.ng-select-opened .ng-select-container .ng-arrow {
  top: -5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  width: 50px;
}

.ng-dropdown-panel {
  width: auto !important;
  z-index: 1080 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  margin-left: 0.7rem;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
  font-size: 0.875rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 0.75rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  width: 100%;
  padding: 0.35rem 0.75rem;
  background-color: #f7f8f9;
  margin-top: 0.75rem;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup:first-child {
  margin-top: 0;
}

.ng-select.ng-select-disabled.table-editor {
  background: #f7f8f9 !important;
  border: none !important;
}
.ng-select.ng-select-disabled.table-editor .ng-arrow-wrapper {
  display: none !important;
}
.ng-select.ng-select-disabled.table-editor .ng-select-container .ng-value-container .ng-placeholder {
  color: rgba(81, 95, 109, 0.6) !important;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-backdrop {
  animation-name: modalBgFadeIn;
  animation-duration: 0.3s;
  background: #000000;
  z-index: 1040 !important;
}

.modal-backdrop.show {
  opacity: 0.4;
}

.modal > .modal-dialog {
  animation-name: modalZoomIn !important;
  animation-duration: 0.3s;
}

.modal-xxl {
  max-width: calc(100% - 20px);
}

.modal-xs {
  max-width: 380px;
}

.modal.modal--assignment-new .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
@media (min-width: 1280px) {
  .modal.modal--assignment-new .modal-dialog {
    margin-left: 300px;
  }
}

.modal.ap-modal-desktop--lg .modal-dialog {
  max-width: 100%;
}
@media (min-width: 1280px) {
  .modal.ap-modal-desktop--lg .modal-dialog {
    max-width: 1200px;
  }
}

.modal.ap-modal-desktop--xl .modal-dialog {
  max-width: 100%;
}
@media (min-width: 1280px) {
  .modal.ap-modal-desktop--xl .modal-dialog {
    max-width: 1280px;
  }
}

.modal.ap-modal--high-zindex {
  z-index: 1065 !important;
}

.modal.chat-modal .modal-content, .modal.form-edit-modal .modal-content, .modal.layout-picker-modal .modal-content, .modal.ws-links-modal .modal-content {
  border: 1px solid rgb(204, 204, 204);
  transition: none 0s ease 0s;
  will-change: auto;
  box-shadow: rgb(0, 0, 0) 0px 6px 20px 0px;
  height: auto;
  min-height: 100%;
  padding: 0px;
}
.modal.chat-modal .modal-content .modal-body, .modal.form-edit-modal .modal-content .modal-body, .modal.layout-picker-modal .modal-content .modal-body, .modal.ws-links-modal .modal-content .modal-body {
  overflow-y: hidden !important;
  max-height: 88vh !important;
  padding: 0px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.modal.ws-links-modal .modal-dialog,
.modal.form-edit-modal .modal-dialog,
.modal.layout-picker-modal .modal-dialog,
.modal.chat-modal .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 90vw;
  max-height: 90vh;
  margin: 20px 0 0 0;
}

.ngb-dp-day:focus, .ngb-dp-day.focus {
  outline-color: #252a35;
}
.ngb-dp-day:hover {
  background-color: #49546a;
  color: #f7f8f9;
}
.ngb-dp-day .btn-outline-light:hover, .ngb-dp-day .btn-outline-light:active:not(:disabled):not(.disabled), .ngb-dp-day .btn-outline-light:focus {
  background-color: initial;
}

.ngb-dp-today {
  background: #ffca00;
  color: #f7f8f9;
  border-radius: 0.25rem;
}
.ngb-dp-today.hidden {
  background-color: initial;
}

.ngb-tp {
  padding: 6px;
}
.ngb-tp .ngb-tp-input-container {
  position: relative;
  padding-right: 32px;
  width: 76px;
}
.ngb-tp .ngb-tp-input-container > button:first-child {
  position: absolute;
  right: 0;
  bottom: 50%;
}
.ngb-tp .ngb-tp-input-container > button:last-child {
  position: absolute;
  right: 0;
  top: 50%;
}
.ngb-tp .ngb-tp-input-container > input {
  min-width: 34px;
}

.pac-container {
  z-index: 2000;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
@media (min-width: 1600px) {
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
}

@media (min-width: 1600px) {
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #757d8e;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #49546a;
  background-clip: padding-box;
}

@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
.ap-popover--no-hz-padding .popover-body {
  padding-left: 0;
  padding-right: 0;
}

.popover-menu {
  padding-left: 0;
  list-style: none;
  margin: 0 -1.5rem;
  min-width: 200px;
}

.popover-menu__item, .rich-dropdown-menu__item {
  padding-left: 0;
  list-style: none;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  color: #515f6d;
  white-space: nowrap;
}
.popover-menu__item:hover, .rich-dropdown-menu__item:hover {
  color: #ff4a75;
  background-color: #f7f8f9;
}
.popover-menu__item .popover_icon_style, .rich-dropdown-menu__item .popover_icon_style {
  color: #515f6d;
}
.popover-menu__item.disabled, .rich-dropdown-menu__item.disabled {
  color: #6d6d6d;
  pointer-events: none;
  background-color: transparent;
}

.popover-menu__item--name-value-pair {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.popover-menu__item-name {
  font-size: 0.875rem;
  padding-right: 1.5rem;
}

.popover-menu__item-value {
  font-size: 0.875rem;
  color: #515f6d;
}

.popover-menu__divider {
  height: 1;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #dadfe3;
}

.popover-menu__info-title {
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #6f42c1;
}

.popover-menu__info-title--primary {
  font-size: 0.8125rem;
  color: rgba(81, 95, 109, 0.6);
}

.popover-menu__info-text {
  font-size: 0.875rem;
  color: #6f42c1;
  padding: 0.35rem 1.5rem;
}

.popover-body {
  padding: 0rem 1.5rem 0.5rem 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/*
* Attention: Only place "very" generic styles into this file.
*/
@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }
}
@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
@keyframes modalBgFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}
/*------------------------------------*\
  Colors
\*------------------------------------*/
/*------------------------------------*\
  Shadows
\*------------------------------------*/
/*------------------------------------*\
  Typography
\*------------------------------------*/
/* $gray-100; */
.assignment-primary-tag:hover:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
/* Hide elements by screen size*/
@media (max-width: 768px) {
  /* Hide the element on screens smaller than or equal to 768px */
  .hide-on-sm {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  /* Hide the element on screens between 769px and 992px */
  .hide-on-md {
    display: none !important;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  /* Hide the element on screens between 993px and 1200px */
  .hide-on-lg {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  /* Hide the element on screens larger than 1200px */
  .hide-on-xl {
    display: none !important;
  }
}
html {
  min-height: 100%;
  font-size: 14px;
  line-height: 1.25;
}

body {
  min-height: 100%;
  font-family: "Figtree", "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  background-color: #f7f8f9;
}

ng-component,
app-secured-header,
app-multi-select-controls,
main {
  display: contents;
}

.ap h1,
.ap h2,
.ap h3 {
  font-family: "Figtree", "Roboto", sans-serif;
}

a.plain-link {
  color: #515f6d;
  text-decoration: none;
}
a.plain-link:hover {
  text-decoration: underline;
}

.ap .card-ap {
  border-radius: 0;
  box-shadow: 1px 1px 3px 0px #aaa;
}

/* Header */
.ap .print-header {
  display: none;
}

/* My Workspaces */
/* Legal Documents */
.ap .text-legal-doc h1 {
  font-size: 1.2rem;
  font-weight: bold;
}

.ap .text-legal-doc h2 {
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: underline;
}

.ap .text-legal-doc h3 {
  font-size: 1rem;
  font-weight: bold;
}

.ap .text-legal-doc p,
.ap .text-legal-doc li {
  font-size: 0.8rem;
}

.ap .text-legal-doc h1,
.ap .text-legal-doc h2,
.ap .text-legal-doc h3,
.ap .text-legal-doc p {
  margin-top: 0.1em;
  margin-bottom: 1.2em;
}

.ap .modal-body .text-legal-doc {
  height: 400px;
  max-height: 100%;
  overflow: auto;
}

/* Generic table fixes */
.table thead th {
  font-weight: 400;
  font-size: 1rem;
  border: 0;
}

.table tbody tr:first-child td {
  border-top: 0;
}

.__rm.modal-body {
  position: relative;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-connected-position-bounding-box {
  overflow-y: auto;
  pointer-events: auto !important;
}

.cdk-overlay-backdrop,
.__rm.modal-backdrop {
  animation-name: modalBgFadeIn;
  animation-duration: 0.3s;
  background: #000000;
}

.__rm.modal-backdrop.show,
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.4;
}

.overlay-popover,
.__rm.modal > .modal-dialog {
  animation-name: modalZoomIn !important;
  animation-duration: 0.3s;
}

.overlay-popover,
.popover-layout,
.__rm.modal-content {
  border: 0;
  border-radius: 1.5rem;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
}

.__rm.modal-header {
  border-top-left-radius: 1.425rem; /* Fix strange white artifact border */
  border-top-right-radius: 1.425rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.__rm.modal-header .close {
  text-shadow: none;
}

.__rm.modal-body {
  border-bottom-left-radius: 1.425rem; /* Fix strange white artifact border */
  border-bottom-right-radius: 1.425rem;
  background-color: #f7f8f9;
}

.__rm.modal-title {
  font-size: 1.125rem;
}

label[for] {
  cursor: pointer;
}

.loading-icon {
  position: fixed;
  width: auto;
  height: auto;
  top: 27px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

.nav__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: none;
  padding: 0;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 1.3rem;
  line-height: 1.1;
  color: #515f6d;
}
.nav__button:hover {
  background-color: #f7f8f9;
  border: 1px solid #dadfe3;
}

/* App Alerts */
.app-alert__workspace {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1051; /* Ensure it appears above other content */
  width: auto; /* Adjust width as needed */
  max-width: 300px; /* Adjust max-width as needed */
}

/* Sidebar Nav */
.sidebar-nav {
  padding: 1rem 1rem;
}
.sidebar-nav:not(:last-child) {
  border-bottom: 1px solid #dadfe3;
}

.sidebar-nav__title {
  color: #515f6d;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.link__item {
  color: #515f6d;
  font-size: 0.875rem;
  text-align: left;
  width: 100%;
  display: block;
  padding: 0.25rem 1.25rem;
  border-radius: 0.25rem;
}
.link__item:hover {
  color: #515f6d;
  text-decoration: none;
}
.link__item.link__item--active {
  color: #ff4a75;
}

/* Lazy Scroller */
.lazy-scroller-placeholder--horizontal,
.lazy-scroller-container--horizontal {
  height: 100%;
}

.lazy-scroller-container {
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Generic Mobile Style Fixes */
@media (max-width: 767.98px) {
  .workspace-common__sidebar,
.workspace-common__sub-sidebar {
    display: none;
  }
  .workspace-content__control-bar .rich-dropdown__label {
    width: 80px;
  }
  .workspace-content__control-bar .controlbar__filter-status-label {
    width: 75px;
  }
  .workspace-content__control-bar .expansion-button, .workspace-content__control-bar .controlbar__content {
    background-color: #f7f8f9;
  }
  .hero-dropdown__hero-btn {
    flex: auto;
  }
  :host ::ng-deep .ngb-dp-months {
    flex-flow: row wrap;
    justify-content: flex-start;
  }
  :host ::ng-deep .ngb-dp-month {
    flex: 1 0 auto;
  }
}