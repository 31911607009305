
.ngb-dp-day {

  &:focus,
  &.focus {
    outline-color: mix($gray-900, $accent, $theme-color-interval * 5);
  }

  &:hover {

    background-color: $accent;
    color: $theme-color-bg;

  }

  .btn-outline-light {
    &:hover, &:active:not(:disabled):not(.disabled), &:focus {
      background-color: initial;
    }
  }
}

.ngb-dp-today {
  background: $color-yellow;
  color: $theme-color-bg;
  border-radius: 0.25rem;

  &.hidden {
    background-color: initial;
  }
}
