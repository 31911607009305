@mixin close-btn($primary-color-txt) {
  position: absolute;
  color: $primary-color-txt;
  font-size: rem-calc(24px);
  right: 10px;
  top: 10px;
  @include icon-btn();
}

@mixin icon-btn() {
  cursor: pointer;
  padding: 0;
  border-width: 0;
  opacity: 0.5;
  @include hover-focus() {
    opacity: 0.75;
  }
}


@mixin nav-btn() {
  display: flex;
  justify-content: center;
  align-items: center;

  width: $nav-btn-size;
  height: $nav-btn-size;

  background-color: none;
  padding: 0;
  border-radius: $border-radius;
  cursor: pointer;

  font-size: 1.3rem;
  line-height: 1.1;
  color: $theme-color-txt;

  &:hover {
    background-color: $theme-color-bg;
    border: 1px solid $theme-color-border;
  }
}

@mixin nav-btn-active() {
  background-color: $theme-color-bg-darken;
  &:hover {
    background-color: $theme-color-bg-darken;
  }
}


@mixin controlbar-btn() {
  border-color: transparent;
  color: $body-color;
  &:hover {
    color: $secondary-brand;
  }
  &:focus {
    background-color: $accent;
    color: $theme-color-bg;
  }
}


@mixin controlbar-btn-active() {
  background-color: $accent;
  color: $theme-color-bg;
  &:hover, &:focus {
    background-color: mix($gray-900, $accent, $theme-color-interval);
  }
}


@mixin action-icon-btn() {
  color: $color-heading-gray;
  min-width: 30px;
  min-height: 30px;
  font-size: rem-calc(16px);
  margin-left: $layout-margin-sm;
  cursor: pointer;
  padding: 0;
  border-width: 0;

  &:hover {
    background-color: $theme-color-bg;
  }
}


