@keyframes shake {
  0% {
    transform: rotate(0.5deg);
  }
  50% {
    transform: rotate(-0.5deg);
  }
  100% {
    transform: rotate(0.5deg);
  }

}

@keyframes shakeLite {
  0% {
    transform: rotate(0.2deg);
  }
  50% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}
