@import 'reference';

.item-picked__search-bar {
  position: sticky;
  top: -1rem;
  z-index: 2;
  margin-top: -1rem;
  padding: 1rem 0 0.25rem;
  background-color: transparent;
}

.item-picker__list {
  display: flex;
  flex-wrap: wrap;
  padding: $layout-padding 0;
  margin: 0;

  &--flex-column {
    flex-direction: column;
  }
}

.item-picker__list--tree {
  flex-direction: column;

  .item-picker__list-item {
    width: 100%;
  }
}

.item-picker__list-item {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 170px;
  padding: 0.5rem 1rem; // Default padding for xs and smaller sizes

  @include media-breakpoint-up(sm) {
    width: 100%;
    padding: 0.5rem 1rem; // Adjusted padding for sm breakpoint
  }

  @include media-breakpoint-up(md) {
    width: 100%;
    padding: 0.5rem 1rem; // Adjusted padding for md breakpoint
  }

  // @include media-breakpoint-up(lg) {
  //   width: 20%;
  //   padding: 0.5rem 5rem; // Adjusted padding for lg breakpoint
  // }

  &--flex-column {
    width: 100% !important;
  }

  &.item-picker__list-item--selected {
    /*padding: 1rem;
    border: 3px solid $teal;
    border-radius: 10px;
    margin-bottom: 5px;
    max-width: 88%;
    margin-left: auto;
    margin-right: auto;*/
    & .selectable-brick {
      background: mix($gray-900, #eee, $theme-color-interval);
    }
  }
}

.list-item__brick {
  width: 100%;

  &:hover {
    /*padding: 1rem;
    border: 3px solid $teal;
    border-radius: 10px;
    max-width: 88%;
    margin-left: auto;
    margin-right: auto;*/
    box-shadow: $box-shadow-theme-1; // Adding a subtle shadow for depth
    cursor: pointer; // Changes the mouse cursor to a pointer
  }

  &--flex-column {
    font-size: 1.5rem;
  }
}

/* Adjust list item padding or margins as needed to accommodate the checkbox */
.item-picker__list-item {
  display: flex;
  align-items: center;
  /* Add any additional styles for alignment or spacing */
}
