.workspace-layout-content {
  display: flex;
  flex-direction: column;

  //height: $workspace-height;
  //@include media-breakpoint-up(lg) {
  //  height: $workspace-height-lg;
  //}
}

.workspace-layout-content__body {
  flex-grow: 1;
  overflow: auto;
  padding: $layout-padding;
}
