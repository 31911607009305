//@each $name, $color in (success: $success, info: $info, warning: $warning, danger: $danger) {
//  .btn-link-#{$name} {
//    color: $color;
//    @include hover-focus {
//      color: darken($color, 10%) !important;
//    }
//  }
//}

.ap-action-icon-btn {
  @include action-icon-btn;
}

//.btn-sm:not(.dropdown-toggle){
//  min-width: 100px;
//}
//
//.btn-group-sm > .btn{
//  min-width: 0;
//}

.modal-footer {
  & .btn-sm.btn-primary {
    min-width: 110px;
  }
}

// Button Outline
.btn-outline-link {
  Color: $theme-color-txt;

  &:hover {
    color: $secondary-brand;

  }

  &:focus {
    color: $theme-color-txt;
    background-color: $white;
    border: 1px solid $theme-color-border;
  }
}

// Button Outline Link
.btn-outline-light {
  Color: $theme-color-txt;
  background-color: $white;
  border: 1px solid $theme-color-border;

  &:hover {
    color: $secondary-brand;
    background-color: $white;
    border: 1px solid $theme-color-border;
    transform: translateY(1px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);

  }

  &:focus {
    color: $theme-color-txt;
    background-color: $white;
    border: 1px solid $theme-color-border;
  }
}

//Button Light
.btn-light {
  color: $theme-color-txt;
  background-color: $theme-color-bg;
  border-color: $theme-color-border;

  &:hover {
    background-color: $theme-color-bg-darken;
    border-color: $theme-color-border;
  }

  &:focus {
    color: $theme-color-txt;
    background-color: $white;
    border: 1px solid $theme-color-border;
    box-shadow: $box-shadow-theme-1;
    text-underline-mode: none;
  }

  // Adding disabled state
  &:disabled,
  &.disabled {
    color: $theme-color-txt-lighten;
    background-color: $theme-color-bg;
    border-color: $theme-color-border;
    cursor: not-allowed;
    opacity: 0.65; // Adjust as needed to match your design

    &:hover,
    &:focus {
      color: $theme-color-txt-lighten;
      background-color: $theme-color-bg;
      border-color: $theme-color-border;
      box-shadow: none;
    }
  }
}

//Button Primary
.btn-primary {
  color: $white;
  background-color: $primary-brand;
  border: none;

  &:hover {
    background-color: darken($primary-brand, 10%);
    border-color: $white;
    transform: translateY(1px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);

  }

  &:focus {
    box-shadow: $box-shadow-sm;
    text-underline-mode: none;
  }
}

//Button Primary
.btn-primary-reverse {
  color: $theme-color-txt;
  background-color: lighten($primary-brand, 40%);
  border: none;

  &:hover {
    background-color: lighten($primary-brand, 40%);
    border-color: $theme-color-txt;
  }

  &:focus {
    box-shadow: $box-shadow-theme-1;
    text-underline-mode: none;
  }
}

//Button Secondary
.btn-secondary {
  color: $theme-color-txt;
  background-color: lighten($secondary-brand, 30%);
  border: none;

  &:hover {
    background-color: $secondary-brand;
    border-color: $white;
  }

  &:focus {
    box-shadow: $box-shadow-theme-1;
    text-underline-mode: none;
  }
}

// Button Outline
.btn-dropdown-title {
  Color: $theme-color-txt;
  font-size: 1.5rem;
  width: 220px;

  &:hover {
    color: $secondary-brand;
    transform: translateY(1px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);

  }

  &:focus {
    box-shadow: none;
  }
}

// Radio Button

.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: $border-radius-lg;
  background-color: $white;
  padding: 2px;
  border: 1px solid $theme-color-border;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: $font-size-title;
  line-height: $line-height-base;
  transition: all .15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
  background-color: lighten($secondary-brand, 30%);
  border: none;
  color: $theme-color-txt;
}

.radio {
  margin-bottom: 0;
}

// Dropdown
.dropdown-item:hover, .dropdown-item:focus {
  color: $secondary-brand;
  text-decoration: none;
  background: none;
}

// Close X
.close {
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
  color: $theme-color-txt;
  opacity: 1;

  &:hover {
    Color: $secondary-brand;
  }
}
