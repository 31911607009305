p {
  margin-bottom: $layout-margin;
}


.ap-panel-title {
  @include panel-heading--center(rem-calc(16px));
}

.ap-panel-subtitle {
  @include panel-heading--center(rem-calc(14px));
}

.ap-section-heading {
  @include heading--primary(rem-calc(18px));
}

.ap-subheading {
  @include heading--body(16px);
}

.ap-subheading--white {
  color: $theme-color-txt;
}

.ap-subheading--primary {
  color: $link-color;
}


.ap-no-content {
  font-size: rem-calc(14px);
  color: $theme-color-txt;
}

.ap-link--dark {
  color: $theme-color-txt;
  text-decoration: underline;

  &:hover {
    color: $link-color;
  }
}

.ap-link--sm {
  font-size: 14px;
}

.ap-status-label {
  font-size: rem-calc(10px);
  color: $theme-color-txt;
  font-style: italic;
}

h1, .h1 { @include font-size($h1-font-size); color: $theme-color-txt; }
h2, .h2 { @include font-size($h2-font-size); color: $theme-color-txt; }
h3, .h3 { @include font-size($h3-font-size); color: $theme-color-txt; }
h4, .h4 { @include font-size($h4-font-size); color: $theme-color-txt; }
h5, .h5 { @include font-size($h5-font-size); color: $theme-color-txt; }
h6, .h6 { @include font-size($h6-font-size); color: $theme-color-txt; }

