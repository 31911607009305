@import 'reference';

::ng-deep {
  .accordion {
    &:hover {
      overflow: hidden !important;
    }

    .accordion-button {
      border: none;
      width: 100%;
      text-align: left;
      height: 50px;
      background: $theme-color-bg;
      // margin-bottom: 10px;
    }

    .accordion-item {
      border-radius: 0;
      border: 1px solid $theme-color-border !important;
      margin-bottom: 0.5rem !important;
      background-color: $theme-color-bg;

      .accordion-header {
        // background-color: $white !important;
        margin-bottom: 0 !important;
        border-bottom: none !important;
        background-color: $theme-color-bg;
        width: 100%;

        .btn {
          text-decoration: none;
          width: 100%;
          padding-left: 0 !important;

          &:hover {
            text-decoration: none !important;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .accordion-body {
        padding: 10px !important;
        border-top: 1px solid $theme-color-border;
        background-color: $white;
      }
    }
  }
}
