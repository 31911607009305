.z-table__editor-workspace-group {
  .ng-select {
    width: 100%;
    background: $white;
    border: 1px solid $theme-color-border;
  }

  .ng-select .ng-select-container {
    flex-wrap: wrap;
    background: transparent;
    border: none;
    height: auto;
    min-height: 28px;
    color: $theme-color-txt;
    max-height: 29px;

    &:hover {
      box-shadow: none;
    }

    .ng-value-container {
      display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
    }

    .ng-value-container .ng-placeholder {
      width: 100%;
      height: 100%;
      padding: 1px 5px;
      z-index: 1;

      font-size: inherit !important;
      text-align: left;
      color: $theme-color-txt;
    }
  }

  .ng-select.ng-select-single .ng-select-container {
    .ng-value-container {
      .ng-value {
        margin-left: 0;
      }
      .ng-value .ng-value-label {
        font-size: inherit !important;
      }
      .ng-input {
        height: 100%;
        top: 0;
        padding-left: 5px;
      }
      .ng-input > input {
        height: 100%;
      }
    }
  }

  .ng-select.ng-select-opened .ng-select-container {
    z-index: 4 !important;
  }

  .ng-dropdown-panel {
    width: auto;
    min-width: 100%;
    z-index: 7 !important;
  }

  &.z-table__editor--empty .ng-input {
    //   background: $input-bg;
    //   border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
  }

  .ng-select-disabled .ng-value .ng-value-label,
  .ng-input input:disabled {
    color: $theme-color-txt-lighten;
    opacity: 1;
  }
}

.ng-select .ng-select-container {
  min-height: 38px;
  background-color: $theme-color-bg-darken;
}

.ng-select.ng-select-opened > .ng-select-container {
  background-color: $theme-color-bg; // override the default theme
}

.ng-placeholder {
  font-size: rem-calc(14px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: rem-calc(14px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 9px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 180px !important; // hack
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $white;
}

.ng-select.ng-select-focused .ng-select-container,
.ng-select.ng-select-focused.ng-select-opened .ng-select-container:hover,
.ng-select.ng-select-focused:not(.ng-select-opened) .ng-select-container {
  @include transition($input-transition);
  border-color: $theme-color-border;
  box-shadow: $box-shadow-theme-1;
}

.ng-dropdown-panel.ng-select-top {
  margin-bottom: 0.2rem;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 0.2rem;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-radius: 4px;
}

.ng-select.ng-select-opened .ng-select-container .ng-arrow {
  top: -5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  width: 50px;
}

.ng-dropdown-panel {
  width: auto !important;
  z-index: 1080 !important; // a bit hack here with important rule
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
  .ng-value {
    margin-left: $layout-margin-sm * 2;

    .ng-value-label {
      display: inline-block;
      padding: 1px 5px;
      font-size: rem-calc(14px);
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: $layout-padding;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  width: 100%;
  padding: $layout-padding-sm $layout-padding;
  background-color: $theme-color-bg;
  margin-top: $layout-margin;

  &:first-child {
    margin-top: 0;
  }
}

.ng-select.ng-select-disabled {
  // table styles
  &.table-editor {
    background: $theme-color-bg !important;
    border: none !important;

    & .ng-arrow-wrapper {
      display: none !important;
    }

    & .ng-select-container .ng-value-container .ng-placeholder {
      color: $theme-color-txt-lighten !important;
    }
  }
}
