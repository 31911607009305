$primary-tag-icon-size: 28px;
$primary-tag-icon-size-sm: $tag-icon-font-size;

@mixin assignment-primary-tag-variant($key, $value) {
  .assignment-primary-tag--#{$key} {
    background-color: $value;
    border: none;
    color: $theme-color-txt;



    &:hover {
      background-color: $value;

      &.assignment-primary-tag--readonly {
        background-color: $value;

      }
    }

  }

}

.assignment-primary-tag {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  height: $assignment-primary-tag-height;
  cursor: pointer;
  padding-left: $layout-padding-lg;
  padding-right: $layout-padding-lg;
  border-radius: 3rem;
  box-shadow: none;
  @include field-edit-icon-on-hover($color-heading-gray-light, 10px);
}

@each $key, $value in $ap-user-color-selections {
  @include assignment-primary-tag-variant($key, $value);
}

//.assignment-primary-tag--mini {
//  max-height: 6px;
//
//  &:hover:after {
//    content: none;
//  }
//
//  .assignment-primary-tag__name,
//  .assignment-primary-tag__value {
//    display: none;
//  }
//}

.assignment-primary-tag--sm {
  height: $assignment-primary-tag-height-sm;
  width: 120px;

  .assignment-primary-tag__icon {
    width: $primary-tag-icon-size-sm;
    height: $primary-tag-icon-size-sm;
  }

  .assignment-primary-tag__value {
    font-size: rem-calc(10px);
  }

}

.assignment-primary-tag--readonly {
  cursor: initial;

  &:hover:after {
    content: none;
  }
}

.assignment-primary-tag__icon {
  font-size: rem-calc($tag-icon-font-size);
  color: $theme-color-txt;
  width: $primary-tag-icon-size;
  height: $primary-tag-icon-size;
  flex-shrink: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: transparent;
  //border: 1px solid $color-border;
  border-radius: $border-radius-md;
  //box-shadow: $box-shadow-sm;
}


.assignment-primary-tag__value {
  font-size: rem-calc(14px);
  flex-grow: 1;
  display: flex;
  justify-content: start;
}
