.view-custom-settings {
  padding: 0;
  min-width: 300px;
}

.view-custom-settings__dropdown-header {
  font-size: rem-calc(12px);
}

.view-custom-settings__form-group {
  margin-bottom: 0;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
}

.view-custom-settings__custom-control {
  font-size: 1rem;
  color: $theme-color-txt;
}

.view-custom-settings__custom-control-label {
  cursor: pointer;

  &:before,
  &:after {
    top: 0.125rem;
  }
}

.view-custom-settings__row {
  display: flex;
}

.view-custom-settings__item-padding {
  display: inline-block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
}
