.ap-color-link {
  color: $link-color !important;
}

.ap-color-danger {
  color: $color-red !important;
}

.ap-color-low-key {
  color: $color-heading-gray !important;
}

.ap-color-positive {
  color: $link-color !important;
}

.ap-color-negative {
  color: $color-purple-light !important;
}
