/*------------------------------------*\
  Colors
\*------------------------------------*/

//Grays
$gray-100: #f7f8f9;
$gray-200: #e6e6e6;
$gray-300: #d5d5d5;
$gray-400: #b1b1b1;
$gray-500: #909090;
$gray-600: #6d6d6d;
$gray-700: #464646;
$gray-800: #222222;
$gray-900: #000000;

// Brand Colors
$primary-brand: #6f42c1;
$secondary-brand: #ff4a75;
$accent-brand: #49546a;
$confirm-brand: #1abb9a;
$primary-brand-lighten: #FFE3EA;
$primary-brand-lighten: #FFE3EA;

// Light Theme Options
$blue-100: #e0f2ff;
$green-100: #f1fdf6;
$red-100: #fef3f0;
$purple-100: #f9f4ff;

// Light Theme Applied
$theme-color-bg: $gray-100;
$theme-color-bg-lighten: lighten($theme-color-bg, 5%);
$theme-color-bg-darken: darken($theme-color-bg, 5%);
$theme-color-border: darken($theme-color-bg, 10%);
$theme-color-txt: darken($theme-color-bg, 60%);
$theme-color-txt-lighten: rgba($theme-color-txt, 0.6);

// Color system Bootstrap
$blue: #007bff;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffca00;
$green: #28a745;
$cyan: #47c8ae;
$teal: #1abb9a;
$white: #ffffff;
$black: #000000;

//Bootstrap Color Override
$primary: $primary-brand;
$secondary: $secondary-brand;
$accent: $accent-brand;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red;
$light-200: $white;
$dark: $black;

$theme-color-interval: 10%;
$alert-bg-level: -9;

$color-body: $theme-color-txt;
$color-heading-gray-light: $theme-color-txt;
$color-heading-gray: $theme-color-txt;
$color-heading-gray-dark: $theme-color-txt;
$color-background-gray-dark: $theme-color-txt;
$color-border-dark: $theme-color-txt;

$color-gold: #c5af80;
$color-blue: $blue;
$color-green: $green;
$color-red: $red;
$color-yellow: $yellow;
$color-purple: $primary;
$color-purple-light: #f06292;
$color-cyan: $cyan;
$color-teal: $teal;
$color-orange: $orange;
$color-gray-default: $theme-color-txt;

$color-river-bed: $secondary;

$color-background-dark-900: #1e1f23;
$color-background-dark-700: #313842;
$color-background-gray-light: $theme-color-bg;
$primary-color: $primary-brand;
$color-background-duplicated: #ffd7dc;

$color-body-inverted: $theme-color-txt;
$color-watermark-inverted: $gray-700;

$color-background-primary-light: mix($primary-brand, $theme-color-bg, $theme-color-interval * 2);
$color-background-primary-light-hover: mix(
  $primary-brand,
  $theme-color-bg,
  $theme-color-interval * 3
);

//Modal Color Theme
$theme-colors: (
  'light-200': $light-200,
);

// Color Selections
// List of colors selectable by users
// Colors can be defined by users for Primary Tags, Tags, and Folders

$lighten: 0.2;
$ap-user-color-selections: (
  'not-available': $theme-color-bg,
  'color-49546a': rgba(73, 84, 106, $lighten),
  'color-ff4a75': rgb(255, 74, 117, $lighten),
  'color-6f41c1': rgb(111, 65, 193, $lighten),
  'color-007bff': rgb(0, 123, 255, $lighten),
  'color-eb842d': rgb(235, 132, 45, $lighten),
  'color-39b49b': rgb(57, 180, 155, $lighten),
  'color-708090': rgb(112, 128, 144, $lighten),
  'color-c62f3e': rgb(198, 47, 62, $lighten),
  'color-856088': rgb(133, 96, 136, $lighten),
  'color-2072af': rgb(32, 114, 175, $lighten),
  'color-de7c67': rgb(222, 124, 103, $lighten),
  'color-70a951': rgb(112, 169, 81, $lighten),
  'color-0f1f40': rgb(15, 31, 64, $lighten),
  'color-d471ac': rgb(212, 113, 172, $lighten),
  'color-9e71bd': rgb(158, 113, 189, $lighten),
  'color-51acca': rgb(81, 172, 202, $lighten),
  'color-e8b717': rgb(232, 183, 23, $lighten),
  'color-79d18a': rgb(121, 209, 138, $lighten),
  'color-848484': rgb(132, 132, 132, $lighten),
  'color-c13ea5': rgb(193, 62, 165, $lighten),
  'color-c392e0': rgb(195, 146, 224, $lighten),
  'color-67c9e3': rgb(103, 201, 227, $lighten),
  'color-f1dc4d': rgb(241, 220, 77, $lighten),
);

/*------------------------------------*\
  Shadows
\*------------------------------------*/

// box-shadow
$box-shadow: 0 0.5rem 1rem rgba($gray-900, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($gray-900, 0.075);
$box-shadow-md: 0 0.25rem 0.5rem rgba($gray-900, 0.15);
$box-shadow-lg: 0 1rem 3rem rgba($gray-900, 0.175);

$box-shadow-top: 0 -0.5rem 1rem rgba($gray-900, 0.15);
$box-shadow-sm-top: 0 -0.125rem 0.25rem rgba($gray-900, 0.075);
$box-shadow-md-top: 0 -0.25rem 0.5rem rgba($gray-900, 0.15);
$box-shadow-lg-top: 0 -1rem 3rem rgba($gray-900, 0.175);

$box-shadow-popover: rgba(0, 0, 0, 0.24) 0px 3px 8px;

$btn-focus-box-shadow-yellow: 0 0 0 0.2rem rgba(255, 227, 120, 0.25);

$leaf-list-item-box-shadow: 0 0 0 1px $primary-color;

$box-shadow-theme-1: $theme-color-bg-darken 0px 13px 27px -5px,
  $theme-color-txt-lighten 0px 8px 16px -8px;

// Only support device width above the min-device-width
$min-device-width: rem-calc(400px);

// Breakpoints
$grid-breakpoints: (
  xxs: 0,
  iphone5: 320px,
  xs: 400px,
  sm: 576px,
  md: 768px,
  lg: 1280px,
  xl: 1600px,
  xxl: 1920px,
);

// Grid gutter
$grid-gutter-width: 20px;

/*------------------------------------*\
  Typography
\*------------------------------------*/

// Font size
$font-size-title: 1rem;
$font-size-subtitle: 0.875rem;

// Font Family
$font-family: 'Figtree', 'Roboto', sans-serif;

// Line Height
$line-height-compact: 1.2;

// headings
$headings-font-weight: normal;
$headings-margin-bottom: 10px;

// Padding
//$layout-padding-sm: 4px;
//$layout-padding: 10px;
//$layout-padding-lg: 16px;
//$layout-padding-xl: 20px;
//$layout-padding-xxl: 30px;
//$layout-padding-xxxl: 40px;

$layout-padding-xs: 0.2rem;
$layout-padding-sm: 0.35rem;
$layout-padding-md: 0.5rem;
$layout-padding: 0.75rem;
$layout-padding-lg: 1rem;
$layout-padding-xl: 1.5rem;
$layout-padding-xxl: 2rem;
$layout-padding-xxxl: 2.5rem;

// Margin
//$layout-margin-sm: 4px;
//$layout-margin: 10px;
//$layout-margin-lg: 16px;
//$layout-margin-xl: 20px;
//$layout-margin-xxl: 40px;

$layout-margin-xs: 0.2rem;
$layout-margin-sm: 0.35rem;
$layout-margin: 0.75rem;
$layout-margin-lg: 1rem;
$layout-margin-xl: 1.5rem;
$layout-margin-xxl: 2rem;
$layout-margin-xxxl: 2.5rem;

// Link
$link-color: $primary-brand;
$link-hover-color: $secondary-brand;

// Form
$input-bg: mix($theme-color-bg, $primary, $theme-color-interval * 9); /* $gray-100; */

$input-placeholder-color: $gray-500;

$input-btn-padding-x: $layout-padding;

// z-index
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1040 !default;
$zindex-popover: 1050 !default;
$zindex-modal-high-zindex: 1065 !default;
$zindex-tooltip: 1070 !default;
$zindex-google-place-autocomplete: 2000 !default;
$zindex-ngb-typeahead: 2000 !default;

$zindex-header: 7;
$zindex-sidebar: 6;
$zindex-sidebar-toggle: 5;
$zindex-properties-panel: 4;
$zindex-controlbar: 3;

$zindex-awaiting-assignments: 9;
$zindex-workspace-disabled-overlay: 20;
$zindex-content-panel: 40;

$zindex-ng-dropdown: $zindex-tooltip;

$zindex-gantt: 1;
$zindex-gantt-is-today: 2;
$zindex-gantt-assignment: 3;

// Layout and component sizes
$navbar-height: 67.5px;

$controlbar-approx-height: 62px;
$folder-breadcrumb-height: 36px;

$sidebar-width: 275px;
$sidebar-width-lg: 300px;
$sidebar-width-xl: 375px;
$content-panel-width: 300px;

$vertical-bar-width: 12px;

//$assignment-width: 300px;
//$assignment-body-height: 250px;
//$assignment-footer-height: 36px;
//$assignment-height: 363px;

$assignment-width: 21.5rem;
$assignment-body-height: 18rem;
$assignment-footer-height: 3rem;
$assignment-height: 26rem;

$assignment-primary-tag-height: 42px;
$assignment-primary-tag-height-sm: 28px;
$assignment-primary-tag-icon-font-size-sm: 28px;

$assignment-footer-background-color: $theme-color-bg;

$assignment-section-title-font-size: 10px;
$assignment-section-title-font-color: $theme-color-txt;

$tag-icon-font-size: 18px;

$dashboard-card-height: 200px;

$footer-height: 0px;
$footer-height-workspace: 80px;

$inline-edit-time-height: 53px;

$popover-footer-spacing: $layout-padding-xxxl + $layout-padding-lg;

$template-popover-header-height: 52px;

$widgets-height: 360px;

$workspace-height: calc(100vh - #{$navbar-height + $footer-height});

$form-control-width-xxs: 100px;
$form-control-width-xs: 200px;
$form-control-width-sm: 320px;
$form-control-width-md: 480px;
$form-control-width-lg: 600px;

$form-label-width: 150px;

$tag-list-width: 320px;
$three-col-table-width: 500px;
$four-col-table-width: 600px;

$layout-switch-btn-width: 50px;
$layout-switch-btn-padding: 4px;
$layout-switch-btn-background-on: #18c98e;
$layout-switch-btn-background-off: #bfc1c6;
$layout-switch-btn-color: $theme-color-bg;

// gantt chart components
$gc-col-content-width: 45px;
$gc-col-content-margin: 2px;
$gc-col-date-width: $gc-col-content-width + $gc-col-content-margin * 2 + 1px; // 1px for border-right

// Border radius sizes
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius: $layout-padding;
$border-radius-lg: $layout-padding-lg;
$border-radius-xl: $layout-padding-xl;

// Button sizes
$btn-padding-y-sm: 6px;
$btn-padding-x-sm: 10px;
$btn-line-height-sm: 1.2;

$nav-btn-size: 36px;

$btn-size-sm: 32px;

// Input box
$input-box-height-lg: 40px;

//Table
$table-hover-bg: rgba($gray-900, 0.025);

$table-head-bg: $input-bg;
$table-cell-min-height: 30px;

// Modal
$modal-content-border-radius: $layout-padding-lg;

$modal-xl: 1400px;
$modal-lg: 1200px;
$modal-md: 736px;
$modal-sm: 556px;

$modal-backdrop-opacity: 0.4;
$modal-animation-duration: 0.3s;

// Bootstrap popover
$popover-border-radius: $border-radius-md;
$popover-body-padding-x: 1.5rem;
$popover-max-width: initial;

// Tooltip
$tooltip-max-width: 300px;
$tooltip-bg: mix($gray-900, $primary, $theme-color-interval * 2);
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: $layout-padding;
$tooltip-padding-x: $layout-padding;

// Animation
$animation-duration: 250ms;
$animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);

$transition-base: all $animation-duration $animation-timing-function;
$transition-transform: transform $animation-duration $animation-timing-function;

// breadcrumb
$breadcrumb-font-size: 10px;
$breadcrumb-bg: transparent;
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: $layout-padding-sm;
