
.ap-form-section {
  padding: $layout-padding 0;
  border-bottom: 1px solid $gray-300;
}

.ap-form-field-width-xs {
  width: $form-control-width-xs; // have to use width instead of max-width so multiple fields of same row will have width set correctly
}

.ap-form-field-width-sm {
  width: $form-control-width-sm;
}

.ap-form-field-width-md {
  width: $form-control-width-md;
}

.ap-form-field-width-lg {
  width: $form-control-width-lg;
}

.ap-field-label-width {
  width: $form-label-width;
}

.ap-field-label-sm {
  font-size: rem-calc(14px);
}

.ap-field-text {
  @include font-size(16px);
  color: $color-heading-gray;
  padding: $input-padding-y 0;
}

.ap-field-text-sm {
  @include font-size(14px);
}

.ap-field-text--tooltip {
  color: $color-heading-gray-light;
}

.ap-field-text--positive {
  color: $link-color;
}

.ap-field-text--negative {
  color: $color-purple-light;
}

.ap-required-asterisk {
  color: $color-red;
  margin-left: $layout-margin-sm;
}

.ap-optional-text {
  color: $gray-500;
  font-style: italic;
}

.btn.disabled,
.btn:disabled {
  filter: opacity(0.5);
}

.form-control {
  color: $theme-color-txt;
  background-color: $white;
  height: 45px;
}

.no-input-focus {
  outline: none;
  box-shadow: none;
  border: none;
}
